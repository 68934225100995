@import "../../index";

.noMedia {
  @include flex-row-full-center;
  font-size: $size-big;
  color: $color-bright-2;
  min-height: 300px;

  .emoji {
    padding: 0 10px 8px;
  }
}