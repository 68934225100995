/**
  GLOBAL VARIABLES
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500");
@import url('https://fonts.googleapis.com/css?family=Karla&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

//layout
$container-width: 1000px;

//font weights :
$weight-light: 300;
$weight-regular: normal;
$weight-bold: 500;
$weight-bolder: 600;

//text size
$size-smallest: 9px;
$size-smaller: 12px;
$size-small: 15px;
$size-normal: 18px;
$size-big: 20px;
$size-bigger: 24px;
$size-biggest: 36px;

//colors
$color-dark-1: #2E4052;
$color-dark-2: #08090A;
$color-bright-1: #BDD9BF;
$color-bright-2: #FFC857;

//media sizes
$media-tablet: "screen and (max-width : 1260px)";
$media-phone: "screen and (max-width : 700px)";

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column-full-center {
  @include flex-column;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-full-center {
  @include flex-row;
  justify-content: center;
  align-items: center;
}


body {
  margin: 0;
}

html {
  font-family: 'Roboto', sans-serif;
  font-weight: $weight-light;
  font-size: $size-normal;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: lighten(#0000FF, 30%);
}

strong {
  font-weight: $weight-regular;
}

h2 {
  color: $color-bright-2;
  font-weight: $weight-light;
  font-size: $size-normal;
}

//Forms
.Form {
  @include flex-column;
  border: 1px solid $color-bright-2;
  padding: 30px 80px;
}

.Form_label {
  @include flex-column;
  margin-bottom: 30px;
}

.Form_labelContent {
  color: $color-bright-1;
  font-size: $size-small;
  padding: 10px 15px;
  letter-spacing: 1px;
}

.Form_error {
  font-size: $size-small;
  margin: 10px 0 0 15px;
  height: 20px;
  color: $color-bright-2;
  width: 300px;
}

.Form_inputSubmit {
  width: auto !important;
}

// Inputs
.InputText {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid $color-bright-1;
  border-radius: 25px;
  width: 300px;
  font-size: $size-small;
  color: $color-bright-1;
  font-weight: $weight-light;
  font-family: 'Roboto', sans-serif;

  &:focus {
    outline: none;
  }
}

.inputContainer {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid $color-bright-1;
  margin: 20px;
  border-radius: 50px;
  @include flex-row;
  align-items: center;


  &.small {
    margin: 10px;
    padding: 0 15px;

    .input {
      font-size: $size-small;
      width: 300px;
      padding: 8px 0;
    }
  }

  &.smallest {
    width: 200px;
    border-color: transparent;

    .input {
      font-size: $size-small;
    }

    .icon {
      font-size: 30px;
      margin-right: 10px;
    }

    &.focus {
      border-color: transparent;
    }
  }

  .input {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: $color-bright-1;
    font-size: $size-normal;
    font-weight: $weight-light;
  }

  .icon {
    color: $color-bright-1;
    margin-right: 20px;
  }

  &.focus {
    border: 1px solid $color-bright-2;

    .icon {
      color: $color-bright-2;
    }

    .input {
      color: $color-bright-2;
    }
  }
}

.inputWithSuggestionsContainer {
  @include flex-column;

  .alternativeSearch {
    color: $color-bright-1;
    font-size: $size-smaller;

    span {
      margin-left: 5px;
    }
  }

  .suggestionsContainer {
    width: 300px;

    .loadingContainer {
      margin-top: 20px;
    }

    .suggestionItem {
      color: $color-bright-1;
      font-size: $size-small;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &.active {
        color: $color-bright-2;
      }

      &:hover {
        color: $color-bright-2;
        transform: translateX(5px);
        transition: all 0.2s ease-in-out;
      }
    }

    .noMatch {
      margin-left: 25px;
      color: $color-bright-2;
      font-size: $size-smaller;
      text-transform: uppercase;
      font-weight: $weight-regular;
    }
  }

  .inputContainer {
    margin: 0;
    padding: 0;
  }
}

.hidden {
  display: none !important;
}

// Buttons
.btn {
  color: $color-bright-1;
  background-color: transparent;
  border-radius: 10px;
  font-weight: $weight-regular;
  font-size: $size-small;
  cursor: pointer;
  border: 1px solid $color-bright-1;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 $color-bright-2;
  transition: box-shadow 0.1s, transform 0.1s;

  &:hover, &:active {
    border: 1px solid $color-bright-2;
    color: $color-bright-2;
    box-shadow: 3px 3px 0 0 $color-bright-2;
    transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, transform 0.2s;
  }

  &:focus {
    outline: none !important;
  }

  &::-moz-focus-inner {
    border: 0 !important;
  }

  &.small {
    font-size: $size-smaller;
    padding: 6px 6px;
  }

  &.disabled {
    opacity: 0.4;
  }

}

/* Effect 4: bottom border enlarge */
.navMenuItem {
  position: relative;
  color: $color-bright-1;
  width: 100px;
  height: 100%;
  @include flex-column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: $weight-regular;
  font-size: $size-small;
}

.navMenuItem::after {
  position: absolute;
  //top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: $color-bright-1;
  content: '';
  text-align: center;
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  -moz-transition: width 0.3s, -moz-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
}

.navMenuItem.active {
  color: $color-bright-2;
}

.navMenuItem:hover::after,
.navMenuItem:focus::after,
.navMenuItem.active::after {
  width: 100%;
}

.navMenuItem.active::after {
  background: $color-bright-2;
  width: 100%;
}


.cardContainer {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  transform: scale(0.90);
  overflow: hidden;

  .hoveredInfo {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    @include flex-column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative;

    .footer {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%
    }

  }

  .backColor {
    background-color: $color-dark-2;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
  }

  .backImage {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: $color-dark-2;
  }

  .title {
    margin: 30px 5px 10px 0;
    color: $color-bright-2;
    opacity: 1;
    position: relative;
    font-weight: $weight-regular;
    font-size: $size-normal;
    text-transform: uppercase;
    text-align: center;
  }

  .secondaryInfoContainer {
    font-style: italic;
    color: $color-bright-2;
    font-size: $size-small;
    position: relative;
  }

  .mediasSummaryContainer {
    @include flex-column-full-center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px;

    .badge {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      @include flex-row-full-center;
      color: $color-bright-1;
      background-color: $color-dark-1;
      font-weight: $weight-regular;
      font-size: $size-small;
      margin-top: 7px;

      .number {
        font-size: $size-normal;
        margin-right: 5px;
        color: $color-bright-2;
      }

      .emoji {
        margin-left: 3px;
      }
    }

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1);
    opacity: 1;

    .hoveredInfo {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }


    .noBlur {
      filter: none !important;
    }

    .backImage {
      filter: blur(2px);
    }
  }
}

ul {
  li.slick-active {
    div {
      opacity: 1;
    }
  }
}


@media #{$media-phone} {
  .inputContainer {
    .input {
      width: auto;
    }
  }

  .cardContainer {
    .mediasSummaryContainer {
      display: none;
    }
  }
}