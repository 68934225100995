@import "../../index";

.footerContainer {
  @include flex-row-full-center;
  width: 100%;
  height: 80px;
  background-color: $color-dark-1;
  color: $color-bright-2;
  padding: 0 30px;
  box-sizing: border-box;

  .copyrightContainer {
    font-size: $size-smaller;
  }
}