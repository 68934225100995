@import "../../index";

.trashContainer {
  transform: scale(0.15);
  transform-origin: top center;
  padding: 40px 20px;

  .trash {
    width: 66px;
    height: 80px;
    display: inline-block;
    box-sizing: border-box;

    position: relative;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .trash span {
    position: absolute;
    height: 12px;
    top: -19px;
    left: -10px;
    right: -10px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform-origin: 19% 100%;
  }

  .trash .top {
    position: absolute;
    width: 27px;
    height: 7px;
    top: -10px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform-origin: 19% 100%;
    left: 27px;
  }


  .trash i {
    position: relative;
    width: 5px;
    height: 50px;
    background: transparent;
    display: block;
    margin: 14px auto;
    border-radius: 5px;
  }

  .trash i:after {
    content: '';
    width: 5px;
    height: 50px;
    background: transparent;
    position: absolute;
    left: -18px;
    border-radius: 5px;
  }

  .trash i:before {
    content: '';
    width: 5px;
    height: 50px;
    background: transparent;
    position: absolute;
    right: -18px;
    border-radius: 5px;
  }

  &:hover {
    .trash span {
      transform: rotate(-45deg);
      transition: transform 250ms;
    }
  }
}