@import "../../index";

.playPodcastContainer {
  width: 250px;
  height: 250px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  position: relative;
  background-color: $color-dark-2;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);

    img {
      opacity: 0.6;
    }

    .playIcon {
      color: $color-bright-2;
    }
  }

  img {
    height: 100%;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
  }

  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    color:  $color-bright-1;
    transition: all 0.1s ease-in-out;
  }

}