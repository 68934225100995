@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.Form {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.Form_label {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.Form_labelContent {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.Form_error {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.Form_inputSubmit {
  width: auto !important; }

.InputText {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .InputText:focus {
    outline: none; }

.inputContainer {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .inputContainer.small {
    margin: 10px;
    padding: 0 15px; }
    .inputContainer.small .input {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .inputContainer.smallest {
    width: 200px;
    border-color: transparent; }
    .inputContainer.smallest .input {
      font-size: 15px; }
    .inputContainer.smallest .icon {
      font-size: 30px;
      margin-right: 10px; }
    .inputContainer.smallest.focus {
      border-color: transparent; }
  .inputContainer .input {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .inputContainer .icon {
    color: #BDD9BF;
    margin-right: 20px; }
  .inputContainer.focus {
    border: 1px solid #FFC857; }
    .inputContainer.focus .icon {
      color: #FFC857; }
    .inputContainer.focus .input {
      color: #FFC857; }

.inputWithSuggestionsContainer {
  display: flex;
  flex-direction: column; }
  .inputWithSuggestionsContainer .alternativeSearch {
    color: #BDD9BF;
    font-size: 12px; }
    .inputWithSuggestionsContainer .alternativeSearch span {
      margin-left: 5px; }
  .inputWithSuggestionsContainer .suggestionsContainer {
    width: 300px; }
    .inputWithSuggestionsContainer .suggestionsContainer .loadingContainer {
      margin-top: 20px; }
    .inputWithSuggestionsContainer .suggestionsContainer .suggestionItem {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .inputWithSuggestionsContainer .suggestionsContainer .suggestionItem.active {
        color: #FFC857; }
      .inputWithSuggestionsContainer .suggestionsContainer .suggestionItem:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .inputWithSuggestionsContainer .suggestionsContainer .noMatch {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .inputWithSuggestionsContainer .inputContainer {
    margin: 0;
    padding: 0; }

.hidden {
  display: none !important; }

.btn {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .btn:hover, .btn:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .btn:focus {
    outline: none !important; }
  .btn::-moz-focus-inner {
    border: 0 !important; }
  .btn.small {
    font-size: 12px;
    padding: 6px 6px; }
  .btn.disabled {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.navMenuItem {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.navMenuItem::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.navMenuItem.active {
  color: #FFC857; }

.navMenuItem:hover::after,
.navMenuItem:focus::after,
.navMenuItem.active::after {
  width: 100%; }

.navMenuItem.active::after {
  background: #FFC857;
  width: 100%; }

.cardContainer {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .cardContainer .hoveredInfo {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .cardContainer .hoveredInfo .footer {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .cardContainer .backColor {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .cardContainer .backImage {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .cardContainer .title {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .cardContainer .secondaryInfoContainer {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .cardContainer .mediasSummaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .cardContainer .mediasSummaryContainer .badge {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .cardContainer .mediasSummaryContainer .badge .number {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .cardContainer .mediasSummaryContainer .badge .emoji {
        margin-left: 3px; }
  .cardContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .cardContainer:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .cardContainer:hover .hoveredInfo {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .cardContainer:hover .noBlur {
      -webkit-filter: none !important;
              filter: none !important; }
    .cardContainer:hover .backImage {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.slick-active div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .inputContainer .input {
    width: auto; }
  .cardContainer .mediasSummaryContainer {
    display: none; } }

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.slick-disabled {
    opacity: 0 !important;
}

.Popover {
    z-index: 5;
}


.pretty.p-default input:checked ~ .state label:after {
    background-color: #FFC857 !important;
}
/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.homepage_Form__1Kwge {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.homepage_Form_label__2aasB {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.homepage_Form_labelContent__xcs3z {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.homepage_Form_error__3purM {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.homepage_Form_inputSubmit__3m8FC {
  width: auto !important; }

.homepage_InputText__v_V1t {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .homepage_InputText__v_V1t:focus {
    outline: none; }

.homepage_inputContainer__3iMIH {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .homepage_inputContainer__3iMIH.homepage_small__1lqgp {
    margin: 10px;
    padding: 0 15px; }
    .homepage_inputContainer__3iMIH.homepage_small__1lqgp .homepage_input__2kCLM {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .homepage_inputContainer__3iMIH.homepage_smallest__uWOzU {
    width: 200px;
    border-color: transparent; }
    .homepage_inputContainer__3iMIH.homepage_smallest__uWOzU .homepage_input__2kCLM {
      font-size: 15px; }
    .homepage_inputContainer__3iMIH.homepage_smallest__uWOzU .homepage_icon__1fFri {
      font-size: 30px;
      margin-right: 10px; }
    .homepage_inputContainer__3iMIH.homepage_smallest__uWOzU.homepage_focus__17GQB {
      border-color: transparent; }
  .homepage_inputContainer__3iMIH .homepage_input__2kCLM {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .homepage_inputContainer__3iMIH .homepage_icon__1fFri {
    color: #BDD9BF;
    margin-right: 20px; }
  .homepage_inputContainer__3iMIH.homepage_focus__17GQB {
    border: 1px solid #FFC857; }
    .homepage_inputContainer__3iMIH.homepage_focus__17GQB .homepage_icon__1fFri {
      color: #FFC857; }
    .homepage_inputContainer__3iMIH.homepage_focus__17GQB .homepage_input__2kCLM {
      color: #FFC857; }

.homepage_inputWithSuggestionsContainer__31d-W {
  display: flex;
  flex-direction: column; }
  .homepage_inputWithSuggestionsContainer__31d-W .homepage_alternativeSearch__a3F4M {
    color: #BDD9BF;
    font-size: 12px; }
    .homepage_inputWithSuggestionsContainer__31d-W .homepage_alternativeSearch__a3F4M span {
      margin-left: 5px; }
  .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow {
    width: 300px; }
    .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow .homepage_loadingContainer__1rY8J {
      margin-top: 20px; }
    .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow .homepage_suggestionItem__2be25 {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow .homepage_suggestionItem__2be25.homepage_active__2SEeh {
        color: #FFC857; }
      .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow .homepage_suggestionItem__2be25:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .homepage_inputWithSuggestionsContainer__31d-W .homepage_suggestionsContainer__2Y1ow .homepage_noMatch__1zzjb {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .homepage_inputWithSuggestionsContainer__31d-W .homepage_inputContainer__3iMIH {
    margin: 0;
    padding: 0; }

.homepage_hidden__layt0 {
  display: none !important; }

.homepage_btn__23BQ6 {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .homepage_btn__23BQ6:hover, .homepage_btn__23BQ6:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .homepage_btn__23BQ6:focus {
    outline: none !important; }
  .homepage_btn__23BQ6::-moz-focus-inner {
    border: 0 !important; }
  .homepage_btn__23BQ6.homepage_small__1lqgp {
    font-size: 12px;
    padding: 6px 6px; }
  .homepage_btn__23BQ6.homepage_disabled__1vaej {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.homepage_navMenuItem__1Yf3O {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.homepage_navMenuItem__1Yf3O::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.homepage_navMenuItem__1Yf3O.homepage_active__2SEeh {
  color: #FFC857; }

.homepage_navMenuItem__1Yf3O:hover::after,
.homepage_navMenuItem__1Yf3O:focus::after,
.homepage_navMenuItem__1Yf3O.homepage_active__2SEeh::after {
  width: 100%; }

.homepage_navMenuItem__1Yf3O.homepage_active__2SEeh::after {
  background: #FFC857;
  width: 100%; }

.homepage_cardContainer__3S_gC {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .homepage_cardContainer__3S_gC .homepage_hoveredInfo__CZ43X {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .homepage_cardContainer__3S_gC .homepage_hoveredInfo__CZ43X .homepage_footer__3yCt3 {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .homepage_cardContainer__3S_gC .homepage_backColor__1pG6e {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .homepage_cardContainer__3S_gC .homepage_backImage__1N0Kn {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .homepage_cardContainer__3S_gC .homepage_title__3lvsQ {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .homepage_cardContainer__3S_gC .homepage_secondaryInfoContainer__3kbyJ {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .homepage_cardContainer__3S_gC .homepage_mediasSummaryContainer__3KLIg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .homepage_cardContainer__3S_gC .homepage_mediasSummaryContainer__3KLIg .homepage_badge__1mnKQ {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .homepage_cardContainer__3S_gC .homepage_mediasSummaryContainer__3KLIg .homepage_badge__1mnKQ .homepage_number__XUpNU {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .homepage_cardContainer__3S_gC .homepage_mediasSummaryContainer__3KLIg .homepage_badge__1mnKQ .homepage_emoji__2YQTV {
        margin-left: 3px; }
  .homepage_cardContainer__3S_gC img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .homepage_cardContainer__3S_gC:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .homepage_cardContainer__3S_gC:hover .homepage_hoveredInfo__CZ43X {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .homepage_cardContainer__3S_gC:hover .homepage_noBlur__VQNMR {
      -webkit-filter: none !important;
              filter: none !important; }
    .homepage_cardContainer__3S_gC:hover .homepage_backImage__1N0Kn {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.homepage_slick-active__26Wl4 div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .homepage_inputContainer__3iMIH .homepage_input__2kCLM {
    width: auto; }
  .homepage_cardContainer__3S_gC .homepage_mediasSummaryContainer__3KLIg {
    display: none; } }

.homepage_titleContainer__2v_nR {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Karla', sans-serif;
  height: 100%;
  color: #FFC857;
  font-size: 35px;
  margin-bottom: 20px; }
  .homepage_titleContainer__2v_nR .homepage_logo__2JLDn {
    height: 100%;
    padding-bottom: 10px; }

.homepage_subtitle__1XvD3 {
  color: #BDD9BF;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center; }
  .homepage_subtitle__1XvD3 strong {
    color: #FFC857; }

@media screen and (max-width: 700px) {
  .homepage_headerContainer__1nm5g .homepage_titleContainer__2v_nR {
    font-size: 30px; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.header_Form__38Cqx {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.header_Form_label__2eXUJ {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.header_Form_labelContent__EC9Vp {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.header_Form_error__BV3q_ {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.header_Form_inputSubmit__3xyej {
  width: auto !important; }

.header_InputText__2P5Xx {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .header_InputText__2P5Xx:focus {
    outline: none; }

.header_inputContainer__1koIV {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .header_inputContainer__1koIV.header_small__3xBPW {
    margin: 10px;
    padding: 0 15px; }
    .header_inputContainer__1koIV.header_small__3xBPW .header_input__1d8gC {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .header_inputContainer__1koIV.header_smallest__1Fyib {
    width: 200px;
    border-color: transparent; }
    .header_inputContainer__1koIV.header_smallest__1Fyib .header_input__1d8gC {
      font-size: 15px; }
    .header_inputContainer__1koIV.header_smallest__1Fyib .header_icon__2BFqW {
      font-size: 30px;
      margin-right: 10px; }
    .header_inputContainer__1koIV.header_smallest__1Fyib.header_focus__1qM1_ {
      border-color: transparent; }
  .header_inputContainer__1koIV .header_input__1d8gC {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .header_inputContainer__1koIV .header_icon__2BFqW {
    color: #BDD9BF;
    margin-right: 20px; }
  .header_inputContainer__1koIV.header_focus__1qM1_ {
    border: 1px solid #FFC857; }
    .header_inputContainer__1koIV.header_focus__1qM1_ .header_icon__2BFqW {
      color: #FFC857; }
    .header_inputContainer__1koIV.header_focus__1qM1_ .header_input__1d8gC {
      color: #FFC857; }

.header_inputWithSuggestionsContainer__IMdgl {
  display: flex;
  flex-direction: column; }
  .header_inputWithSuggestionsContainer__IMdgl .header_alternativeSearch__inNgS {
    color: #BDD9BF;
    font-size: 12px; }
    .header_inputWithSuggestionsContainer__IMdgl .header_alternativeSearch__inNgS span {
      margin-left: 5px; }
  .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ {
    width: 300px; }
    .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ .header_loadingContainer__1XIXg {
      margin-top: 20px; }
    .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ .header_suggestionItem__1ws8l {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ .header_suggestionItem__1ws8l.header_active__1Ofvd {
        color: #FFC857; }
      .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ .header_suggestionItem__1ws8l:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .header_inputWithSuggestionsContainer__IMdgl .header_suggestionsContainer__38PlZ .header_noMatch__UwfKY {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .header_inputWithSuggestionsContainer__IMdgl .header_inputContainer__1koIV {
    margin: 0;
    padding: 0; }

.header_hidden__iOqy8 {
  display: none !important; }

.header_btn__4yx9n {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .header_btn__4yx9n:hover, .header_btn__4yx9n:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .header_btn__4yx9n:focus {
    outline: none !important; }
  .header_btn__4yx9n::-moz-focus-inner {
    border: 0 !important; }
  .header_btn__4yx9n.header_small__3xBPW {
    font-size: 12px;
    padding: 6px 6px; }
  .header_btn__4yx9n.header_disabled__18Mqo {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.header_navMenuItem__1vfgl {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.header_navMenuItem__1vfgl::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.header_navMenuItem__1vfgl.header_active__1Ofvd {
  color: #FFC857; }

.header_navMenuItem__1vfgl:hover::after,
.header_navMenuItem__1vfgl:focus::after,
.header_navMenuItem__1vfgl.header_active__1Ofvd::after {
  width: 100%; }

.header_navMenuItem__1vfgl.header_active__1Ofvd::after {
  background: #FFC857;
  width: 100%; }

.header_cardContainer__RzRy0 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .header_cardContainer__RzRy0 .header_hoveredInfo__3ySZ_ {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .header_cardContainer__RzRy0 .header_hoveredInfo__3ySZ_ .header_footer__3E45S {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .header_cardContainer__RzRy0 .header_backColor__32E66 {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .header_cardContainer__RzRy0 .header_backImage__3w9AC {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .header_cardContainer__RzRy0 .header_title__364Np {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .header_cardContainer__RzRy0 .header_secondaryInfoContainer__1f5vS {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .header_cardContainer__RzRy0 .header_mediasSummaryContainer__CtMbm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .header_cardContainer__RzRy0 .header_mediasSummaryContainer__CtMbm .header_badge__23C-C {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .header_cardContainer__RzRy0 .header_mediasSummaryContainer__CtMbm .header_badge__23C-C .header_number__1eyIT {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .header_cardContainer__RzRy0 .header_mediasSummaryContainer__CtMbm .header_badge__23C-C .header_emoji__3e4W_ {
        margin-left: 3px; }
  .header_cardContainer__RzRy0 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .header_cardContainer__RzRy0:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .header_cardContainer__RzRy0:hover .header_hoveredInfo__3ySZ_ {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .header_cardContainer__RzRy0:hover .header_noBlur__3N7hS {
      -webkit-filter: none !important;
              filter: none !important; }
    .header_cardContainer__RzRy0:hover .header_backImage__3w9AC {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.header_slick-active__1UvNT div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .header_inputContainer__1koIV .header_input__1d8gC {
    width: auto; }
  .header_cardContainer__RzRy0 .header_mediasSummaryContainer__CtMbm {
    display: none; } }

.header_headerContainer__18JN1 {
  width: 100%;
  background-color: #2E4052;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  z-index: 6;
  height: 56px; }
  .header_headerContainer__18JN1 .header_headerWrapper__2OTZH {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;
    width: 100%; }
  .header_headerContainer__18JN1 .header_titleContainer__2VusA {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }
  .header_headerContainer__18JN1 .header_navMenuContainer__S40La {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    width: 390px; }

.header_mobileSearch__vVcN6 {
  display: none;
  color: #BDD9BF; }
  .header_mobileSearch__vVcN6 .header_backButton__5hD6- {
    padding: 15px 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .header_mobileSearch__vVcN6 .header_backButton__5hD6- svg {
      position: absolute;
      left: 15px; }
    .header_mobileSearch__vVcN6 .header_backButton__5hD6- span {
      text-transform: uppercase;
      font-weight: normal; }
  .header_mobileSearch__vVcN6 .header_searchWithPreviewContainer__1g7_e {
    position: relative !important; }
  .header_mobileSearch__vVcN6 .header_sidebarButton__35Lw5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    height: 50px;
    padding: 0 15px; }
  .header_mobileSearch__vVcN6 .header_searchResultsContainer__2ap2B {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    right: 0;
    border-bottom: none;
    box-shadow: none; }
    .header_mobileSearch__vVcN6 .header_searchResultsContainer__2ap2B .header_allResults__2XEoo {
      margin: 10px; }
      .header_mobileSearch__vVcN6 .header_searchResultsContainer__2ap2B .header_allResults__2XEoo .header_itemContainer__s7zCK {
        padding: 10px 10px;
        justify-content: center; }
      .header_mobileSearch__vVcN6 .header_searchResultsContainer__2ap2B .header_allResults__2XEoo .header_gameTitle__1105X {
        font-size: 15px; }

.header_searchResultsContainer__2ap2B {
  position: absolute;
  padding: 0 30px;
  z-index: 22;
  justify-content: center;
  background-color: #08090A;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 #FFC857;
  width: 600px;
  right: 20px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotateZ(0) rotateY(0);
          transform: rotateZ(0) rotateY(0); }
  .header_searchResultsContainer__2ap2B ul {
    list-style-type: none;
    padding: 0; }
    .header_searchResultsContainer__2ap2B ul li .header_itemContainer__s7zCK {
      padding: 10px 20px;
      display: flex;
      flex-direction: row; }
    .header_searchResultsContainer__2ap2B ul li.header_allResults__2XEoo {
      color: #BDD9BF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 15px;
      cursor: pointer; }
      .header_searchResultsContainer__2ap2B ul li.header_allResults__2XEoo .header_itemContainer__s7zCK {
        padding: 20px 20px; }
      .header_searchResultsContainer__2ap2B ul li.header_allResults__2XEoo .header_gameTitle__1105X {
        text-transform: none; }
    .header_searchResultsContainer__2ap2B ul li .header_imageContainer__1r7HG {
      height: 60px;
      width: 45px;
      margin-right: 20px;
      color: #2E4052;
      transition: all 0.1s ease-in-out; }
      .header_searchResultsContainer__2ap2B ul li .header_imageContainer__1r7HG img {
        height: 100%;
        width: 100%;
        opacity: 0.9;
        object-fit: cover; }
    .header_searchResultsContainer__2ap2B ul li .header_gameInfos__2r38a {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .header_searchResultsContainer__2ap2B ul li.header_active__1Ofvd {
      color: #FFC857; }
      .header_searchResultsContainer__2ap2B ul li.header_active__1Ofvd .header_imageContainer__1r7HG {
        box-shadow: 5px 5px 0 0 #FFC857;
        -webkit-transform: translateX(-5px) translateY(-5px);
                transform: translateX(-5px) translateY(-5px);
        transition: box-shadow 0.2s, -webkit-transform 0.2s;
        transition: box-shadow 0.2s, transform 0.2s;
        transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
        .header_searchResultsContainer__2ap2B ul li.header_active__1Ofvd .header_imageContainer__1r7HG img {
          opacity: 1; }
      .header_searchResultsContainer__2ap2B ul li.header_active__1Ofvd .header_gameTitle__1105X {
        color: #FFC857; }
      .header_searchResultsContainer__2ap2B ul li.header_active__1Ofvd .header_gameDate__DK6m3 {
        color: rgba(255, 200, 87, 0.8); }
    .header_searchResultsContainer__2ap2B ul li .header_gameTitle__1105X {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: normal;
      color: #BDD9BF; }
    .header_searchResultsContainer__2ap2B ul li .header_gameDate__DK6m3 {
      color: rgba(189, 217, 191, 0.8);
      font-size: 15px; }

.header_searchWithPreviewContainer__1g7_e.header_mobile__3pZKn {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: #08090A; }
  .header_searchWithPreviewContainer__1g7_e.header_mobile__3pZKn .header_inputContainer__1koIV .header_input__1d8gC {
    width: 100%; }

@media screen and (max-width: 700px) {
  .header_headerContainer__18JN1 .header_navMenuContainer__S40La {
    display: none; }
  .header_notHomeHeader__10eJm {
    height: 50px; }
    .header_notHomeHeader__10eJm .header_titleContainer__2VusA {
      width: 100px;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      top: auto; }
    .header_notHomeHeader__10eJm .header_desktopSearch__79ZHy {
      display: none; }
  .header_mobileSearch__vVcN6 {
    display: block; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.logo_Form__6jik6 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.logo_Form_label__2g-Dg {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.logo_Form_labelContent__3SOY0 {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.logo_Form_error__DGMCu {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.logo_Form_inputSubmit__1CvNG {
  width: auto !important; }

.logo_InputText__2r4K_ {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .logo_InputText__2r4K_:focus {
    outline: none; }

.logo_inputContainer__2yiCc {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .logo_inputContainer__2yiCc.logo_small___jQYd {
    margin: 10px;
    padding: 0 15px; }
    .logo_inputContainer__2yiCc.logo_small___jQYd .logo_input__15W2K {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .logo_inputContainer__2yiCc.logo_smallest__3Xq9_ {
    width: 200px;
    border-color: transparent; }
    .logo_inputContainer__2yiCc.logo_smallest__3Xq9_ .logo_input__15W2K {
      font-size: 15px; }
    .logo_inputContainer__2yiCc.logo_smallest__3Xq9_ .logo_icon__2tw0b {
      font-size: 30px;
      margin-right: 10px; }
    .logo_inputContainer__2yiCc.logo_smallest__3Xq9_.logo_focus__2kP37 {
      border-color: transparent; }
  .logo_inputContainer__2yiCc .logo_input__15W2K {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .logo_inputContainer__2yiCc .logo_icon__2tw0b {
    color: #BDD9BF;
    margin-right: 20px; }
  .logo_inputContainer__2yiCc.logo_focus__2kP37 {
    border: 1px solid #FFC857; }
    .logo_inputContainer__2yiCc.logo_focus__2kP37 .logo_icon__2tw0b {
      color: #FFC857; }
    .logo_inputContainer__2yiCc.logo_focus__2kP37 .logo_input__15W2K {
      color: #FFC857; }

.logo_inputWithSuggestionsContainer__1EAaG {
  display: flex;
  flex-direction: column; }
  .logo_inputWithSuggestionsContainer__1EAaG .logo_alternativeSearch__ShJDF {
    color: #BDD9BF;
    font-size: 12px; }
    .logo_inputWithSuggestionsContainer__1EAaG .logo_alternativeSearch__ShJDF span {
      margin-left: 5px; }
  .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh {
    width: 300px; }
    .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh .logo_loadingContainer__3yfHv {
      margin-top: 20px; }
    .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh .logo_suggestionItem__2CPvH {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh .logo_suggestionItem__2CPvH.logo_active__38es6 {
        color: #FFC857; }
      .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh .logo_suggestionItem__2CPvH:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .logo_inputWithSuggestionsContainer__1EAaG .logo_suggestionsContainer__1OnTh .logo_noMatch__22uIe {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .logo_inputWithSuggestionsContainer__1EAaG .logo_inputContainer__2yiCc {
    margin: 0;
    padding: 0; }

.logo_hidden__3lz8n {
  display: none !important; }

.logo_btn__3Avix {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .logo_btn__3Avix:hover, .logo_btn__3Avix:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .logo_btn__3Avix:focus {
    outline: none !important; }
  .logo_btn__3Avix::-moz-focus-inner {
    border: 0 !important; }
  .logo_btn__3Avix.logo_small___jQYd {
    font-size: 12px;
    padding: 6px 6px; }
  .logo_btn__3Avix.logo_disabled__2Hw6U {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.logo_navMenuItem__3OqPk {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.logo_navMenuItem__3OqPk::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.logo_navMenuItem__3OqPk.logo_active__38es6 {
  color: #FFC857; }

.logo_navMenuItem__3OqPk:hover::after,
.logo_navMenuItem__3OqPk:focus::after,
.logo_navMenuItem__3OqPk.logo_active__38es6::after {
  width: 100%; }

.logo_navMenuItem__3OqPk.logo_active__38es6::after {
  background: #FFC857;
  width: 100%; }

.logo_cardContainer__2O50C {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .logo_cardContainer__2O50C .logo_hoveredInfo__2pyjK {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .logo_cardContainer__2O50C .logo_hoveredInfo__2pyjK .logo_footer__27Hxq {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .logo_cardContainer__2O50C .logo_backColor__2CEc1 {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .logo_cardContainer__2O50C .logo_backImage__3n5HN {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .logo_cardContainer__2O50C .logo_title__1MYpW {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .logo_cardContainer__2O50C .logo_secondaryInfoContainer__2BO1c {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .logo_cardContainer__2O50C .logo_mediasSummaryContainer__1MhqG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .logo_cardContainer__2O50C .logo_mediasSummaryContainer__1MhqG .logo_badge__1yVDI {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .logo_cardContainer__2O50C .logo_mediasSummaryContainer__1MhqG .logo_badge__1yVDI .logo_number__10fGr {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .logo_cardContainer__2O50C .logo_mediasSummaryContainer__1MhqG .logo_badge__1yVDI .logo_emoji__10cIZ {
        margin-left: 3px; }
  .logo_cardContainer__2O50C img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .logo_cardContainer__2O50C:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .logo_cardContainer__2O50C:hover .logo_hoveredInfo__2pyjK {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .logo_cardContainer__2O50C:hover .logo_noBlur__nBlOd {
      -webkit-filter: none !important;
              filter: none !important; }
    .logo_cardContainer__2O50C:hover .logo_backImage__3n5HN {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.logo_slick-active__t7NYD div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .logo_inputContainer__2yiCc .logo_input__15W2K {
    width: auto; }
  .logo_cardContainer__2O50C .logo_mediasSummaryContainer__1MhqG {
    display: none; } }

.logo_logoContainer__3YZnM {
  position: relative;
  height: 100px;
  width: 100px;
  -webkit-transform: translateY(10px) translateX(-10px);
          transform: translateY(10px) translateX(-10px); }
  .logo_logoContainer__3YZnM .logo_topContainer__1WuBk, .logo_logoContainer__3YZnM .logo_bottomContainer__1pDtQ {
    height: 45px;
    width: 90px;
    border-radius: 90px 90px 0 0; }
  .logo_logoContainer__3YZnM .logo_topContainer__1WuBk {
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);
    background-color: #FFC857;
    color: #08090A; }
    .logo_logoContainer__3YZnM .logo_topContainer__1WuBk .logo_icon__2tw0b {
      -webkit-transform: rotateZ(45deg);
              transform: rotateZ(45deg);
      position: absolute;
      top: 20px;
      left: 23px;
      font-size: 50px; }
  .logo_logoContainer__3YZnM .logo_bottomContainer__1pDtQ {
    background-color: #08090A;
    color: #FFC857;
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg);
    position: absolute;
    left: 32px;
    top: 32px;
    overflow: hidden; }
    .logo_logoContainer__3YZnM .logo_bottomContainer__1pDtQ .logo_icon__2tw0b {
      position: absolute;
      top: 21px;
      left: 23px;
      -webkit-transform: rotateZ(-135deg) rotateY(180deg);
              transform: rotateZ(-135deg) rotateY(180deg);
      font-size: 38px; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.footer_Form__19a23 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.footer_Form_label__3a1MZ {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.footer_Form_labelContent__1TaU5 {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.footer_Form_error__1xfbL {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.footer_Form_inputSubmit__11dF3 {
  width: auto !important; }

.footer_InputText__3XXV5 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .footer_InputText__3XXV5:focus {
    outline: none; }

.footer_inputContainer__2gU_w {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .footer_inputContainer__2gU_w.footer_small__3bXut {
    margin: 10px;
    padding: 0 15px; }
    .footer_inputContainer__2gU_w.footer_small__3bXut .footer_input__1mpYs {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .footer_inputContainer__2gU_w.footer_smallest__17ZUN {
    width: 200px;
    border-color: transparent; }
    .footer_inputContainer__2gU_w.footer_smallest__17ZUN .footer_input__1mpYs {
      font-size: 15px; }
    .footer_inputContainer__2gU_w.footer_smallest__17ZUN .footer_icon__2SshW {
      font-size: 30px;
      margin-right: 10px; }
    .footer_inputContainer__2gU_w.footer_smallest__17ZUN.footer_focus__29GIw {
      border-color: transparent; }
  .footer_inputContainer__2gU_w .footer_input__1mpYs {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .footer_inputContainer__2gU_w .footer_icon__2SshW {
    color: #BDD9BF;
    margin-right: 20px; }
  .footer_inputContainer__2gU_w.footer_focus__29GIw {
    border: 1px solid #FFC857; }
    .footer_inputContainer__2gU_w.footer_focus__29GIw .footer_icon__2SshW {
      color: #FFC857; }
    .footer_inputContainer__2gU_w.footer_focus__29GIw .footer_input__1mpYs {
      color: #FFC857; }

.footer_inputWithSuggestionsContainer__1HzXJ {
  display: flex;
  flex-direction: column; }
  .footer_inputWithSuggestionsContainer__1HzXJ .footer_alternativeSearch__1p9rd {
    color: #BDD9BF;
    font-size: 12px; }
    .footer_inputWithSuggestionsContainer__1HzXJ .footer_alternativeSearch__1p9rd span {
      margin-left: 5px; }
  .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV {
    width: 300px; }
    .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV .footer_loadingContainer__2CP8z {
      margin-top: 20px; }
    .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV .footer_suggestionItem__3pikg {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV .footer_suggestionItem__3pikg.footer_active__3xcgS {
        color: #FFC857; }
      .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV .footer_suggestionItem__3pikg:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .footer_inputWithSuggestionsContainer__1HzXJ .footer_suggestionsContainer__2siHV .footer_noMatch__8y4G2 {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .footer_inputWithSuggestionsContainer__1HzXJ .footer_inputContainer__2gU_w {
    margin: 0;
    padding: 0; }

.footer_hidden__PJ0Su {
  display: none !important; }

.footer_btn__2-owV {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .footer_btn__2-owV:hover, .footer_btn__2-owV:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .footer_btn__2-owV:focus {
    outline: none !important; }
  .footer_btn__2-owV::-moz-focus-inner {
    border: 0 !important; }
  .footer_btn__2-owV.footer_small__3bXut {
    font-size: 12px;
    padding: 6px 6px; }
  .footer_btn__2-owV.footer_disabled__3nZFL {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.footer_navMenuItem__9wamn {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.footer_navMenuItem__9wamn::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.footer_navMenuItem__9wamn.footer_active__3xcgS {
  color: #FFC857; }

.footer_navMenuItem__9wamn:hover::after,
.footer_navMenuItem__9wamn:focus::after,
.footer_navMenuItem__9wamn.footer_active__3xcgS::after {
  width: 100%; }

.footer_navMenuItem__9wamn.footer_active__3xcgS::after {
  background: #FFC857;
  width: 100%; }

.footer_cardContainer__2Onj0 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .footer_cardContainer__2Onj0 .footer_hoveredInfo__3iE0n {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .footer_cardContainer__2Onj0 .footer_hoveredInfo__3iE0n .footer_footer__EQtlF {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .footer_cardContainer__2Onj0 .footer_backColor__3i_oB {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .footer_cardContainer__2Onj0 .footer_backImage__2h32g {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .footer_cardContainer__2Onj0 .footer_title__3nT9v {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .footer_cardContainer__2Onj0 .footer_secondaryInfoContainer__8WAdc {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .footer_cardContainer__2Onj0 .footer_mediasSummaryContainer__2oeIY {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .footer_cardContainer__2Onj0 .footer_mediasSummaryContainer__2oeIY .footer_badge__1ZjWj {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .footer_cardContainer__2Onj0 .footer_mediasSummaryContainer__2oeIY .footer_badge__1ZjWj .footer_number__1Aaqv {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .footer_cardContainer__2Onj0 .footer_mediasSummaryContainer__2oeIY .footer_badge__1ZjWj .footer_emoji__22gTV {
        margin-left: 3px; }
  .footer_cardContainer__2Onj0 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .footer_cardContainer__2Onj0:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .footer_cardContainer__2Onj0:hover .footer_hoveredInfo__3iE0n {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .footer_cardContainer__2Onj0:hover .footer_noBlur__1ouDW {
      -webkit-filter: none !important;
              filter: none !important; }
    .footer_cardContainer__2Onj0:hover .footer_backImage__2h32g {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.footer_slick-active__2T9Jm div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .footer_inputContainer__2gU_w .footer_input__1mpYs {
    width: auto; }
  .footer_cardContainer__2Onj0 .footer_mediasSummaryContainer__2oeIY {
    display: none; } }

.footer_footerContainer__AaFZD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #2E4052;
  color: #FFC857;
  padding: 0 30px;
  box-sizing: border-box; }
  .footer_footerContainer__AaFZD .footer_copyrightContainer__19o9v {
    font-size: 12px; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.pageLayout_Form__2t8XD {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.pageLayout_Form_label__AAA-e {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.pageLayout_Form_labelContent__3_fMo {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.pageLayout_Form_error__1hbsU {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.pageLayout_Form_inputSubmit__c1OT1 {
  width: auto !important; }

.pageLayout_InputText__12vfZ {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .pageLayout_InputText__12vfZ:focus {
    outline: none; }

.pageLayout_inputContainer__2TEdS {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .pageLayout_inputContainer__2TEdS.pageLayout_small__2vmkG {
    margin: 10px;
    padding: 0 15px; }
    .pageLayout_inputContainer__2TEdS.pageLayout_small__2vmkG .pageLayout_input__2CilJ {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .pageLayout_inputContainer__2TEdS.pageLayout_smallest__3xXPW {
    width: 200px;
    border-color: transparent; }
    .pageLayout_inputContainer__2TEdS.pageLayout_smallest__3xXPW .pageLayout_input__2CilJ {
      font-size: 15px; }
    .pageLayout_inputContainer__2TEdS.pageLayout_smallest__3xXPW .pageLayout_icon__2rHew {
      font-size: 30px;
      margin-right: 10px; }
    .pageLayout_inputContainer__2TEdS.pageLayout_smallest__3xXPW.pageLayout_focus__2rpYH {
      border-color: transparent; }
  .pageLayout_inputContainer__2TEdS .pageLayout_input__2CilJ {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .pageLayout_inputContainer__2TEdS .pageLayout_icon__2rHew {
    color: #BDD9BF;
    margin-right: 20px; }
  .pageLayout_inputContainer__2TEdS.pageLayout_focus__2rpYH {
    border: 1px solid #FFC857; }
    .pageLayout_inputContainer__2TEdS.pageLayout_focus__2rpYH .pageLayout_icon__2rHew {
      color: #FFC857; }
    .pageLayout_inputContainer__2TEdS.pageLayout_focus__2rpYH .pageLayout_input__2CilJ {
      color: #FFC857; }

.pageLayout_inputWithSuggestionsContainer__2xYMN {
  display: flex;
  flex-direction: column; }
  .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_alternativeSearch__1Ad_h {
    color: #BDD9BF;
    font-size: 12px; }
    .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_alternativeSearch__1Ad_h span {
      margin-left: 5px; }
  .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ {
    width: 300px; }
    .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ .pageLayout_loadingContainer__iXNi1 {
      margin-top: 20px; }
    .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ .pageLayout_suggestionItem__3ISKT {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ .pageLayout_suggestionItem__3ISKT.pageLayout_active__1XYMg {
        color: #FFC857; }
      .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ .pageLayout_suggestionItem__3ISKT:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_suggestionsContainer__2c3v_ .pageLayout_noMatch__1o-7n {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .pageLayout_inputWithSuggestionsContainer__2xYMN .pageLayout_inputContainer__2TEdS {
    margin: 0;
    padding: 0; }

.pageLayout_hidden__1JMb7 {
  display: none !important; }

.pageLayout_btn__3b0np {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .pageLayout_btn__3b0np:hover, .pageLayout_btn__3b0np:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .pageLayout_btn__3b0np:focus {
    outline: none !important; }
  .pageLayout_btn__3b0np::-moz-focus-inner {
    border: 0 !important; }
  .pageLayout_btn__3b0np.pageLayout_small__2vmkG {
    font-size: 12px;
    padding: 6px 6px; }
  .pageLayout_btn__3b0np.pageLayout_disabled__E2u78 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.pageLayout_navMenuItem__3UK8P {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.pageLayout_navMenuItem__3UK8P::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.pageLayout_navMenuItem__3UK8P.pageLayout_active__1XYMg {
  color: #FFC857; }

.pageLayout_navMenuItem__3UK8P:hover::after,
.pageLayout_navMenuItem__3UK8P:focus::after,
.pageLayout_navMenuItem__3UK8P.pageLayout_active__1XYMg::after {
  width: 100%; }

.pageLayout_navMenuItem__3UK8P.pageLayout_active__1XYMg::after {
  background: #FFC857;
  width: 100%; }

.pageLayout_cardContainer__21c0b {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .pageLayout_cardContainer__21c0b .pageLayout_hoveredInfo__MksNu {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .pageLayout_cardContainer__21c0b .pageLayout_hoveredInfo__MksNu .pageLayout_footer__1QRip {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .pageLayout_cardContainer__21c0b .pageLayout_backColor__2Bne5 {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .pageLayout_cardContainer__21c0b .pageLayout_backImage__3u55R {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .pageLayout_cardContainer__21c0b .pageLayout_title__2m0r_ {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .pageLayout_cardContainer__21c0b .pageLayout_secondaryInfoContainer__3B8zY {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .pageLayout_cardContainer__21c0b .pageLayout_mediasSummaryContainer__11bQa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .pageLayout_cardContainer__21c0b .pageLayout_mediasSummaryContainer__11bQa .pageLayout_badge__1TGlF {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .pageLayout_cardContainer__21c0b .pageLayout_mediasSummaryContainer__11bQa .pageLayout_badge__1TGlF .pageLayout_number__2j94Y {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .pageLayout_cardContainer__21c0b .pageLayout_mediasSummaryContainer__11bQa .pageLayout_badge__1TGlF .pageLayout_emoji__315Fg {
        margin-left: 3px; }
  .pageLayout_cardContainer__21c0b img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .pageLayout_cardContainer__21c0b:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .pageLayout_cardContainer__21c0b:hover .pageLayout_hoveredInfo__MksNu {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .pageLayout_cardContainer__21c0b:hover .pageLayout_noBlur__3-Gja {
      -webkit-filter: none !important;
              filter: none !important; }
    .pageLayout_cardContainer__21c0b:hover .pageLayout_backImage__3u55R {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.pageLayout_slick-active__39v-c div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .pageLayout_inputContainer__2TEdS .pageLayout_input__2CilJ {
    width: auto; }
  .pageLayout_cardContainer__21c0b .pageLayout_mediasSummaryContainer__11bQa {
    display: none; } }

.pageLayout_pageContainer__3WWLZ {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .pageLayout_pageContainer__3WWLZ .pageLayout_pageContent__3lFx6 {
    z-index: 5;
    flex: 1 1;
    background-color: #2E4052;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .pageLayout_pageContainer__3WWLZ .pageLayout_pageContent__3lFx6 .pageLayout_titleContainer__3BwPY {
      margin-bottom: 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 20px;
      color: #BDD9BF;
      padding: 20px; }
    .pageLayout_pageContainer__3WWLZ .pageLayout_pageContent__3lFx6.pageLayout_dark__oZ7Yr {
      background-color: #08090A; }
  .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv {
    display: none; }
    .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_sidebarButton__21qPy {
      position: absolute;
      left: 17px;
      top: 17px;
      z-index: 4;
      color: #BDD9BF; }
      .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_sidebarButton__21qPy .pageLayout_icon__2rHew {
        width: 25px;
        height: 25px; }
    .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_menuNavMobile__3MJHb {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_menuNavMobile__3MJHb .pageLayout_menuMobileLink__1eg69 {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        border-bottom: 1px solid #BDD9BF;
        font-weight: 300;
        padding: 15px 50px;
        width: 100%;
        box-sizing: border-box;
        text-align: left; }
        .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_menuNavMobile__3MJHb .pageLayout_menuMobileLink__1eg69:hover {
          color: white;
          background-color: #BDD9BF; }
      .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv .pageLayout_menuNavMobile__3MJHb .pageLayout_menuMobileLinkActive__2iAIf {
        color: white;
        background-color: #BDD9BF;
        font-weight: normal; }

@media screen and (max-width: 700px) {
  .pageLayout_pageContainer__3WWLZ .pageLayout_sidebarContainer__2eqFv {
    display: flex; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.cookieConsent_Form__Fqp6H {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.cookieConsent_Form_label__3mVDM {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.cookieConsent_Form_labelContent__3TGMo {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.cookieConsent_Form_error__2KVw5 {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.cookieConsent_Form_inputSubmit__10oql {
  width: auto !important; }

.cookieConsent_InputText__3rBTA {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .cookieConsent_InputText__3rBTA:focus {
    outline: none; }

.cookieConsent_inputContainer__gJAD9 {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .cookieConsent_inputContainer__gJAD9.cookieConsent_small__3ZS6Z {
    margin: 10px;
    padding: 0 15px; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_small__3ZS6Z .cookieConsent_input__18wQs {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .cookieConsent_inputContainer__gJAD9.cookieConsent_smallest__17pd3 {
    width: 200px;
    border-color: transparent; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_smallest__17pd3 .cookieConsent_input__18wQs {
      font-size: 15px; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_smallest__17pd3 .cookieConsent_icon__2RumN {
      font-size: 30px;
      margin-right: 10px; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_smallest__17pd3.cookieConsent_focus__3bTh5 {
      border-color: transparent; }
  .cookieConsent_inputContainer__gJAD9 .cookieConsent_input__18wQs {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .cookieConsent_inputContainer__gJAD9 .cookieConsent_icon__2RumN {
    color: #BDD9BF;
    margin-right: 20px; }
  .cookieConsent_inputContainer__gJAD9.cookieConsent_focus__3bTh5 {
    border: 1px solid #FFC857; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_focus__3bTh5 .cookieConsent_icon__2RumN {
      color: #FFC857; }
    .cookieConsent_inputContainer__gJAD9.cookieConsent_focus__3bTh5 .cookieConsent_input__18wQs {
      color: #FFC857; }

.cookieConsent_inputWithSuggestionsContainer__1ptAM {
  display: flex;
  flex-direction: column; }
  .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_alternativeSearch__2PGtn {
    color: #BDD9BF;
    font-size: 12px; }
    .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_alternativeSearch__2PGtn span {
      margin-left: 5px; }
  .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U {
    width: 300px; }
    .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U .cookieConsent_loadingContainer__vBVP5 {
      margin-top: 20px; }
    .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U .cookieConsent_suggestionItem__d4Oqv {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U .cookieConsent_suggestionItem__d4Oqv.cookieConsent_active__CYQpC {
        color: #FFC857; }
      .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U .cookieConsent_suggestionItem__d4Oqv:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_suggestionsContainer__9ba5U .cookieConsent_noMatch__3hWQb {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .cookieConsent_inputWithSuggestionsContainer__1ptAM .cookieConsent_inputContainer__gJAD9 {
    margin: 0;
    padding: 0; }

.cookieConsent_hidden__3n3pD {
  display: none !important; }

.cookieConsent_btn__LKooR {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .cookieConsent_btn__LKooR:hover, .cookieConsent_btn__LKooR:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .cookieConsent_btn__LKooR:focus {
    outline: none !important; }
  .cookieConsent_btn__LKooR::-moz-focus-inner {
    border: 0 !important; }
  .cookieConsent_btn__LKooR.cookieConsent_small__3ZS6Z {
    font-size: 12px;
    padding: 6px 6px; }
  .cookieConsent_btn__LKooR.cookieConsent_disabled__1vZRp {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.cookieConsent_navMenuItem__1fPGE {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.cookieConsent_navMenuItem__1fPGE::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.cookieConsent_navMenuItem__1fPGE.cookieConsent_active__CYQpC {
  color: #FFC857; }

.cookieConsent_navMenuItem__1fPGE:hover::after,
.cookieConsent_navMenuItem__1fPGE:focus::after,
.cookieConsent_navMenuItem__1fPGE.cookieConsent_active__CYQpC::after {
  width: 100%; }

.cookieConsent_navMenuItem__1fPGE.cookieConsent_active__CYQpC::after {
  background: #FFC857;
  width: 100%; }

.cookieConsent_cardContainer__2Ew-0 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_hoveredInfo__100KK {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .cookieConsent_cardContainer__2Ew-0 .cookieConsent_hoveredInfo__100KK .cookieConsent_footer__1QJPG {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_backColor__182Bs {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_backImage__1HmJT {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_title__DZGgY {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_secondaryInfoContainer__3-T9q {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_mediasSummaryContainer__3ECcm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .cookieConsent_cardContainer__2Ew-0 .cookieConsent_mediasSummaryContainer__3ECcm .cookieConsent_badge__37RG9 {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .cookieConsent_cardContainer__2Ew-0 .cookieConsent_mediasSummaryContainer__3ECcm .cookieConsent_badge__37RG9 .cookieConsent_number__3PR7o {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .cookieConsent_cardContainer__2Ew-0 .cookieConsent_mediasSummaryContainer__3ECcm .cookieConsent_badge__37RG9 .cookieConsent_emoji__2xw5w {
        margin-left: 3px; }
  .cookieConsent_cardContainer__2Ew-0 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .cookieConsent_cardContainer__2Ew-0:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .cookieConsent_cardContainer__2Ew-0:hover .cookieConsent_hoveredInfo__100KK {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .cookieConsent_cardContainer__2Ew-0:hover .cookieConsent_noBlur__2kOAo {
      -webkit-filter: none !important;
              filter: none !important; }
    .cookieConsent_cardContainer__2Ew-0:hover .cookieConsent_backImage__1HmJT {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.cookieConsent_slick-active__1ouzD div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .cookieConsent_inputContainer__gJAD9 .cookieConsent_input__18wQs {
    width: auto; }
  .cookieConsent_cardContainer__2Ew-0 .cookieConsent_mediasSummaryContainer__3ECcm {
    display: none; } }

.cookieConsent_cookieConsentContainer__1tVeU {
  position: fixed;
  margin: 5% auto;
  padding: 30px 50px;
  width: 800px;
  left: 0;
  right: 0;
  bottom: -50px;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 #FFC857;
  background-color: #08090A;
  color: #BDD9BF;
  z-index: 5; }
  .cookieConsent_cookieConsentContainer__1tVeU strong {
    color: #FFC857; }
  .cookieConsent_cookieConsentContainer__1tVeU .cookieConsent_cookieConsentFooter__D5AJa {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .cookieConsent_cookieConsentContainer__1tVeU .cookieConsent_cookieConsentFooter__D5AJa .cookieConsent_btn__LKooR {
      margin: 0 5px; }

@media screen and (max-width: 700px) {
  .cookieConsent_cookieConsentContainer__1tVeU {
    bottom: unset;
    left: unset;
    right: 0;
    width: unset;
    top: 50%;
    margin: 0 10px;
    padding: 25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.drawerMenuNav_Form__yw13L {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.drawerMenuNav_Form_label__gHOae {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.drawerMenuNav_Form_labelContent__SWEBB {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.drawerMenuNav_Form_error__1DZYC {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.drawerMenuNav_Form_inputSubmit__24_-w {
  width: auto !important; }

.drawerMenuNav_InputText__32Dyj {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .drawerMenuNav_InputText__32Dyj:focus {
    outline: none; }

.drawerMenuNav_inputContainer__32IIr {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_small__1CwhC {
    margin: 10px;
    padding: 0 15px; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_small__1CwhC .drawerMenuNav_input__1Dnu4 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_smallest__17BAF {
    width: 200px;
    border-color: transparent; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_smallest__17BAF .drawerMenuNav_input__1Dnu4 {
      font-size: 15px; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_smallest__17BAF .drawerMenuNav_icon__1T_Yv {
      font-size: 30px;
      margin-right: 10px; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_smallest__17BAF.drawerMenuNav_focus__3m-lT {
      border-color: transparent; }
  .drawerMenuNav_inputContainer__32IIr .drawerMenuNav_input__1Dnu4 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .drawerMenuNav_inputContainer__32IIr .drawerMenuNav_icon__1T_Yv {
    color: #BDD9BF;
    margin-right: 20px; }
  .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_focus__3m-lT {
    border: 1px solid #FFC857; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_focus__3m-lT .drawerMenuNav_icon__1T_Yv {
      color: #FFC857; }
    .drawerMenuNav_inputContainer__32IIr.drawerMenuNav_focus__3m-lT .drawerMenuNav_input__1Dnu4 {
      color: #FFC857; }

.drawerMenuNav_inputWithSuggestionsContainer__3NBAP {
  display: flex;
  flex-direction: column; }
  .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_alternativeSearch__32NO9 {
    color: #BDD9BF;
    font-size: 12px; }
    .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_alternativeSearch__32NO9 span {
      margin-left: 5px; }
  .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 {
    width: 300px; }
    .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 .drawerMenuNav_loadingContainer__zdiDI {
      margin-top: 20px; }
    .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 .drawerMenuNav_suggestionItem__3GOaz {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 .drawerMenuNav_suggestionItem__3GOaz.drawerMenuNav_active__RKTPf {
        color: #FFC857; }
      .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 .drawerMenuNav_suggestionItem__3GOaz:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_suggestionsContainer__3V0W2 .drawerMenuNav_noMatch__92L9T {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .drawerMenuNav_inputWithSuggestionsContainer__3NBAP .drawerMenuNav_inputContainer__32IIr {
    margin: 0;
    padding: 0; }

.drawerMenuNav_hidden__12BMw {
  display: none !important; }

.drawerMenuNav_btn__1PlqG {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .drawerMenuNav_btn__1PlqG:hover, .drawerMenuNav_btn__1PlqG:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .drawerMenuNav_btn__1PlqG:focus {
    outline: none !important; }
  .drawerMenuNav_btn__1PlqG::-moz-focus-inner {
    border: 0 !important; }
  .drawerMenuNav_btn__1PlqG.drawerMenuNav_small__1CwhC {
    font-size: 12px;
    padding: 6px 6px; }
  .drawerMenuNav_btn__1PlqG.drawerMenuNav_disabled__fqiA4 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.drawerMenuNav_navMenuItem__2L0XC {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.drawerMenuNav_navMenuItem__2L0XC::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.drawerMenuNav_navMenuItem__2L0XC.drawerMenuNav_active__RKTPf {
  color: #FFC857; }

.drawerMenuNav_navMenuItem__2L0XC:hover::after,
.drawerMenuNav_navMenuItem__2L0XC:focus::after,
.drawerMenuNav_navMenuItem__2L0XC.drawerMenuNav_active__RKTPf::after {
  width: 100%; }

.drawerMenuNav_navMenuItem__2L0XC.drawerMenuNav_active__RKTPf::after {
  background: #FFC857;
  width: 100%; }

.drawerMenuNav_cardContainer__2-yfS {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_hoveredInfo__3rzH9 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_hoveredInfo__3rzH9 .drawerMenuNav_footer__1X9sY {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_backColor__1dwrc {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_backImage__1EksM {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_title__e32KP {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_secondaryInfoContainer__1sKiO {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_mediasSummaryContainer__1NzPQ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_mediasSummaryContainer__1NzPQ .drawerMenuNav_badge__1Bj2V {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_mediasSummaryContainer__1NzPQ .drawerMenuNav_badge__1Bj2V .drawerMenuNav_number__rS9dE {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_mediasSummaryContainer__1NzPQ .drawerMenuNav_badge__1Bj2V .drawerMenuNav_emoji__2m_o3 {
        margin-left: 3px; }
  .drawerMenuNav_cardContainer__2-yfS img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .drawerMenuNav_cardContainer__2-yfS:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .drawerMenuNav_cardContainer__2-yfS:hover .drawerMenuNav_hoveredInfo__3rzH9 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .drawerMenuNav_cardContainer__2-yfS:hover .drawerMenuNav_noBlur__2ZhMi {
      -webkit-filter: none !important;
              filter: none !important; }
    .drawerMenuNav_cardContainer__2-yfS:hover .drawerMenuNav_backImage__1EksM {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.drawerMenuNav_slick-active__1XTGr div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .drawerMenuNav_inputContainer__32IIr .drawerMenuNav_input__1Dnu4 {
    width: auto; }
  .drawerMenuNav_cardContainer__2-yfS .drawerMenuNav_mediasSummaryContainer__1NzPQ {
    display: none; } }

.drawerMenuNav_sidebarContainer__wIKBZ {
  display: none; }
  .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_sidebarButton__32opk {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    z-index: 7;
    color: #BDD9BF; }
  .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    flex: 1 1; }
    .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuHeader__1zvmK {
      margin: 15px 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      width: 100%;
      height: 40px; }
      .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuHeader__1zvmK .drawerMenuNav_backButton__2lbBm {
        align-self: flex-start; }
        .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuHeader__1zvmK .drawerMenuNav_backButton__2lbBm svg {
          position: absolute;
          left: 15px; }
      .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuHeader__1zvmK .drawerMenuNav_logoImageContainer__38NKp {
        -webkit-transform: scale(0.4);
                transform: scale(0.4); }
    .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_navMenuMobileContainer__2Zecm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;
      flex: 1 1; }
    .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuMobileLink__3OH-9 {
      display: flex;
      flex-direction: row;
      text-transform: uppercase;
      padding: 15px 40px;
      width: 50px;
      height: auto;
      font-size: 18px;
      margin: 5px 0; }
      .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuMobileLink__3OH-9:hover {
        color: #FFC857; }
    .drawerMenuNav_sidebarContainer__wIKBZ .drawerMenuNav_menuNavMobile__AMFEG .drawerMenuNav_menuMobileLinkActive__1HFKT {
      color: white;
      background-color: #08090A;
      font-weight: normal; }

@media screen and (max-width: 700px) {
  .drawerMenuNav_sidebarContainer__wIKBZ {
    display: flex; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.settings_Form__38qHV {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.settings_Form_label__NXS5s {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.settings_Form_labelContent__39IOL {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.settings_Form_error__2sHmH {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.settings_Form_inputSubmit__1UxZn {
  width: auto !important; }

.settings_InputText__1B2Th {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .settings_InputText__1B2Th:focus {
    outline: none; }

.settings_inputContainer__3ET6Z {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .settings_inputContainer__3ET6Z.settings_small__3rFhA {
    margin: 10px;
    padding: 0 15px; }
    .settings_inputContainer__3ET6Z.settings_small__3rFhA .settings_input__GDuZl {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .settings_inputContainer__3ET6Z.settings_smallest__2LIrM {
    width: 200px;
    border-color: transparent; }
    .settings_inputContainer__3ET6Z.settings_smallest__2LIrM .settings_input__GDuZl {
      font-size: 15px; }
    .settings_inputContainer__3ET6Z.settings_smallest__2LIrM .settings_icon__3QMQM {
      font-size: 30px;
      margin-right: 10px; }
    .settings_inputContainer__3ET6Z.settings_smallest__2LIrM.settings_focus__10AOb {
      border-color: transparent; }
  .settings_inputContainer__3ET6Z .settings_input__GDuZl {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .settings_inputContainer__3ET6Z .settings_icon__3QMQM {
    color: #BDD9BF;
    margin-right: 20px; }
  .settings_inputContainer__3ET6Z.settings_focus__10AOb {
    border: 1px solid #FFC857; }
    .settings_inputContainer__3ET6Z.settings_focus__10AOb .settings_icon__3QMQM {
      color: #FFC857; }
    .settings_inputContainer__3ET6Z.settings_focus__10AOb .settings_input__GDuZl {
      color: #FFC857; }

.settings_inputWithSuggestionsContainer__P1g6L {
  display: flex;
  flex-direction: column; }
  .settings_inputWithSuggestionsContainer__P1g6L .settings_alternativeSearch__GVg4- {
    color: #BDD9BF;
    font-size: 12px; }
    .settings_inputWithSuggestionsContainer__P1g6L .settings_alternativeSearch__GVg4- span {
      margin-left: 5px; }
  .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 {
    width: 300px; }
    .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 .settings_loadingContainer__3hUM6 {
      margin-top: 20px; }
    .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 .settings_suggestionItem__1Sh0S {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 .settings_suggestionItem__1Sh0S.settings_active__14Fg4 {
        color: #FFC857; }
      .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 .settings_suggestionItem__1Sh0S:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .settings_inputWithSuggestionsContainer__P1g6L .settings_suggestionsContainer__GTVn0 .settings_noMatch__VRcYc {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .settings_inputWithSuggestionsContainer__P1g6L .settings_inputContainer__3ET6Z {
    margin: 0;
    padding: 0; }

.settings_hidden__1ZSmP {
  display: none !important; }

.settings_btn__1rX_q {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .settings_btn__1rX_q:hover, .settings_btn__1rX_q:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .settings_btn__1rX_q:focus {
    outline: none !important; }
  .settings_btn__1rX_q::-moz-focus-inner {
    border: 0 !important; }
  .settings_btn__1rX_q.settings_small__3rFhA {
    font-size: 12px;
    padding: 6px 6px; }
  .settings_btn__1rX_q.settings_disabled__28Oy6 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.settings_navMenuItem__gKs0j {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.settings_navMenuItem__gKs0j::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.settings_navMenuItem__gKs0j.settings_active__14Fg4 {
  color: #FFC857; }

.settings_navMenuItem__gKs0j:hover::after,
.settings_navMenuItem__gKs0j:focus::after,
.settings_navMenuItem__gKs0j.settings_active__14Fg4::after {
  width: 100%; }

.settings_navMenuItem__gKs0j.settings_active__14Fg4::after {
  background: #FFC857;
  width: 100%; }

.settings_cardContainer__qgyr4 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .settings_cardContainer__qgyr4 .settings_hoveredInfo__1tLpv {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .settings_cardContainer__qgyr4 .settings_hoveredInfo__1tLpv .settings_footer__2Enni {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .settings_cardContainer__qgyr4 .settings_backColor__3pwWJ {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .settings_cardContainer__qgyr4 .settings_backImage__3uYuC {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .settings_cardContainer__qgyr4 .settings_title__u_QWx {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .settings_cardContainer__qgyr4 .settings_secondaryInfoContainer__3_nXc {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .settings_cardContainer__qgyr4 .settings_mediasSummaryContainer__1RdGj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .settings_cardContainer__qgyr4 .settings_mediasSummaryContainer__1RdGj .settings_badge__2Va2V {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .settings_cardContainer__qgyr4 .settings_mediasSummaryContainer__1RdGj .settings_badge__2Va2V .settings_number__1RKcs {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .settings_cardContainer__qgyr4 .settings_mediasSummaryContainer__1RdGj .settings_badge__2Va2V .settings_emoji__11SCw {
        margin-left: 3px; }
  .settings_cardContainer__qgyr4 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .settings_cardContainer__qgyr4:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .settings_cardContainer__qgyr4:hover .settings_hoveredInfo__1tLpv {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .settings_cardContainer__qgyr4:hover .settings_noBlur__1BYPJ {
      -webkit-filter: none !important;
              filter: none !important; }
    .settings_cardContainer__qgyr4:hover .settings_backImage__3uYuC {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.settings_slick-active__HPdXp div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .settings_inputContainer__3ET6Z .settings_input__GDuZl {
    width: auto; }
  .settings_cardContainer__qgyr4 .settings_mediasSummaryContainer__1RdGj {
    display: none; } }

.settings_settingsContainer__3WYCw .settings_adminButton__12_iZ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 7;
  padding: 10px;
  background-color: #2E4052;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  right: 30px;
  top: 30px;
  height: 20px;
  width: 20px;
  color: #BDD9BF;
  transition: all 0.2s ease-in-out; }
  .settings_settingsContainer__3WYCw .settings_adminButton__12_iZ:hover, .settings_settingsContainer__3WYCw .settings_adminButton__12_iZ.settings_active__14Fg4 {
    color: #FFC857;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }

.settings_settingsPopoverContainer__th5x- {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  background-color: #08090A;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 #FFC857;
  margin-right: 20px; }
  .settings_settingsPopoverContainer__th5x- .settings_blockTitle__31mf2 {
    color: #FFC857;
    font-weight: normal;
    margin-bottom: 15px;
    font-size: 20px; }
    .settings_settingsPopoverContainer__th5x- .settings_blockTitle__31mf2 .settings_icon__3QMQM {
      margin-right: 10px;
      font-size: 15px; }
  .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY {
    display: flex;
    flex-direction: column; }
    .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY .settings_subBlock__Q4H1m {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px; }
      .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY .settings_subBlock__Q4H1m:last-child {
        margin-right: 0 !important; }
      .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY .settings_subBlock__Q4H1m .settings_subBlockTitle__3bxWI {
        color: #FFC857;
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 10px; }
    .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY .settings_filterRow__2dnSh {
      font-size: 15px;
      margin: 5px 0; }
      .settings_settingsPopoverContainer__th5x- .settings_settingsBlock__2-qfY .settings_filterRow__2dnSh .settings_filterName__X-QdD {
        color: #BDD9BF;
        padding-left: 5px; }
  .settings_settingsPopoverContainer__th5x- .settings_settingsFooter__1HMW1 {
    color: #BDD9BF;
    font-size: 15px;
    display: flex;
    flex-direction: column; }
    .settings_settingsPopoverContainer__th5x- .settings_settingsFooter__1HMW1 .settings_btn__1rX_q {
      margin-top: 20px; }

@media screen and (max-width: 700px) {
  .settings_settingsContainer__3WYCw {
    display: none; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.gameGrid_Form__1bN50 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.gameGrid_Form_label__or2wk {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.gameGrid_Form_labelContent__3wqvr {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.gameGrid_Form_error__1JAbd {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.gameGrid_Form_inputSubmit__3hCdC {
  width: auto !important; }

.gameGrid_InputText__1_N46 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .gameGrid_InputText__1_N46:focus {
    outline: none; }

.gameGrid_inputContainer__3q4nP {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .gameGrid_inputContainer__3q4nP.gameGrid_small__2yr_o {
    margin: 10px;
    padding: 0 15px; }
    .gameGrid_inputContainer__3q4nP.gameGrid_small__2yr_o .gameGrid_input__FHm1t {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .gameGrid_inputContainer__3q4nP.gameGrid_smallest__2SQGs {
    width: 200px;
    border-color: transparent; }
    .gameGrid_inputContainer__3q4nP.gameGrid_smallest__2SQGs .gameGrid_input__FHm1t {
      font-size: 15px; }
    .gameGrid_inputContainer__3q4nP.gameGrid_smallest__2SQGs .gameGrid_icon__3p0HG {
      font-size: 30px;
      margin-right: 10px; }
    .gameGrid_inputContainer__3q4nP.gameGrid_smallest__2SQGs.gameGrid_focus__Ztfe5 {
      border-color: transparent; }
  .gameGrid_inputContainer__3q4nP .gameGrid_input__FHm1t {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .gameGrid_inputContainer__3q4nP .gameGrid_icon__3p0HG {
    color: #BDD9BF;
    margin-right: 20px; }
  .gameGrid_inputContainer__3q4nP.gameGrid_focus__Ztfe5 {
    border: 1px solid #FFC857; }
    .gameGrid_inputContainer__3q4nP.gameGrid_focus__Ztfe5 .gameGrid_icon__3p0HG {
      color: #FFC857; }
    .gameGrid_inputContainer__3q4nP.gameGrid_focus__Ztfe5 .gameGrid_input__FHm1t {
      color: #FFC857; }

.gameGrid_inputWithSuggestionsContainer__2xJb8 {
  display: flex;
  flex-direction: column; }
  .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_alternativeSearch__2HgUK {
    color: #BDD9BF;
    font-size: 12px; }
    .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_alternativeSearch__2HgUK span {
      margin-left: 5px; }
  .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT {
    width: 300px; }
    .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT .gameGrid_loadingContainer__Q7V00 {
      margin-top: 20px; }
    .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT .gameGrid_suggestionItem__1h-4r {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT .gameGrid_suggestionItem__1h-4r.gameGrid_active__3Ynab {
        color: #FFC857; }
      .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT .gameGrid_suggestionItem__1h-4r:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_suggestionsContainer__o4sCT .gameGrid_noMatch__1iB5- {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .gameGrid_inputWithSuggestionsContainer__2xJb8 .gameGrid_inputContainer__3q4nP {
    margin: 0;
    padding: 0; }

.gameGrid_hidden__2XU86 {
  display: none !important; }

.gameGrid_btn__65P_a {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .gameGrid_btn__65P_a:hover, .gameGrid_btn__65P_a:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .gameGrid_btn__65P_a:focus {
    outline: none !important; }
  .gameGrid_btn__65P_a::-moz-focus-inner {
    border: 0 !important; }
  .gameGrid_btn__65P_a.gameGrid_small__2yr_o {
    font-size: 12px;
    padding: 6px 6px; }
  .gameGrid_btn__65P_a.gameGrid_disabled__2Tf1z {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.gameGrid_navMenuItem__13HV5 {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.gameGrid_navMenuItem__13HV5::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.gameGrid_navMenuItem__13HV5.gameGrid_active__3Ynab {
  color: #FFC857; }

.gameGrid_navMenuItem__13HV5:hover::after,
.gameGrid_navMenuItem__13HV5:focus::after,
.gameGrid_navMenuItem__13HV5.gameGrid_active__3Ynab::after {
  width: 100%; }

.gameGrid_navMenuItem__13HV5.gameGrid_active__3Ynab::after {
  background: #FFC857;
  width: 100%; }

.gameGrid_cardContainer__3_DmH {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .gameGrid_cardContainer__3_DmH .gameGrid_hoveredInfo__1PxXY {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .gameGrid_cardContainer__3_DmH .gameGrid_hoveredInfo__1PxXY .gameGrid_footer__2dE1x {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .gameGrid_cardContainer__3_DmH .gameGrid_backColor__d-Qj- {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .gameGrid_cardContainer__3_DmH .gameGrid_backImage__39qUr {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .gameGrid_cardContainer__3_DmH .gameGrid_title__1mXO- {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .gameGrid_cardContainer__3_DmH .gameGrid_secondaryInfoContainer__25mDj {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .gameGrid_cardContainer__3_DmH .gameGrid_mediasSummaryContainer__14_43 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .gameGrid_cardContainer__3_DmH .gameGrid_mediasSummaryContainer__14_43 .gameGrid_badge__12Kjb {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .gameGrid_cardContainer__3_DmH .gameGrid_mediasSummaryContainer__14_43 .gameGrid_badge__12Kjb .gameGrid_number__2btxx {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .gameGrid_cardContainer__3_DmH .gameGrid_mediasSummaryContainer__14_43 .gameGrid_badge__12Kjb .gameGrid_emoji__oVyOp {
        margin-left: 3px; }
  .gameGrid_cardContainer__3_DmH img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .gameGrid_cardContainer__3_DmH:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .gameGrid_cardContainer__3_DmH:hover .gameGrid_hoveredInfo__1PxXY {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .gameGrid_cardContainer__3_DmH:hover .gameGrid_noBlur__1T1s_ {
      -webkit-filter: none !important;
              filter: none !important; }
    .gameGrid_cardContainer__3_DmH:hover .gameGrid_backImage__39qUr {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.gameGrid_slick-active__LbJbE div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .gameGrid_inputContainer__3q4nP .gameGrid_input__FHm1t {
    width: auto; }
  .gameGrid_cardContainer__3_DmH .gameGrid_mediasSummaryContainer__14_43 {
    display: none; } }

.gameGrid_gamesGridContainer__345Oq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1500px; }
  .gameGrid_gamesGridContainer__345Oq .gameGrid_cardContainer__3_DmH {
    width: 200px;
    height: 266px;
    opacity: 1; }

.gameGrid_filtersTagsContainer__3Q4PF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; }
  .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH {
    border: none;
    margin: 0 5px;
    transition: all 0.1s ease-in-out; }
    .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH .gameGrid_text__1PzX1 {
      opacity: 0.8; }
    .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH .gameGrid_icon__3p0HG {
      opacity: 1;
      margin-bottom: -2px; }
    .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH.gameGrid_active__3Ynab {
      color: #FFC857;
      background-color: #08090A; }
      .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH.gameGrid_active__3Ynab:hover {
        color: #FFC857; }
      .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH.gameGrid_active__3Ynab .gameGrid_text__1PzX1 {
        opacity: 1; }
    .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH:hover {
      color: #BDD9BF;
      -webkit-transform: unset;
              transform: unset;
      box-shadow: unset; }
      .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH:hover .gameGrid_text__1PzX1 {
        opacity: 1; }
    .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH.gameGrid_disabled__2Tf1z {
      -webkit-transform: unset !important;
              transform: unset !important;
      cursor: default; }
      .gameGrid_filtersTagsContainer__3Q4PF .gameGrid_filterTag__3GhWH.gameGrid_disabled__2Tf1z .gameGrid_text__1PzX1 {
        opacity: 0.8 !important; }

.gameGrid_separator__28kbM {
  border-top: 1px solid #FFC857;
  opacity: 0.7;
  margin-bottom: 30px; }

.gameGrid_mediaGridContainer__3d2E0 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0; }
  .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH {
    width: 150px;
    height: 150px;
    opacity: 1; }

.gameGrid_noResultContainer__3f4eT {
  color: #BDD9BF; }
  .gameGrid_noResultContainer__3f4eT strong {
    color: #FFC857; }
  .gameGrid_noResultContainer__3f4eT .gameGrid_btn__65P_a {
    margin-top: 10px;
    width: 100%; }

.gameGrid_loaderContainer__10Xap {
  margin: 50px;
  flex: 1 1; }

@media screen and (max-width: 700px) {
  .gameGrid_gamesGridContainer__345Oq .gameGrid_cardContainer__3_DmH {
    width: 150px;
    height: 200px; }
  .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH {
    width: 120px;
    height: 120px;
    opacity: 1; }
    .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH .gameGrid_hoveredInfo__1PxXY {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
      .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH .gameGrid_hoveredInfo__1PxXY .gameGrid_secondaryInfoContainer__25mDj {
        display: none; }
      .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH .gameGrid_hoveredInfo__1PxXY .gameGrid_title__1mXO- {
        margin-top: 5px;
        text-transform: none;
        font-size: 12px;
        text-align: left; }
    .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH .gameGrid_backColor__d-Qj- {
      opacity: 1;
      height: 200px;
      width: 100%;
      background: linear-gradient(#08090A 0%, rgba(8, 9, 10, 0) 30%);
      position: absolute;
      left: 0;
      top: 0; }
    .gameGrid_mediaGridContainer__3d2E0 .gameGrid_cardContainer__3_DmH .gameGrid_noBlur__1T1s_ {
      -webkit-filter: none !important;
              filter: none !important; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.loadingSpinner_Form__23HRX {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.loadingSpinner_Form_label__367Ma {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.loadingSpinner_Form_labelContent__31XKe {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.loadingSpinner_Form_error__CAJIw {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.loadingSpinner_Form_inputSubmit__1ZAdt {
  width: auto !important; }

.loadingSpinner_InputText__12pjd {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .loadingSpinner_InputText__12pjd:focus {
    outline: none; }

.loadingSpinner_inputContainer__3wlL7 {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .loadingSpinner_inputContainer__3wlL7.loadingSpinner_small__2msY0 {
    margin: 10px;
    padding: 0 15px; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_small__2msY0 .loadingSpinner_input__1HrE2 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .loadingSpinner_inputContainer__3wlL7.loadingSpinner_smallest__1XYNC {
    width: 200px;
    border-color: transparent; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_smallest__1XYNC .loadingSpinner_input__1HrE2 {
      font-size: 15px; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_smallest__1XYNC .loadingSpinner_icon__3LxKi {
      font-size: 30px;
      margin-right: 10px; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_smallest__1XYNC.loadingSpinner_focus__1nA3l {
      border-color: transparent; }
  .loadingSpinner_inputContainer__3wlL7 .loadingSpinner_input__1HrE2 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .loadingSpinner_inputContainer__3wlL7 .loadingSpinner_icon__3LxKi {
    color: #BDD9BF;
    margin-right: 20px; }
  .loadingSpinner_inputContainer__3wlL7.loadingSpinner_focus__1nA3l {
    border: 1px solid #FFC857; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_focus__1nA3l .loadingSpinner_icon__3LxKi {
      color: #FFC857; }
    .loadingSpinner_inputContainer__3wlL7.loadingSpinner_focus__1nA3l .loadingSpinner_input__1HrE2 {
      color: #FFC857; }

.loadingSpinner_inputWithSuggestionsContainer__3FkO6 {
  display: flex;
  flex-direction: column; }
  .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_alternativeSearch__1FkdB {
    color: #BDD9BF;
    font-size: 12px; }
    .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_alternativeSearch__1FkdB span {
      margin-left: 5px; }
  .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR {
    width: 300px; }
    .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR .loadingSpinner_loadingContainer__Musr4 {
      margin-top: 20px; }
    .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR .loadingSpinner_suggestionItem__2OPl1 {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR .loadingSpinner_suggestionItem__2OPl1.loadingSpinner_active__2oJig {
        color: #FFC857; }
      .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR .loadingSpinner_suggestionItem__2OPl1:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_suggestionsContainer__2DyFR .loadingSpinner_noMatch__3r8Ej {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .loadingSpinner_inputWithSuggestionsContainer__3FkO6 .loadingSpinner_inputContainer__3wlL7 {
    margin: 0;
    padding: 0; }

.loadingSpinner_hidden__53C0N {
  display: none !important; }

.loadingSpinner_btn__jqWH4 {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .loadingSpinner_btn__jqWH4:hover, .loadingSpinner_btn__jqWH4:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .loadingSpinner_btn__jqWH4:focus {
    outline: none !important; }
  .loadingSpinner_btn__jqWH4::-moz-focus-inner {
    border: 0 !important; }
  .loadingSpinner_btn__jqWH4.loadingSpinner_small__2msY0 {
    font-size: 12px;
    padding: 6px 6px; }
  .loadingSpinner_btn__jqWH4.loadingSpinner_disabled__2rBsl {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.loadingSpinner_navMenuItem__3vQTY {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.loadingSpinner_navMenuItem__3vQTY::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.loadingSpinner_navMenuItem__3vQTY.loadingSpinner_active__2oJig {
  color: #FFC857; }

.loadingSpinner_navMenuItem__3vQTY:hover::after,
.loadingSpinner_navMenuItem__3vQTY:focus::after,
.loadingSpinner_navMenuItem__3vQTY.loadingSpinner_active__2oJig::after {
  width: 100%; }

.loadingSpinner_navMenuItem__3vQTY.loadingSpinner_active__2oJig::after {
  background: #FFC857;
  width: 100%; }

.loadingSpinner_cardContainer__2vKXG {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_hoveredInfo__2i9WT {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .loadingSpinner_cardContainer__2vKXG .loadingSpinner_hoveredInfo__2i9WT .loadingSpinner_footer__1MpTm {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_backColor__2Qbma {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_backImage__U9rm_ {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_title__1nJsn {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_secondaryInfoContainer__2urh_ {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_mediasSummaryContainer__21sOx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .loadingSpinner_cardContainer__2vKXG .loadingSpinner_mediasSummaryContainer__21sOx .loadingSpinner_badge__168cy {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .loadingSpinner_cardContainer__2vKXG .loadingSpinner_mediasSummaryContainer__21sOx .loadingSpinner_badge__168cy .loadingSpinner_number__3yUv0 {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .loadingSpinner_cardContainer__2vKXG .loadingSpinner_mediasSummaryContainer__21sOx .loadingSpinner_badge__168cy .loadingSpinner_emoji__3Ho9i {
        margin-left: 3px; }
  .loadingSpinner_cardContainer__2vKXG img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .loadingSpinner_cardContainer__2vKXG:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .loadingSpinner_cardContainer__2vKXG:hover .loadingSpinner_hoveredInfo__2i9WT {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .loadingSpinner_cardContainer__2vKXG:hover .loadingSpinner_noBlur__3pczK {
      -webkit-filter: none !important;
              filter: none !important; }
    .loadingSpinner_cardContainer__2vKXG:hover .loadingSpinner_backImage__U9rm_ {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.loadingSpinner_slick-active__1kbeh div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .loadingSpinner_inputContainer__3wlL7 .loadingSpinner_input__1HrE2 {
    width: auto; }
  .loadingSpinner_cardContainer__2vKXG .loadingSpinner_mediasSummaryContainer__21sOx {
    display: none; } }

@-webkit-keyframes loadingSpinner_flip-vertical-left__1zOFS {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0); }
  50% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  100% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg); } }

@keyframes loadingSpinner_flip-vertical-left__1zOFS {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0); }
  50% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  100% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg); } }

.loadingSpinner_LoadingSpinner__2F97s {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.loadingSpinner_LoadingSpinner_flipCard__1fj6n {
  width: 60px;
  height: 60px;
  background-color: transparent;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  /* Remove this if you don't want the 3D effect */ }

.loadingSpinner_LoadingSpinner_flipCard__small__11VYg {
  height: 30px;
  width: 30px; }

.loadingSpinner_LoadingSpinner_inner__mYUvf {
  -webkit-animation: loadingSpinner_flip-vertical-left__1zOFS 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: loadingSpinner_flip-vertical-left__1zOFS 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.loadingSpinner_LoadingSpinner_front__1PggY, .loadingSpinner_LoadingSpinner_back__1iB9Y {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.loadingSpinner_LoadingSpinner_front__1PggY {
  background-color: #08090A; }

.loadingSpinner_LoadingSpinner_back__1iB9Y {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  background-color: #FFC857; }

.loadingSpinner_LoadingSpinner_frontIcon__3uNaJ, .loadingSpinner_LoadingSpinner_backIcon__lTkDb {
  font-size: 35px;
  color: #08090A;
  height: 40px; }

.loadingSpinner_LoadingSpinner_frontIcon__small__2oN9c, .loadingSpinner_LoadingSpinner_backIcon__small__3Y4od {
  height: 20px;
  width: 20px; }

.loadingSpinner_LoadingSpinner_frontIcon__3uNaJ {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  color: #FFC857 !important; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.errorMessage_Form__esgWa {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.errorMessage_Form_label__16Cw1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.errorMessage_Form_labelContent___5HmE {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.errorMessage_Form_error__19a6O {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.errorMessage_Form_inputSubmit__1Vs22 {
  width: auto !important; }

.errorMessage_InputText__37_xs {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .errorMessage_InputText__37_xs:focus {
    outline: none; }

.errorMessage_inputContainer__B2jik {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .errorMessage_inputContainer__B2jik.errorMessage_small__3DJ_t {
    margin: 10px;
    padding: 0 15px; }
    .errorMessage_inputContainer__B2jik.errorMessage_small__3DJ_t .errorMessage_input__2t-2u {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .errorMessage_inputContainer__B2jik.errorMessage_smallest__w6_Y2 {
    width: 200px;
    border-color: transparent; }
    .errorMessage_inputContainer__B2jik.errorMessage_smallest__w6_Y2 .errorMessage_input__2t-2u {
      font-size: 15px; }
    .errorMessage_inputContainer__B2jik.errorMessage_smallest__w6_Y2 .errorMessage_icon__1dLAB {
      font-size: 30px;
      margin-right: 10px; }
    .errorMessage_inputContainer__B2jik.errorMessage_smallest__w6_Y2.errorMessage_focus__feP9O {
      border-color: transparent; }
  .errorMessage_inputContainer__B2jik .errorMessage_input__2t-2u {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .errorMessage_inputContainer__B2jik .errorMessage_icon__1dLAB {
    color: #BDD9BF;
    margin-right: 20px; }
  .errorMessage_inputContainer__B2jik.errorMessage_focus__feP9O {
    border: 1px solid #FFC857; }
    .errorMessage_inputContainer__B2jik.errorMessage_focus__feP9O .errorMessage_icon__1dLAB {
      color: #FFC857; }
    .errorMessage_inputContainer__B2jik.errorMessage_focus__feP9O .errorMessage_input__2t-2u {
      color: #FFC857; }

.errorMessage_inputWithSuggestionsContainer__OyfcP {
  display: flex;
  flex-direction: column; }
  .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_alternativeSearch__rrdXI {
    color: #BDD9BF;
    font-size: 12px; }
    .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_alternativeSearch__rrdXI span {
      margin-left: 5px; }
  .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI {
    width: 300px; }
    .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI .errorMessage_loadingContainer__1V6sm {
      margin-top: 20px; }
    .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI .errorMessage_suggestionItem__KVdmY {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI .errorMessage_suggestionItem__KVdmY.errorMessage_active__10FaV {
        color: #FFC857; }
      .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI .errorMessage_suggestionItem__KVdmY:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_suggestionsContainer__3AHuI .errorMessage_noMatch__3S5qJ {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .errorMessage_inputWithSuggestionsContainer__OyfcP .errorMessage_inputContainer__B2jik {
    margin: 0;
    padding: 0; }

.errorMessage_hidden__Os5Uu {
  display: none !important; }

.errorMessage_btn__1MyPF {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .errorMessage_btn__1MyPF:hover, .errorMessage_btn__1MyPF:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .errorMessage_btn__1MyPF:focus {
    outline: none !important; }
  .errorMessage_btn__1MyPF::-moz-focus-inner {
    border: 0 !important; }
  .errorMessage_btn__1MyPF.errorMessage_small__3DJ_t {
    font-size: 12px;
    padding: 6px 6px; }
  .errorMessage_btn__1MyPF.errorMessage_disabled__OhQuI {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.errorMessage_navMenuItem__322px {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.errorMessage_navMenuItem__322px::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.errorMessage_navMenuItem__322px.errorMessage_active__10FaV {
  color: #FFC857; }

.errorMessage_navMenuItem__322px:hover::after,
.errorMessage_navMenuItem__322px:focus::after,
.errorMessage_navMenuItem__322px.errorMessage_active__10FaV::after {
  width: 100%; }

.errorMessage_navMenuItem__322px.errorMessage_active__10FaV::after {
  background: #FFC857;
  width: 100%; }

.errorMessage_cardContainer__39kZT {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .errorMessage_cardContainer__39kZT .errorMessage_hoveredInfo__1e6mJ {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .errorMessage_cardContainer__39kZT .errorMessage_hoveredInfo__1e6mJ .errorMessage_footer__1j4VH {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .errorMessage_cardContainer__39kZT .errorMessage_backColor__1Axop {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .errorMessage_cardContainer__39kZT .errorMessage_backImage__1cnIy {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .errorMessage_cardContainer__39kZT .errorMessage_title__2doyi {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .errorMessage_cardContainer__39kZT .errorMessage_secondaryInfoContainer__17kqO {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .errorMessage_cardContainer__39kZT .errorMessage_mediasSummaryContainer__1tUEf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .errorMessage_cardContainer__39kZT .errorMessage_mediasSummaryContainer__1tUEf .errorMessage_badge__nBflb {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .errorMessage_cardContainer__39kZT .errorMessage_mediasSummaryContainer__1tUEf .errorMessage_badge__nBflb .errorMessage_number__272ww {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .errorMessage_cardContainer__39kZT .errorMessage_mediasSummaryContainer__1tUEf .errorMessage_badge__nBflb .errorMessage_emoji__3Fp8J {
        margin-left: 3px; }
  .errorMessage_cardContainer__39kZT img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .errorMessage_cardContainer__39kZT:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .errorMessage_cardContainer__39kZT:hover .errorMessage_hoveredInfo__1e6mJ {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .errorMessage_cardContainer__39kZT:hover .errorMessage_noBlur__24kFF {
      -webkit-filter: none !important;
              filter: none !important; }
    .errorMessage_cardContainer__39kZT:hover .errorMessage_backImage__1cnIy {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.errorMessage_slick-active__3MGA_ div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .errorMessage_inputContainer__B2jik .errorMessage_input__2t-2u {
    width: auto; }
  .errorMessage_cardContainer__39kZT .errorMessage_mediasSummaryContainer__1tUEf {
    display: none; } }

.errorMessage_errorContainer__HDqfg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ff9933;
  font-size: 20px;
  min-height: 300px;
  font-weight: normal; }
  .errorMessage_errorContainer__HDqfg .errorMessage_icon__1dLAB {
    font-size: 50px;
    padding: 20px; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.admin_Form__3nwM2 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.admin_Form_label__1gJZn {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.admin_Form_labelContent__2vkAs {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.admin_Form_error__100F- {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.admin_Form_inputSubmit__37iE- {
  width: auto !important; }

.admin_InputText__1Yl37 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .admin_InputText__1Yl37:focus {
    outline: none; }

.admin_inputContainer__28p7j {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .admin_inputContainer__28p7j.admin_small__2kY0R {
    margin: 10px;
    padding: 0 15px; }
    .admin_inputContainer__28p7j.admin_small__2kY0R .admin_input__3gPs0 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .admin_inputContainer__28p7j.admin_smallest__2Yfnu {
    width: 200px;
    border-color: transparent; }
    .admin_inputContainer__28p7j.admin_smallest__2Yfnu .admin_input__3gPs0 {
      font-size: 15px; }
    .admin_inputContainer__28p7j.admin_smallest__2Yfnu .admin_icon__2CO-M {
      font-size: 30px;
      margin-right: 10px; }
    .admin_inputContainer__28p7j.admin_smallest__2Yfnu.admin_focus__Zly27 {
      border-color: transparent; }
  .admin_inputContainer__28p7j .admin_input__3gPs0 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .admin_inputContainer__28p7j .admin_icon__2CO-M {
    color: #BDD9BF;
    margin-right: 20px; }
  .admin_inputContainer__28p7j.admin_focus__Zly27 {
    border: 1px solid #FFC857; }
    .admin_inputContainer__28p7j.admin_focus__Zly27 .admin_icon__2CO-M {
      color: #FFC857; }
    .admin_inputContainer__28p7j.admin_focus__Zly27 .admin_input__3gPs0 {
      color: #FFC857; }

.admin_inputWithSuggestionsContainer__CL-LG {
  display: flex;
  flex-direction: column; }
  .admin_inputWithSuggestionsContainer__CL-LG .admin_alternativeSearch__3TbU4 {
    color: #BDD9BF;
    font-size: 12px; }
    .admin_inputWithSuggestionsContainer__CL-LG .admin_alternativeSearch__3TbU4 span {
      margin-left: 5px; }
  .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me {
    width: 300px; }
    .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me .admin_loadingContainer__33oWy {
      margin-top: 20px; }
    .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me .admin_suggestionItem__3Crp_ {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me .admin_suggestionItem__3Crp_.admin_active__7jRtG {
        color: #FFC857; }
      .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me .admin_suggestionItem__3Crp_:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .admin_inputWithSuggestionsContainer__CL-LG .admin_suggestionsContainer__O2-me .admin_noMatch__2uPVM {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .admin_inputWithSuggestionsContainer__CL-LG .admin_inputContainer__28p7j {
    margin: 0;
    padding: 0; }

.admin_hidden__2_1TI {
  display: none !important; }

.admin_btn__3FtJ4 {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .admin_btn__3FtJ4:hover, .admin_btn__3FtJ4:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .admin_btn__3FtJ4:focus {
    outline: none !important; }
  .admin_btn__3FtJ4::-moz-focus-inner {
    border: 0 !important; }
  .admin_btn__3FtJ4.admin_small__2kY0R {
    font-size: 12px;
    padding: 6px 6px; }
  .admin_btn__3FtJ4.admin_disabled__2MD00 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.admin_navMenuItem__1CVSM {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.admin_navMenuItem__1CVSM::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.admin_navMenuItem__1CVSM.admin_active__7jRtG {
  color: #FFC857; }

.admin_navMenuItem__1CVSM:hover::after,
.admin_navMenuItem__1CVSM:focus::after,
.admin_navMenuItem__1CVSM.admin_active__7jRtG::after {
  width: 100%; }

.admin_navMenuItem__1CVSM.admin_active__7jRtG::after {
  background: #FFC857;
  width: 100%; }

.admin_cardContainer__3wU86 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .admin_cardContainer__3wU86 .admin_hoveredInfo__RYkSg {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .admin_cardContainer__3wU86 .admin_hoveredInfo__RYkSg .admin_footer__3njRO {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .admin_cardContainer__3wU86 .admin_backColor__2_cTw {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .admin_cardContainer__3wU86 .admin_backImage__-WNwl {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .admin_cardContainer__3wU86 .admin_title__JC9X6 {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .admin_cardContainer__3wU86 .admin_secondaryInfoContainer__1HbaE {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .admin_cardContainer__3wU86 .admin_mediasSummaryContainer__3Q-fc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .admin_cardContainer__3wU86 .admin_mediasSummaryContainer__3Q-fc .admin_badge__2mcYe {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .admin_cardContainer__3wU86 .admin_mediasSummaryContainer__3Q-fc .admin_badge__2mcYe .admin_number__1lHju {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .admin_cardContainer__3wU86 .admin_mediasSummaryContainer__3Q-fc .admin_badge__2mcYe .admin_emoji__1_VHe {
        margin-left: 3px; }
  .admin_cardContainer__3wU86 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .admin_cardContainer__3wU86:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .admin_cardContainer__3wU86:hover .admin_hoveredInfo__RYkSg {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .admin_cardContainer__3wU86:hover .admin_noBlur__3Sme4 {
      -webkit-filter: none !important;
              filter: none !important; }
    .admin_cardContainer__3wU86:hover .admin_backImage__-WNwl {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.admin_slick-active__IBdLF div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .admin_inputContainer__28p7j .admin_input__3gPs0 {
    width: auto; }
  .admin_cardContainer__3wU86 .admin_mediasSummaryContainer__3Q-fc {
    display: none; } }

.admin_sectionGridContainer__29wRz {
  width: 100%;
  box-sizing: border-box;
  max-width: 1500px; }
  .admin_sectionGridContainer__29wRz .admin_sectionTitle__15jBQ {
    color: #FFC857;
    font-size: 24px;
    font-weight: normal;
    margin: 10px; }
  .admin_sectionGridContainer__29wRz .admin_sectionGrid__A8p-I {
    display: flex;
    flex-direction: row;
    flex: 1 1; }
    .admin_sectionGridContainer__29wRz .admin_sectionGrid__A8p-I .admin_cardContainer__3wU86 {
      width: 150px;
      height: 150px; }
      .admin_sectionGridContainer__29wRz .admin_sectionGrid__A8p-I .admin_cardContainer__3wU86 .admin_hoveredInfo__RYkSg {
        justify-content: space-evenly; }
        .admin_sectionGridContainer__29wRz .admin_sectionGrid__A8p-I .admin_cardContainer__3wU86 .admin_hoveredInfo__RYkSg .admin_title__JC9X6 {
          margin: 0; }

.admin_adminPageContainer__18_vv {
  width: 100%; }

@media screen and (max-width: 700px) {
  .admin_Form__3nwM2 {
    padding: 0;
    border: none; }
    .admin_Form__3nwM2 .admin_btn__3FtJ4 {
      width: 200px; }
    .admin_Form__3nwM2 label {
      width: 200px; }
      .admin_Form__3nwM2 label input {
        width: 200px; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.mediaSection_Form__38Gxt {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.mediaSection_Form_label__We3Ig {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.mediaSection_Form_labelContent__ucl8a {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.mediaSection_Form_error__LWuIy {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.mediaSection_Form_inputSubmit__1mptC {
  width: auto !important; }

.mediaSection_InputText__3FV6s {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .mediaSection_InputText__3FV6s:focus {
    outline: none; }

.mediaSection_inputContainer__1xocg {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .mediaSection_inputContainer__1xocg.mediaSection_small__22uX8 {
    margin: 10px;
    padding: 0 15px; }
    .mediaSection_inputContainer__1xocg.mediaSection_small__22uX8 .mediaSection_input__3P2-f {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .mediaSection_inputContainer__1xocg.mediaSection_smallest__3ryWa {
    width: 200px;
    border-color: transparent; }
    .mediaSection_inputContainer__1xocg.mediaSection_smallest__3ryWa .mediaSection_input__3P2-f {
      font-size: 15px; }
    .mediaSection_inputContainer__1xocg.mediaSection_smallest__3ryWa .mediaSection_icon__3_ABb {
      font-size: 30px;
      margin-right: 10px; }
    .mediaSection_inputContainer__1xocg.mediaSection_smallest__3ryWa.mediaSection_focus__3CybG {
      border-color: transparent; }
  .mediaSection_inputContainer__1xocg .mediaSection_input__3P2-f {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .mediaSection_inputContainer__1xocg .mediaSection_icon__3_ABb {
    color: #BDD9BF;
    margin-right: 20px; }
  .mediaSection_inputContainer__1xocg.mediaSection_focus__3CybG {
    border: 1px solid #FFC857; }
    .mediaSection_inputContainer__1xocg.mediaSection_focus__3CybG .mediaSection_icon__3_ABb {
      color: #FFC857; }
    .mediaSection_inputContainer__1xocg.mediaSection_focus__3CybG .mediaSection_input__3P2-f {
      color: #FFC857; }

.mediaSection_inputWithSuggestionsContainer__8RRBc {
  display: flex;
  flex-direction: column; }
  .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_alternativeSearch__3bfS5 {
    color: #BDD9BF;
    font-size: 12px; }
    .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_alternativeSearch__3bfS5 span {
      margin-left: 5px; }
  .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S {
    width: 300px; }
    .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S .mediaSection_loadingContainer__3UPwv {
      margin-top: 20px; }
    .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S .mediaSection_suggestionItem__Ymp9w {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S .mediaSection_suggestionItem__Ymp9w.mediaSection_active__1ekbV {
        color: #FFC857; }
      .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S .mediaSection_suggestionItem__Ymp9w:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_suggestionsContainer__3Fi7S .mediaSection_noMatch__3QQ2r {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .mediaSection_inputWithSuggestionsContainer__8RRBc .mediaSection_inputContainer__1xocg {
    margin: 0;
    padding: 0; }

.mediaSection_hidden__37LR4 {
  display: none !important; }

.mediaSection_btn__3LTmS {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .mediaSection_btn__3LTmS:hover, .mediaSection_btn__3LTmS:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .mediaSection_btn__3LTmS:focus {
    outline: none !important; }
  .mediaSection_btn__3LTmS::-moz-focus-inner {
    border: 0 !important; }
  .mediaSection_btn__3LTmS.mediaSection_small__22uX8 {
    font-size: 12px;
    padding: 6px 6px; }
  .mediaSection_btn__3LTmS.mediaSection_disabled__y9bU0 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.mediaSection_navMenuItem__3UMlB {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.mediaSection_navMenuItem__3UMlB::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.mediaSection_navMenuItem__3UMlB.mediaSection_active__1ekbV {
  color: #FFC857; }

.mediaSection_navMenuItem__3UMlB:hover::after,
.mediaSection_navMenuItem__3UMlB:focus::after,
.mediaSection_navMenuItem__3UMlB.mediaSection_active__1ekbV::after {
  width: 100%; }

.mediaSection_navMenuItem__3UMlB.mediaSection_active__1ekbV::after {
  background: #FFC857;
  width: 100%; }

.mediaSection_cardContainer__mpr5w {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .mediaSection_cardContainer__mpr5w .mediaSection_hoveredInfo__I0fFy {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .mediaSection_cardContainer__mpr5w .mediaSection_hoveredInfo__I0fFy .mediaSection_footer__f7Ivq {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .mediaSection_cardContainer__mpr5w .mediaSection_backColor__ie8-_ {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .mediaSection_cardContainer__mpr5w .mediaSection_backImage__3VEdg {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .mediaSection_cardContainer__mpr5w .mediaSection_title__3hPAC {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .mediaSection_cardContainer__mpr5w .mediaSection_secondaryInfoContainer__1iVy- {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .mediaSection_cardContainer__mpr5w .mediaSection_mediasSummaryContainer__12tl6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .mediaSection_cardContainer__mpr5w .mediaSection_mediasSummaryContainer__12tl6 .mediaSection_badge__1-B8n {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .mediaSection_cardContainer__mpr5w .mediaSection_mediasSummaryContainer__12tl6 .mediaSection_badge__1-B8n .mediaSection_number__3MBrT {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .mediaSection_cardContainer__mpr5w .mediaSection_mediasSummaryContainer__12tl6 .mediaSection_badge__1-B8n .mediaSection_emoji__11Oaw {
        margin-left: 3px; }
  .mediaSection_cardContainer__mpr5w img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .mediaSection_cardContainer__mpr5w:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .mediaSection_cardContainer__mpr5w:hover .mediaSection_hoveredInfo__I0fFy {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .mediaSection_cardContainer__mpr5w:hover .mediaSection_noBlur__VTQww {
      -webkit-filter: none !important;
              filter: none !important; }
    .mediaSection_cardContainer__mpr5w:hover .mediaSection_backImage__3VEdg {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.mediaSection_slick-active__ht2GP div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .mediaSection_inputContainer__1xocg .mediaSection_input__3P2-f {
    width: auto; }
  .mediaSection_cardContainer__mpr5w .mediaSection_mediasSummaryContainer__12tl6 {
    display: none; } }

.mediaSection_mediaRowContainer__2FvYV {
  width: 100%; }
  .mediaSection_mediaRowContainer__2FvYV .mediaSection_mediaRowContainer__2FvYV {
    padding-top: 20px;
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease-in-out, max-height 0.3s ease-in-out;
    overflow: hidden; }
    .mediaSection_mediaRowContainer__2FvYV .mediaSection_mediaRowContainer__2FvYV .mediaSection_activeEpisodeContainer__2tO92 {
      opacity: 0;
      transition: all 0.2s ease-in-out 0.2s; }
    .mediaSection_mediaRowContainer__2FvYV .mediaSection_mediaRowContainer__2FvYV.mediaSection_mediaRowContainerActive__3M_ix {
      background-color: #08090A; }
      .mediaSection_mediaRowContainer__2FvYV .mediaSection_mediaRowContainer__2FvYV.mediaSection_mediaRowContainerActive__3M_ix .mediaSection_activeEpisodeContainer__2tO92 {
        opacity: 1; }
  .mediaSection_mediaRowContainer__2FvYV .mediaSection_mediaRowWrapper__1LIwf {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    width: 100%; }
  .mediaSection_mediaRowContainer__2FvYV .mediaSection_title__3hPAC {
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 1px;
    color: #BDD9BF;
    margin: 0 10px 5px; }
    .mediaSection_mediaRowContainer__2FvYV .mediaSection_title__3hPAC .mediaSection_emoji__11Oaw {
      height: 30px;
      width: 30px;
      padding: 2px;
      font-size: 18px;
      margin-right: 10px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #FFC857; }

.mediaSection_gameContentWrapper__3lbwN {
  position: relative;
  z-index: 2;
  background-color: #2E4052; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.carousel_Form__10ix_ {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.carousel_Form_label__1CjrV {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.carousel_Form_labelContent__1RmRd {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.carousel_Form_error__5h6aZ {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.carousel_Form_inputSubmit__2a1iN {
  width: auto !important; }

.carousel_InputText__KJ6fB {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .carousel_InputText__KJ6fB:focus {
    outline: none; }

.carousel_inputContainer__1YTso {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .carousel_inputContainer__1YTso.carousel_small__TwFur {
    margin: 10px;
    padding: 0 15px; }
    .carousel_inputContainer__1YTso.carousel_small__TwFur .carousel_input__tywt0 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .carousel_inputContainer__1YTso.carousel_smallest__3fuTG {
    width: 200px;
    border-color: transparent; }
    .carousel_inputContainer__1YTso.carousel_smallest__3fuTG .carousel_input__tywt0 {
      font-size: 15px; }
    .carousel_inputContainer__1YTso.carousel_smallest__3fuTG .carousel_icon__3HYcF {
      font-size: 30px;
      margin-right: 10px; }
    .carousel_inputContainer__1YTso.carousel_smallest__3fuTG.carousel_focus__2PTWp {
      border-color: transparent; }
  .carousel_inputContainer__1YTso .carousel_input__tywt0 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .carousel_inputContainer__1YTso .carousel_icon__3HYcF {
    color: #BDD9BF;
    margin-right: 20px; }
  .carousel_inputContainer__1YTso.carousel_focus__2PTWp {
    border: 1px solid #FFC857; }
    .carousel_inputContainer__1YTso.carousel_focus__2PTWp .carousel_icon__3HYcF {
      color: #FFC857; }
    .carousel_inputContainer__1YTso.carousel_focus__2PTWp .carousel_input__tywt0 {
      color: #FFC857; }

.carousel_inputWithSuggestionsContainer__2cav1 {
  display: flex;
  flex-direction: column; }
  .carousel_inputWithSuggestionsContainer__2cav1 .carousel_alternativeSearch__3UpQI {
    color: #BDD9BF;
    font-size: 12px; }
    .carousel_inputWithSuggestionsContainer__2cav1 .carousel_alternativeSearch__3UpQI span {
      margin-left: 5px; }
  .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ {
    width: 300px; }
    .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ .carousel_loadingContainer__g1NHb {
      margin-top: 20px; }
    .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ .carousel_suggestionItem__28ON6 {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ .carousel_suggestionItem__28ON6.carousel_active__xCeEo {
        color: #FFC857; }
      .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ .carousel_suggestionItem__28ON6:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .carousel_inputWithSuggestionsContainer__2cav1 .carousel_suggestionsContainer__2RcjJ .carousel_noMatch__AvzBB {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .carousel_inputWithSuggestionsContainer__2cav1 .carousel_inputContainer__1YTso {
    margin: 0;
    padding: 0; }

.carousel_hidden__10ZmL {
  display: none !important; }

.carousel_btn__hUkCJ {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .carousel_btn__hUkCJ:hover, .carousel_btn__hUkCJ:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .carousel_btn__hUkCJ:focus {
    outline: none !important; }
  .carousel_btn__hUkCJ::-moz-focus-inner {
    border: 0 !important; }
  .carousel_btn__hUkCJ.carousel_small__TwFur {
    font-size: 12px;
    padding: 6px 6px; }
  .carousel_btn__hUkCJ.carousel_disabled__17so3 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.carousel_navMenuItem__11KzM {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.carousel_navMenuItem__11KzM::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.carousel_navMenuItem__11KzM.carousel_active__xCeEo {
  color: #FFC857; }

.carousel_navMenuItem__11KzM:hover::after,
.carousel_navMenuItem__11KzM:focus::after,
.carousel_navMenuItem__11KzM.carousel_active__xCeEo::after {
  width: 100%; }

.carousel_navMenuItem__11KzM.carousel_active__xCeEo::after {
  background: #FFC857;
  width: 100%; }

.carousel_cardContainer__MCAHT {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .carousel_cardContainer__MCAHT .carousel_hoveredInfo__2EcWc {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .carousel_cardContainer__MCAHT .carousel_hoveredInfo__2EcWc .carousel_footer__1xd6Z {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .carousel_cardContainer__MCAHT .carousel_backColor__20lM5 {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .carousel_cardContainer__MCAHT .carousel_backImage__3Tvob {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .carousel_cardContainer__MCAHT .carousel_title__2J_Ju {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .carousel_cardContainer__MCAHT .carousel_secondaryInfoContainer__2iOQh {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .carousel_cardContainer__MCAHT .carousel_mediasSummaryContainer__2K6_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .carousel_cardContainer__MCAHT .carousel_mediasSummaryContainer__2K6_2 .carousel_badge__-MNdd {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .carousel_cardContainer__MCAHT .carousel_mediasSummaryContainer__2K6_2 .carousel_badge__-MNdd .carousel_number__2gnzV {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .carousel_cardContainer__MCAHT .carousel_mediasSummaryContainer__2K6_2 .carousel_badge__-MNdd .carousel_emoji__3XVzD {
        margin-left: 3px; }
  .carousel_cardContainer__MCAHT img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .carousel_cardContainer__MCAHT:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .carousel_cardContainer__MCAHT:hover .carousel_hoveredInfo__2EcWc {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .carousel_cardContainer__MCAHT:hover .carousel_noBlur__2E3sg {
      -webkit-filter: none !important;
              filter: none !important; }
    .carousel_cardContainer__MCAHT:hover .carousel_backImage__3Tvob {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.carousel_slick-active__kNU3K div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .carousel_inputContainer__1YTso .carousel_input__tywt0 {
    width: auto; }
  .carousel_cardContainer__MCAHT .carousel_mediasSummaryContainer__2K6_2 {
    display: none; } }

.carousel_carouselContainer__2MjPH {
  width: calc(100% - 76px);
  max-width: 1600px;
  align-self: center; }
  .carousel_carouselContainer__2MjPH .carousel_slideContainer__2dTlO {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px; }
    .carousel_carouselContainer__2MjPH .carousel_slideContainer__2dTlO:focus {
      outline: none !important; }
  .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT {
    width: 200px;
    height: 260px;
    opacity: 1; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo {
      -webkit-transform: scale(1);
              transform: scale(1); }
      .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo .carousel_backImage__3Tvob {
        opacity: 1;
        box-shadow: inset 0 0 0 2px #FFC857;
        transition: opacity 0.2s ease-in-out; }
      .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo .carousel_title__2J_Ju {
        color: #FFC857;
        opacity: 0;
        transition: all 0.2s ease-in-out; }
      .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo .carousel_gradient__LYl-z {
        background: linear-gradient(rgba(46, 64, 82, 0) 0%, rgba(46, 64, 82, 0) 2px, #08090A 2px, rgba(46, 64, 82, 0) 35%);
        width: calc(100% - 4px);
        left: 2px; }
        .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo .carousel_gradient__LYl-z:after {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #FFC857;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin: 0 auto; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_gradient__LYl-z {
      height: 200px;
      width: 100%;
      background: linear-gradient(#08090A 0%, #08090A 5%, rgba(46, 64, 82, 0) 45%);
      position: absolute;
      left: 0;
      top: 0; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_cardHeader__28hVV {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 1;
      padding: 5px;
      cursor: pointer; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_mediaName__GWoht {
      color: #BDD9BF;
      margin-left: 8px;
      font-size: 15px; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_badge__-MNdd {
      height: 35px;
      width: 35px;
      cursor: pointer;
      transition: all 0.1s ease-in-out; }
      .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_badge__-MNdd:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }
      .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_badge__-MNdd img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 2px 2px 0 0 #FFC857; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_ribbon__2e9T8 {
      content: "";
      border-top: 40px solid #FFC857;
      border-right: 40px solid transparent;
      position: absolute;
      opacity: 1;
      z-index: 2;
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
              filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5)); }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_backImage__3Tvob {
      opacity: 0.6;
      height: 200px;
      transition: opacity 0.1s ease-in-out; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_hoveredInfo__2EcWc {
      opacity: 1; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_notVerifiedIcon__2UgOD {
      position: absolute;
      top: 0;
      left: 0; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT .carousel_title__2J_Ju {
      position: absolute;
      bottom: 0;
      font-weight: normal;
      font-size: 15px;
      color: #BDD9BF;
      margin: 0;
      height: 50px;
      width: 100%;
      text-align: center;
      transition: all 0.1s ease-in-out; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT.carousel_active__xCeEo {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT:hover .carousel_title__2J_Ju {
      color: #FFC857; }
    .carousel_carouselContainer__2MjPH .carousel_cardContainer__MCAHT:hover .carousel_backImage__3Tvob {
      opacity: 0.9;
      transition: opacty 0.2s ease-in-out; }

.carousel_arrowContainer__1K14w {
  height: 100% !important;
  cursor: pointer;
  display: flex !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-transform: translate(0, -60%);
          transform: translate(0, -60%);
  color: #BDD9BF !important;
  transition: all 0.1s ease-in-out; }
  .carousel_arrowContainer__1K14w:hover {
    color: #FFC857 !important;
    transition: all 0.2s ease-in-out; }
  .carousel_arrowContainer__1K14w:before {
    content: "" !important; }
  .carousel_arrowContainer__1K14w .carousel_icon__3HYcF {
    width: 25px;
    height: 25px;
    margin-bottom: 40px; }
  .carousel_arrowContainer__1K14w.carousel_slickDisabled__NQuVW {
    display: none; }

.carousel_dotsContainer__182Bf {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -20px;
  right: 8px;
  list-style-type: none;
  opacity: 1;
  transition: all 0.1s ease-in-out; }
  .carousel_dotsContainer__182Bf.carousel_hidden__10ZmL {
    opacity: 0;
    transition: all 0.1s ease-in-out; }

.carousel_customPagingContainer__1_i6k {
  opacity: 0.4;
  height: 8px;
  width: 5px;
  margin: 1px;
  background-color: #BDD9BF; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.activeMediaBox_Form__2KzD6 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.activeMediaBox_Form_label__2SfMO {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.activeMediaBox_Form_labelContent__3Zkvo {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.activeMediaBox_Form_error__hsl8a {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.activeMediaBox_Form_inputSubmit__1ZSSj {
  width: auto !important; }

.activeMediaBox_InputText__3mE5Z {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .activeMediaBox_InputText__3mE5Z:focus {
    outline: none; }

.activeMediaBox_inputContainer__1bBmG {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .activeMediaBox_inputContainer__1bBmG.activeMediaBox_small__2a2R1 {
    margin: 10px;
    padding: 0 15px; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_small__2a2R1 .activeMediaBox_input__2C91W {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .activeMediaBox_inputContainer__1bBmG.activeMediaBox_smallest__M2XPQ {
    width: 200px;
    border-color: transparent; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_smallest__M2XPQ .activeMediaBox_input__2C91W {
      font-size: 15px; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_smallest__M2XPQ .activeMediaBox_icon__WqiQT {
      font-size: 30px;
      margin-right: 10px; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_smallest__M2XPQ.activeMediaBox_focus__2U15U {
      border-color: transparent; }
  .activeMediaBox_inputContainer__1bBmG .activeMediaBox_input__2C91W {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .activeMediaBox_inputContainer__1bBmG .activeMediaBox_icon__WqiQT {
    color: #BDD9BF;
    margin-right: 20px; }
  .activeMediaBox_inputContainer__1bBmG.activeMediaBox_focus__2U15U {
    border: 1px solid #FFC857; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_focus__2U15U .activeMediaBox_icon__WqiQT {
      color: #FFC857; }
    .activeMediaBox_inputContainer__1bBmG.activeMediaBox_focus__2U15U .activeMediaBox_input__2C91W {
      color: #FFC857; }

.activeMediaBox_inputWithSuggestionsContainer__2WjYB {
  display: flex;
  flex-direction: column; }
  .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_alternativeSearch__zMu6x {
    color: #BDD9BF;
    font-size: 12px; }
    .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_alternativeSearch__zMu6x span {
      margin-left: 5px; }
  .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol {
    width: 300px; }
    .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol .activeMediaBox_loadingContainer__I1uvH {
      margin-top: 20px; }
    .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol .activeMediaBox_suggestionItem__1Wuau {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol .activeMediaBox_suggestionItem__1Wuau.activeMediaBox_active__2X8zs {
        color: #FFC857; }
      .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol .activeMediaBox_suggestionItem__1Wuau:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_suggestionsContainer__IL1ol .activeMediaBox_noMatch__1RLvL {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .activeMediaBox_inputWithSuggestionsContainer__2WjYB .activeMediaBox_inputContainer__1bBmG {
    margin: 0;
    padding: 0; }

.activeMediaBox_hidden__2fUXk {
  display: none !important; }

.activeMediaBox_btn__hisqo {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .activeMediaBox_btn__hisqo:hover, .activeMediaBox_btn__hisqo:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .activeMediaBox_btn__hisqo:focus {
    outline: none !important; }
  .activeMediaBox_btn__hisqo::-moz-focus-inner {
    border: 0 !important; }
  .activeMediaBox_btn__hisqo.activeMediaBox_small__2a2R1 {
    font-size: 12px;
    padding: 6px 6px; }
  .activeMediaBox_btn__hisqo.activeMediaBox_disabled__2u5b3 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.activeMediaBox_navMenuItem__xj12q {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.activeMediaBox_navMenuItem__xj12q::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.activeMediaBox_navMenuItem__xj12q.activeMediaBox_active__2X8zs {
  color: #FFC857; }

.activeMediaBox_navMenuItem__xj12q:hover::after,
.activeMediaBox_navMenuItem__xj12q:focus::after,
.activeMediaBox_navMenuItem__xj12q.activeMediaBox_active__2X8zs::after {
  width: 100%; }

.activeMediaBox_navMenuItem__xj12q.activeMediaBox_active__2X8zs::after {
  background: #FFC857;
  width: 100%; }

.activeMediaBox_cardContainer__1MW7j {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_hoveredInfo__1opU5 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .activeMediaBox_cardContainer__1MW7j .activeMediaBox_hoveredInfo__1opU5 .activeMediaBox_footer__1ayWb {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_backColor__296s- {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_backImage__1IIej {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_title__3cW3V {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_secondaryInfoContainer__2A-yi {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_mediasSummaryContainer__3UDfX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .activeMediaBox_cardContainer__1MW7j .activeMediaBox_mediasSummaryContainer__3UDfX .activeMediaBox_badge__17INr {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .activeMediaBox_cardContainer__1MW7j .activeMediaBox_mediasSummaryContainer__3UDfX .activeMediaBox_badge__17INr .activeMediaBox_number__3a-QJ {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .activeMediaBox_cardContainer__1MW7j .activeMediaBox_mediasSummaryContainer__3UDfX .activeMediaBox_badge__17INr .activeMediaBox_emoji__3ByYW {
        margin-left: 3px; }
  .activeMediaBox_cardContainer__1MW7j img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .activeMediaBox_cardContainer__1MW7j:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .activeMediaBox_cardContainer__1MW7j:hover .activeMediaBox_hoveredInfo__1opU5 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .activeMediaBox_cardContainer__1MW7j:hover .activeMediaBox_noBlur__mtw_q {
      -webkit-filter: none !important;
              filter: none !important; }
    .activeMediaBox_cardContainer__1MW7j:hover .activeMediaBox_backImage__1IIej {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.activeMediaBox_slick-active__kNNco div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .activeMediaBox_inputContainer__1bBmG .activeMediaBox_input__2C91W {
    width: auto; }
  .activeMediaBox_cardContainer__1MW7j .activeMediaBox_mediasSummaryContainer__3UDfX {
    display: none; } }

.activeMediaBox_activeMediaBoxContainer__3fXqt {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 20px 30px;
  position: relative; }
  .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_warning__3OAio {
    color: #FFC857;
    font-weight: normal;
    font-size: 15px; }
  .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD {
    width: 100%;
    margin: 0;
    padding: 0 20px 30px 20px;
    box-sizing: border-box; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_titleContainer__1n9Fj {
      margin-bottom: 0; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_bodyContainer__22n1l {
      flex-direction: column-reverse; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb {
        margin-left: 0; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_rightRowContainer__luThw {
          min-height: 0;
          width: 100%; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_noGame__3DXdK {
          display: none; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt.activeMediaBox_video__107yD .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR {
        margin-top: 20px; }
  .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_closeBtn__m5Qje {
    color: #BDD9BF;
    font-size: 25px;
    position: absolute;
    padding: 10px;
    right: 0;
    top: 0;
    cursor: pointer; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_closeBtn__m5Qje:hover {
      color: #FFC857; }
  .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    max-width: 1000px;
    width: calc(100%); }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_title__3cW3V {
      font-size: 24px;
      letter-spacing: 2px;
      color: #FFC857;
      margin-bottom: 5px;
      font-weight: normal;
      width: calc(100% - 30px); }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P {
      color: #FFC857;
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P .activeMediaBox_authorAndDateContent__BM2XZ {
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: all 0.1s ease-in-out; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P .activeMediaBox_authorAndDateContent__BM2XZ:hover {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05); }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P .activeMediaBox_author__1P7lB {
        font-weight: normal;
        font-size: 15px; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P .activeMediaBox_date__1rtXG {
        font-weight: 300;
        font-size: 12px; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_authorAndDateContainer__4Vq0P img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
        box-shadow: 2px 2px 0 0 #FFC857; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_subscribeBtn__1c4Il {
      display: none; }
  .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l {
    display: flex;
    flex-direction: row; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR, .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR {
      padding-right: 30px;
      flex: 0.5 1;
      max-width: 500px;
      position: relative; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR .activeMediaBox_description__1UT9w {
        color: #BDD9BF;
        font-weight: normal;
        font-size: 15px; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR .activeMediaBox_description__1UT9w .activeMediaBox_descriptionContent__2opZR {
          white-space: pre-wrap; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR .activeMediaBox_description__1UT9w .activeMediaBox_link__1qNB9 {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          vertical-align: middle; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR .activeMediaBox_readMoreBtn__IpxkY {
        color: #FFC857;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0 0 10px 0;
        letter-spacing: 1px;
        outline: none;
        background: none;
        border: none;
        margin: 0 5px; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb {
      flex: 0.5 1;
      align-items: flex-start;
      margin-left: 30px; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_rightRowContainer__luThw {
        display: flex;
        flex-direction: column;
        margin-top: 10px; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_saveContainer__1LzGX, .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_verifyContainer__3kRU9 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-self: flex-end;
        color: #BDD9BF; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_saveContainer__1LzGX div, .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_verifyContainer__3kRU9 div {
          cursor: pointer; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_saveContainer__1LzGX .activeMediaBox_icon__WqiQT, .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_verifyContainer__3kRU9 .activeMediaBox_icon__WqiQT {
          font-size: 18px;
          margin-right: 5px;
          margin-top: -2px; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_saveContainer__1LzGX:hover, .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_verifyContainer__3kRU9:hover {
          color: #FFC857; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz {
        min-height: 150px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-self: flex-start; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz .activeMediaBox_cardContainer__1MW7j {
          width: 112.5px;
          height: 150px;
          opacity: 1; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz .activeMediaBox_emptyCard__1OR8_ {
          background-color: #2E4052; }
          .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz .activeMediaBox_emptyCard__1OR8_ .activeMediaBox_backImage__1IIej {
            background-color: #2E4052; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz .activeMediaBox_hoveredInfo__1opU5 {
          justify-content: center; }
          .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_gamesContainer__3Mbjz .activeMediaBox_hoveredInfo__1opU5 .activeMediaBox_title__3cW3V {
            font-size: 12px;
            margin: 0; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_inputWithSuggestionsContainer__2WjYB {
        align-self: flex-start; }

@media screen and (max-width: 1260px) {
  .activeMediaBox_activeMediaBoxContainer__3fXqt {
    margin: 0;
    padding: 0 20px 30px 20px;
    box-sizing: border-box; }
    .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l {
      flex-direction: column-reverse; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb {
        margin-left: 0; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_rightRowContainer__luThw {
          min-height: 0; }
        .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_rightRow__1Ndvb .activeMediaBox_noGame__3DXdK {
          display: none; }
      .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_bodyContainer__22n1l .activeMediaBox_leftRow__1zPPR {
        margin-top: 20px; } }

@media screen and (max-width: 700px) {
  .activeMediaBox_activeMediaBoxContainer__3fXqt .activeMediaBox_titleContainer__1n9Fj .activeMediaBox_subscribeBtn__1c4Il {
    margin-top: 15px;
    display: flex; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.trashWidget_Form__1eUbI {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.trashWidget_Form_label__TOqfN {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.trashWidget_Form_labelContent__3W_uk {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.trashWidget_Form_error__pX_op {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.trashWidget_Form_inputSubmit__1Ft5n {
  width: auto !important; }

.trashWidget_InputText__18EmU {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .trashWidget_InputText__18EmU:focus {
    outline: none; }

.trashWidget_inputContainer__1UnX1 {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .trashWidget_inputContainer__1UnX1.trashWidget_small__3C3KU {
    margin: 10px;
    padding: 0 15px; }
    .trashWidget_inputContainer__1UnX1.trashWidget_small__3C3KU .trashWidget_input__Ody8J {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .trashWidget_inputContainer__1UnX1.trashWidget_smallest__zzhIO {
    width: 200px;
    border-color: transparent; }
    .trashWidget_inputContainer__1UnX1.trashWidget_smallest__zzhIO .trashWidget_input__Ody8J {
      font-size: 15px; }
    .trashWidget_inputContainer__1UnX1.trashWidget_smallest__zzhIO .trashWidget_icon__ff45P {
      font-size: 30px;
      margin-right: 10px; }
    .trashWidget_inputContainer__1UnX1.trashWidget_smallest__zzhIO.trashWidget_focus__1uKUA {
      border-color: transparent; }
  .trashWidget_inputContainer__1UnX1 .trashWidget_input__Ody8J {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .trashWidget_inputContainer__1UnX1 .trashWidget_icon__ff45P {
    color: #BDD9BF;
    margin-right: 20px; }
  .trashWidget_inputContainer__1UnX1.trashWidget_focus__1uKUA {
    border: 1px solid #FFC857; }
    .trashWidget_inputContainer__1UnX1.trashWidget_focus__1uKUA .trashWidget_icon__ff45P {
      color: #FFC857; }
    .trashWidget_inputContainer__1UnX1.trashWidget_focus__1uKUA .trashWidget_input__Ody8J {
      color: #FFC857; }

.trashWidget_inputWithSuggestionsContainer__1jP4A {
  display: flex;
  flex-direction: column; }
  .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_alternativeSearch__fuTBG {
    color: #BDD9BF;
    font-size: 12px; }
    .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_alternativeSearch__fuTBG span {
      margin-left: 5px; }
  .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 {
    width: 300px; }
    .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 .trashWidget_loadingContainer__-UnKM {
      margin-top: 20px; }
    .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 .trashWidget_suggestionItem__kLuTo {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 .trashWidget_suggestionItem__kLuTo.trashWidget_active__45HiI {
        color: #FFC857; }
      .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 .trashWidget_suggestionItem__kLuTo:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_suggestionsContainer__2eco7 .trashWidget_noMatch__1JoLi {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .trashWidget_inputWithSuggestionsContainer__1jP4A .trashWidget_inputContainer__1UnX1 {
    margin: 0;
    padding: 0; }

.trashWidget_hidden__jxabJ {
  display: none !important; }

.trashWidget_btn__JOppZ {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .trashWidget_btn__JOppZ:hover, .trashWidget_btn__JOppZ:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .trashWidget_btn__JOppZ:focus {
    outline: none !important; }
  .trashWidget_btn__JOppZ::-moz-focus-inner {
    border: 0 !important; }
  .trashWidget_btn__JOppZ.trashWidget_small__3C3KU {
    font-size: 12px;
    padding: 6px 6px; }
  .trashWidget_btn__JOppZ.trashWidget_disabled__T493U {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.trashWidget_navMenuItem__3_Q6T {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.trashWidget_navMenuItem__3_Q6T::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.trashWidget_navMenuItem__3_Q6T.trashWidget_active__45HiI {
  color: #FFC857; }

.trashWidget_navMenuItem__3_Q6T:hover::after,
.trashWidget_navMenuItem__3_Q6T:focus::after,
.trashWidget_navMenuItem__3_Q6T.trashWidget_active__45HiI::after {
  width: 100%; }

.trashWidget_navMenuItem__3_Q6T.trashWidget_active__45HiI::after {
  background: #FFC857;
  width: 100%; }

.trashWidget_cardContainer__3nsz6 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_hoveredInfo__36RSw {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .trashWidget_cardContainer__3nsz6 .trashWidget_hoveredInfo__36RSw .trashWidget_footer__21E45 {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_backColor__1I03l {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_backImage__1gwIB {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_title__rI35C {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_secondaryInfoContainer__o9C0X {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_mediasSummaryContainer__2dQX4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .trashWidget_cardContainer__3nsz6 .trashWidget_mediasSummaryContainer__2dQX4 .trashWidget_badge__1TFXO {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .trashWidget_cardContainer__3nsz6 .trashWidget_mediasSummaryContainer__2dQX4 .trashWidget_badge__1TFXO .trashWidget_number__1FK9T {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .trashWidget_cardContainer__3nsz6 .trashWidget_mediasSummaryContainer__2dQX4 .trashWidget_badge__1TFXO .trashWidget_emoji__2xHMv {
        margin-left: 3px; }
  .trashWidget_cardContainer__3nsz6 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .trashWidget_cardContainer__3nsz6:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .trashWidget_cardContainer__3nsz6:hover .trashWidget_hoveredInfo__36RSw {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .trashWidget_cardContainer__3nsz6:hover .trashWidget_noBlur__1h3mE {
      -webkit-filter: none !important;
              filter: none !important; }
    .trashWidget_cardContainer__3nsz6:hover .trashWidget_backImage__1gwIB {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.trashWidget_slick-active__2N3g5 div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .trashWidget_inputContainer__1UnX1 .trashWidget_input__Ody8J {
    width: auto; }
  .trashWidget_cardContainer__3nsz6 .trashWidget_mediasSummaryContainer__2dQX4 {
    display: none; } }

.trashWidget_trashContainer__1gzAL {
  -webkit-transform: scale(0.15);
          transform: scale(0.15);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  padding: 40px 20px; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 {
    width: 66px;
    height: 80px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 span {
    position: absolute;
    height: 12px;
    top: -19px;
    left: -10px;
    right: -10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    -webkit-transform-origin: 19% 100%;
            transform-origin: 19% 100%; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 .trashWidget_top__2cZ-6 {
    position: absolute;
    width: 27px;
    height: 7px;
    top: -10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    -webkit-transform-origin: 19% 100%;
            transform-origin: 19% 100%;
    left: 27px; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 i {
    position: relative;
    width: 5px;
    height: 50px;
    background: transparent;
    display: block;
    margin: 14px auto;
    border-radius: 5px; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 i:after {
    content: '';
    width: 5px;
    height: 50px;
    background: transparent;
    position: absolute;
    left: -18px;
    border-radius: 5px; }
  .trashWidget_trashContainer__1gzAL .trashWidget_trash__2_ic9 i:before {
    content: '';
    width: 5px;
    height: 50px;
    background: transparent;
    position: absolute;
    right: -18px;
    border-radius: 5px; }
  .trashWidget_trashContainer__1gzAL:hover .trashWidget_trash__2_ic9 span {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.playPodcast_Form__2IzsK {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.playPodcast_Form_label__1X-gn {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.playPodcast_Form_labelContent__l00gG {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.playPodcast_Form_error__1oomV {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.playPodcast_Form_inputSubmit__32Q0D {
  width: auto !important; }

.playPodcast_InputText__Ub2OG {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .playPodcast_InputText__Ub2OG:focus {
    outline: none; }

.playPodcast_inputContainer__2IAC_ {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .playPodcast_inputContainer__2IAC_.playPodcast_small__1Dqp5 {
    margin: 10px;
    padding: 0 15px; }
    .playPodcast_inputContainer__2IAC_.playPodcast_small__1Dqp5 .playPodcast_input__2l28k {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .playPodcast_inputContainer__2IAC_.playPodcast_smallest__QHsS1 {
    width: 200px;
    border-color: transparent; }
    .playPodcast_inputContainer__2IAC_.playPodcast_smallest__QHsS1 .playPodcast_input__2l28k {
      font-size: 15px; }
    .playPodcast_inputContainer__2IAC_.playPodcast_smallest__QHsS1 .playPodcast_icon__rZW7I {
      font-size: 30px;
      margin-right: 10px; }
    .playPodcast_inputContainer__2IAC_.playPodcast_smallest__QHsS1.playPodcast_focus__3krvz {
      border-color: transparent; }
  .playPodcast_inputContainer__2IAC_ .playPodcast_input__2l28k {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .playPodcast_inputContainer__2IAC_ .playPodcast_icon__rZW7I {
    color: #BDD9BF;
    margin-right: 20px; }
  .playPodcast_inputContainer__2IAC_.playPodcast_focus__3krvz {
    border: 1px solid #FFC857; }
    .playPodcast_inputContainer__2IAC_.playPodcast_focus__3krvz .playPodcast_icon__rZW7I {
      color: #FFC857; }
    .playPodcast_inputContainer__2IAC_.playPodcast_focus__3krvz .playPodcast_input__2l28k {
      color: #FFC857; }

.playPodcast_inputWithSuggestionsContainer__185wl {
  display: flex;
  flex-direction: column; }
  .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_alternativeSearch__2wYxw {
    color: #BDD9BF;
    font-size: 12px; }
    .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_alternativeSearch__2wYxw span {
      margin-left: 5px; }
  .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo {
    width: 300px; }
    .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo .playPodcast_loadingContainer__3sk3q {
      margin-top: 20px; }
    .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo .playPodcast_suggestionItem__14eYr {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo .playPodcast_suggestionItem__14eYr.playPodcast_active__3X21N {
        color: #FFC857; }
      .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo .playPodcast_suggestionItem__14eYr:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_suggestionsContainer__3sEjo .playPodcast_noMatch__2qMPl {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .playPodcast_inputWithSuggestionsContainer__185wl .playPodcast_inputContainer__2IAC_ {
    margin: 0;
    padding: 0; }

.playPodcast_hidden__1dC5D {
  display: none !important; }

.playPodcast_btn__3_HDj {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .playPodcast_btn__3_HDj:hover, .playPodcast_btn__3_HDj:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .playPodcast_btn__3_HDj:focus {
    outline: none !important; }
  .playPodcast_btn__3_HDj::-moz-focus-inner {
    border: 0 !important; }
  .playPodcast_btn__3_HDj.playPodcast_small__1Dqp5 {
    font-size: 12px;
    padding: 6px 6px; }
  .playPodcast_btn__3_HDj.playPodcast_disabled__3_da4 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.playPodcast_navMenuItem__2h0W6 {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.playPodcast_navMenuItem__2h0W6::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.playPodcast_navMenuItem__2h0W6.playPodcast_active__3X21N {
  color: #FFC857; }

.playPodcast_navMenuItem__2h0W6:hover::after,
.playPodcast_navMenuItem__2h0W6:focus::after,
.playPodcast_navMenuItem__2h0W6.playPodcast_active__3X21N::after {
  width: 100%; }

.playPodcast_navMenuItem__2h0W6.playPodcast_active__3X21N::after {
  background: #FFC857;
  width: 100%; }

.playPodcast_cardContainer__2cGx1 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_hoveredInfo__2Z4Lx {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .playPodcast_cardContainer__2cGx1 .playPodcast_hoveredInfo__2Z4Lx .playPodcast_footer__3Dpr2 {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_backColor__2Ia4S {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_backImage__2nVnK {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_title__1C1Qy {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_secondaryInfoContainer__lTDnD {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_mediasSummaryContainer__1mV47 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .playPodcast_cardContainer__2cGx1 .playPodcast_mediasSummaryContainer__1mV47 .playPodcast_badge__1SvJS {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .playPodcast_cardContainer__2cGx1 .playPodcast_mediasSummaryContainer__1mV47 .playPodcast_badge__1SvJS .playPodcast_number__2A1dU {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .playPodcast_cardContainer__2cGx1 .playPodcast_mediasSummaryContainer__1mV47 .playPodcast_badge__1SvJS .playPodcast_emoji__3zNwf {
        margin-left: 3px; }
  .playPodcast_cardContainer__2cGx1 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .playPodcast_cardContainer__2cGx1:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .playPodcast_cardContainer__2cGx1:hover .playPodcast_hoveredInfo__2Z4Lx {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .playPodcast_cardContainer__2cGx1:hover .playPodcast_noBlur__FE0nq {
      -webkit-filter: none !important;
              filter: none !important; }
    .playPodcast_cardContainer__2cGx1:hover .playPodcast_backImage__2nVnK {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.playPodcast_slick-active__2eE87 div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .playPodcast_inputContainer__2IAC_ .playPodcast_input__2l28k {
    width: auto; }
  .playPodcast_cardContainer__2cGx1 .playPodcast_mediasSummaryContainer__1mV47 {
    display: none; } }

.playPodcast_playPodcastContainer__1KOkQ {
  width: 250px;
  height: 250px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  position: relative;
  background-color: #08090A;
  overflow: hidden; }
  .playPodcast_playPodcastContainer__1KOkQ:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
    .playPodcast_playPodcastContainer__1KOkQ:hover img {
      opacity: 0.6; }
    .playPodcast_playPodcastContainer__1KOkQ:hover .playPodcast_playIcon__1x7It {
      color: #FFC857; }
  .playPodcast_playPodcastContainer__1KOkQ img {
    height: 100%;
    opacity: 0.8;
    transition: all 0.1s ease-in-out; }
  .playPodcast_playPodcastContainer__1KOkQ .playPodcast_playIcon__1x7It {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #BDD9BF;
    transition: all 0.1s ease-in-out; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.playVideo_Form__S3hDX {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.playVideo_Form_label__2MKl0 {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.playVideo_Form_labelContent__3ZOHE {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.playVideo_Form_error__1VRlk {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.playVideo_Form_inputSubmit__1VzeE {
  width: auto !important; }

.playVideo_InputText__34pTN {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .playVideo_InputText__34pTN:focus {
    outline: none; }

.playVideo_inputContainer__3sQv4 {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .playVideo_inputContainer__3sQv4.playVideo_small__3uPP1 {
    margin: 10px;
    padding: 0 15px; }
    .playVideo_inputContainer__3sQv4.playVideo_small__3uPP1 .playVideo_input__xF9zY {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .playVideo_inputContainer__3sQv4.playVideo_smallest__2Rkaf {
    width: 200px;
    border-color: transparent; }
    .playVideo_inputContainer__3sQv4.playVideo_smallest__2Rkaf .playVideo_input__xF9zY {
      font-size: 15px; }
    .playVideo_inputContainer__3sQv4.playVideo_smallest__2Rkaf .playVideo_icon__2ytOM {
      font-size: 30px;
      margin-right: 10px; }
    .playVideo_inputContainer__3sQv4.playVideo_smallest__2Rkaf.playVideo_focus__3xouQ {
      border-color: transparent; }
  .playVideo_inputContainer__3sQv4 .playVideo_input__xF9zY {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .playVideo_inputContainer__3sQv4 .playVideo_icon__2ytOM {
    color: #BDD9BF;
    margin-right: 20px; }
  .playVideo_inputContainer__3sQv4.playVideo_focus__3xouQ {
    border: 1px solid #FFC857; }
    .playVideo_inputContainer__3sQv4.playVideo_focus__3xouQ .playVideo_icon__2ytOM {
      color: #FFC857; }
    .playVideo_inputContainer__3sQv4.playVideo_focus__3xouQ .playVideo_input__xF9zY {
      color: #FFC857; }

.playVideo_inputWithSuggestionsContainer__3FQ9Y {
  display: flex;
  flex-direction: column; }
  .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_alternativeSearch__1JPRO {
    color: #BDD9BF;
    font-size: 12px; }
    .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_alternativeSearch__1JPRO span {
      margin-left: 5px; }
  .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 {
    width: 300px; }
    .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 .playVideo_loadingContainer__XuhgV {
      margin-top: 20px; }
    .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 .playVideo_suggestionItem__1bQvj {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 .playVideo_suggestionItem__1bQvj.playVideo_active__2_PyR {
        color: #FFC857; }
      .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 .playVideo_suggestionItem__1bQvj:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_suggestionsContainer__3KlW6 .playVideo_noMatch__3awi1 {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .playVideo_inputWithSuggestionsContainer__3FQ9Y .playVideo_inputContainer__3sQv4 {
    margin: 0;
    padding: 0; }

.playVideo_hidden__3RYHk {
  display: none !important; }

.playVideo_btn__3u11m {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .playVideo_btn__3u11m:hover, .playVideo_btn__3u11m:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .playVideo_btn__3u11m:focus {
    outline: none !important; }
  .playVideo_btn__3u11m::-moz-focus-inner {
    border: 0 !important; }
  .playVideo_btn__3u11m.playVideo_small__3uPP1 {
    font-size: 12px;
    padding: 6px 6px; }
  .playVideo_btn__3u11m.playVideo_disabled__3kkhc {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.playVideo_navMenuItem__1dEpv {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.playVideo_navMenuItem__1dEpv::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.playVideo_navMenuItem__1dEpv.playVideo_active__2_PyR {
  color: #FFC857; }

.playVideo_navMenuItem__1dEpv:hover::after,
.playVideo_navMenuItem__1dEpv:focus::after,
.playVideo_navMenuItem__1dEpv.playVideo_active__2_PyR::after {
  width: 100%; }

.playVideo_navMenuItem__1dEpv.playVideo_active__2_PyR::after {
  background: #FFC857;
  width: 100%; }

.playVideo_cardContainer__2F5-4 {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .playVideo_cardContainer__2F5-4 .playVideo_hoveredInfo__2-L0j {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .playVideo_cardContainer__2F5-4 .playVideo_hoveredInfo__2-L0j .playVideo_footer__1pbl1 {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .playVideo_cardContainer__2F5-4 .playVideo_backColor___Cyxj {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .playVideo_cardContainer__2F5-4 .playVideo_backImage__2yNBH {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .playVideo_cardContainer__2F5-4 .playVideo_title__2Ltc5 {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .playVideo_cardContainer__2F5-4 .playVideo_secondaryInfoContainer__1tEsl {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .playVideo_cardContainer__2F5-4 .playVideo_mediasSummaryContainer__2ObYy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .playVideo_cardContainer__2F5-4 .playVideo_mediasSummaryContainer__2ObYy .playVideo_badge__16R1w {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .playVideo_cardContainer__2F5-4 .playVideo_mediasSummaryContainer__2ObYy .playVideo_badge__16R1w .playVideo_number__3tuAI {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .playVideo_cardContainer__2F5-4 .playVideo_mediasSummaryContainer__2ObYy .playVideo_badge__16R1w .playVideo_emoji__DY4bk {
        margin-left: 3px; }
  .playVideo_cardContainer__2F5-4 img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .playVideo_cardContainer__2F5-4:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .playVideo_cardContainer__2F5-4:hover .playVideo_hoveredInfo__2-L0j {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .playVideo_cardContainer__2F5-4:hover .playVideo_noBlur__2GrXQ {
      -webkit-filter: none !important;
              filter: none !important; }
    .playVideo_cardContainer__2F5-4:hover .playVideo_backImage__2yNBH {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.playVideo_slick-active__6JyCx div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .playVideo_inputContainer__3sQv4 .playVideo_input__xF9zY {
    width: auto; }
  .playVideo_cardContainer__2F5-4 .playVideo_mediasSummaryContainer__2ObYy {
    display: none; } }

.playVideo_videoContainer__1PbN3 {
  width: calc(100%);
  height: calc(100vh - 100px); }
  .playVideo_videoContainer__1PbN3 iframe {
    width: calc(100%);
    height: calc(100vh - 150px); }
  .playVideo_videoContainer__1PbN3.playVideo_smallVideo__k9ah9 {
    height: 390px;
    width: 500px; }
    .playVideo_videoContainer__1PbN3.playVideo_smallVideo__k9ah9 iframe {
      height: 390px;
      width: 500px; }

@media screen and (max-width: 1260px) {
  .playVideo_videoContainer__1PbN3 {
    width: calc(100vw - 40px) !important;
    height: calc((100vw - 40px) * 0.61) !important; }
    .playVideo_videoContainer__1PbN3 iframe {
      width: calc(100vw - 40px) !important;
      height: calc((100vw - 40px) * 0.61) !important; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.adminMediaSection_Form__1X1z8 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.adminMediaSection_Form_label__dFKsl {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.adminMediaSection_Form_labelContent__G1iQE {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.adminMediaSection_Form_error__2Idi_ {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.adminMediaSection_Form_inputSubmit__UR3pw {
  width: auto !important; }

.adminMediaSection_InputText__qEYQ0 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .adminMediaSection_InputText__qEYQ0:focus {
    outline: none; }

.adminMediaSection_inputContainer__3oNeH {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .adminMediaSection_inputContainer__3oNeH.adminMediaSection_small__2c8jo {
    margin: 10px;
    padding: 0 15px; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_small__2c8jo .adminMediaSection_input__1Sbu7 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .adminMediaSection_inputContainer__3oNeH.adminMediaSection_smallest__3eJXW {
    width: 200px;
    border-color: transparent; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_smallest__3eJXW .adminMediaSection_input__1Sbu7 {
      font-size: 15px; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_smallest__3eJXW .adminMediaSection_icon__31jqD {
      font-size: 30px;
      margin-right: 10px; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_smallest__3eJXW.adminMediaSection_focus__2VTN5 {
      border-color: transparent; }
  .adminMediaSection_inputContainer__3oNeH .adminMediaSection_input__1Sbu7 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .adminMediaSection_inputContainer__3oNeH .adminMediaSection_icon__31jqD {
    color: #BDD9BF;
    margin-right: 20px; }
  .adminMediaSection_inputContainer__3oNeH.adminMediaSection_focus__2VTN5 {
    border: 1px solid #FFC857; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_focus__2VTN5 .adminMediaSection_icon__31jqD {
      color: #FFC857; }
    .adminMediaSection_inputContainer__3oNeH.adminMediaSection_focus__2VTN5 .adminMediaSection_input__1Sbu7 {
      color: #FFC857; }

.adminMediaSection_inputWithSuggestionsContainer__kQFXc {
  display: flex;
  flex-direction: column; }
  .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_alternativeSearch__1itvN {
    color: #BDD9BF;
    font-size: 12px; }
    .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_alternativeSearch__1itvN span {
      margin-left: 5px; }
  .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB {
    width: 300px; }
    .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB .adminMediaSection_loadingContainer__9XooG {
      margin-top: 20px; }
    .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB .adminMediaSection_suggestionItem__2yd3D {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB .adminMediaSection_suggestionItem__2yd3D.adminMediaSection_active__1-9ps {
        color: #FFC857; }
      .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB .adminMediaSection_suggestionItem__2yd3D:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_suggestionsContainer__2fXTB .adminMediaSection_noMatch__2zCty {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .adminMediaSection_inputWithSuggestionsContainer__kQFXc .adminMediaSection_inputContainer__3oNeH {
    margin: 0;
    padding: 0; }

.adminMediaSection_hidden__1sO-M {
  display: none !important; }

.adminMediaSection_btn__1b3mm {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .adminMediaSection_btn__1b3mm:hover, .adminMediaSection_btn__1b3mm:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .adminMediaSection_btn__1b3mm:focus {
    outline: none !important; }
  .adminMediaSection_btn__1b3mm::-moz-focus-inner {
    border: 0 !important; }
  .adminMediaSection_btn__1b3mm.adminMediaSection_small__2c8jo {
    font-size: 12px;
    padding: 6px 6px; }
  .adminMediaSection_btn__1b3mm.adminMediaSection_disabled__2zTX- {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.adminMediaSection_navMenuItem__1WDqs {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.adminMediaSection_navMenuItem__1WDqs::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.adminMediaSection_navMenuItem__1WDqs.adminMediaSection_active__1-9ps {
  color: #FFC857; }

.adminMediaSection_navMenuItem__1WDqs:hover::after,
.adminMediaSection_navMenuItem__1WDqs:focus::after,
.adminMediaSection_navMenuItem__1WDqs.adminMediaSection_active__1-9ps::after {
  width: 100%; }

.adminMediaSection_navMenuItem__1WDqs.adminMediaSection_active__1-9ps::after {
  background: #FFC857;
  width: 100%; }

.adminMediaSection_cardContainer__1wX3d {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_hoveredInfo__2dhFH {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .adminMediaSection_cardContainer__1wX3d .adminMediaSection_hoveredInfo__2dhFH .adminMediaSection_footer__1b-Zl {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_backColor__1GAMU {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_backImage__3GOf- {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_title__1YZn3 {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_secondaryInfoContainer__ofHhV {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_mediasSummaryContainer__1cn9m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .adminMediaSection_cardContainer__1wX3d .adminMediaSection_mediasSummaryContainer__1cn9m .adminMediaSection_badge__3Qlhu {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .adminMediaSection_cardContainer__1wX3d .adminMediaSection_mediasSummaryContainer__1cn9m .adminMediaSection_badge__3Qlhu .adminMediaSection_number__5O0Zw {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .adminMediaSection_cardContainer__1wX3d .adminMediaSection_mediasSummaryContainer__1cn9m .adminMediaSection_badge__3Qlhu .adminMediaSection_emoji__1Q8IM {
        margin-left: 3px; }
  .adminMediaSection_cardContainer__1wX3d img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .adminMediaSection_cardContainer__1wX3d:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .adminMediaSection_cardContainer__1wX3d:hover .adminMediaSection_hoveredInfo__2dhFH {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .adminMediaSection_cardContainer__1wX3d:hover .adminMediaSection_noBlur__35QzQ {
      -webkit-filter: none !important;
              filter: none !important; }
    .adminMediaSection_cardContainer__1wX3d:hover .adminMediaSection_backImage__3GOf- {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.adminMediaSection_slick-active__P5Pcf div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .adminMediaSection_inputContainer__3oNeH .adminMediaSection_input__1Sbu7 {
    width: auto; }
  .adminMediaSection_cardContainer__1wX3d .adminMediaSection_mediasSummaryContainer__1cn9m {
    display: none; } }

.adminMediaSection_noMedia__WZcha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #FFC857;
  min-height: 300px; }
  .adminMediaSection_noMedia__WZcha .adminMediaSection_emoji__1Q8IM {
    padding: 0 10px 8px; }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.gamePage_Form__2zinA {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.gamePage_Form_label__2kLEl {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.gamePage_Form_labelContent__3Ghiy {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.gamePage_Form_error__16N_j {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.gamePage_Form_inputSubmit__3nJ9G {
  width: auto !important; }

.gamePage_InputText__6CzE1 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .gamePage_InputText__6CzE1:focus {
    outline: none; }

.gamePage_inputContainer__3c_qQ {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .gamePage_inputContainer__3c_qQ.gamePage_small__BNm4V {
    margin: 10px;
    padding: 0 15px; }
    .gamePage_inputContainer__3c_qQ.gamePage_small__BNm4V .gamePage_input__3LjI6 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE {
    width: 200px;
    border-color: transparent; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE .gamePage_input__3LjI6 {
      font-size: 15px; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE .gamePage_icon__3U7hj {
      font-size: 30px;
      margin-right: 10px; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE.gamePage_focus__6fIwx {
      border-color: transparent; }
  .gamePage_inputContainer__3c_qQ .gamePage_input__3LjI6 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .gamePage_inputContainer__3c_qQ .gamePage_icon__3U7hj {
    color: #BDD9BF;
    margin-right: 20px; }
  .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx {
    border: 1px solid #FFC857; }
    .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx .gamePage_icon__3U7hj {
      color: #FFC857; }
    .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx .gamePage_input__3LjI6 {
      color: #FFC857; }

.gamePage_inputWithSuggestionsContainer__2J0JO {
  display: flex;
  flex-direction: column; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_alternativeSearch__1T8c_ {
    color: #BDD9BF;
    font-size: 12px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_alternativeSearch__1T8c_ span {
      margin-left: 5px; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV {
    width: 300px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_loadingContainer__2EPLT {
      margin-top: 20px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5 {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5.gamePage_active__2Tk4X {
        color: #FFC857; }
      .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_noMatch__2V_cm {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_inputContainer__3c_qQ {
    margin: 0;
    padding: 0; }

.gamePage_hidden__14SeG {
  display: none !important; }

.gamePage_btn__2F4BO {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .gamePage_btn__2F4BO:hover, .gamePage_btn__2F4BO:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .gamePage_btn__2F4BO:focus {
    outline: none !important; }
  .gamePage_btn__2F4BO::-moz-focus-inner {
    border: 0 !important; }
  .gamePage_btn__2F4BO.gamePage_small__BNm4V {
    font-size: 12px;
    padding: 6px 6px; }
  .gamePage_btn__2F4BO.gamePage_disabled__1cpAX {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.gamePage_navMenuItem__3ATu3 {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.gamePage_navMenuItem__3ATu3::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X {
  color: #FFC857; }

.gamePage_navMenuItem__3ATu3:hover::after,
.gamePage_navMenuItem__3ATu3:focus::after,
.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X::after {
  width: 100%; }

.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X::after {
  background: #FFC857;
  width: 100%; }

.gamePage_cardContainer__Oi8Qk {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .gamePage_cardContainer__Oi8Qk .gamePage_hoveredInfo__ZVLX0 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .gamePage_cardContainer__Oi8Qk .gamePage_hoveredInfo__ZVLX0 .gamePage_footer__nIbWC {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .gamePage_cardContainer__Oi8Qk .gamePage_backColor__3EAYZ {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .gamePage_cardContainer__Oi8Qk .gamePage_backImage__1JETx {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .gamePage_cardContainer__Oi8Qk .gamePage_title__hTyBr {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .gamePage_cardContainer__Oi8Qk .gamePage_secondaryInfoContainer__1bc1S {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- .gamePage_number__1Q7_J {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- .gamePage_emoji__T7Feh {
        margin-left: 3px; }
  .gamePage_cardContainer__Oi8Qk img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .gamePage_cardContainer__Oi8Qk:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_hoveredInfo__ZVLX0 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_noBlur__2Kg6J {
      -webkit-filter: none !important;
              filter: none !important; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_backImage__1JETx {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.gamePage_slick-active__2ahBi div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .gamePage_inputContainer__3c_qQ .gamePage_input__3LjI6 {
    width: auto; }
  .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE {
    display: none; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.gamePage_Form__2zinA {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.gamePage_Form_label__2kLEl {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.gamePage_Form_labelContent__3Ghiy {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.gamePage_Form_error__16N_j {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.gamePage_Form_inputSubmit__3nJ9G {
  width: auto !important; }

.gamePage_InputText__6CzE1 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .gamePage_InputText__6CzE1:focus {
    outline: none; }

.gamePage_inputContainer__3c_qQ {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .gamePage_inputContainer__3c_qQ.gamePage_small__BNm4V {
    margin: 10px;
    padding: 0 15px; }
    .gamePage_inputContainer__3c_qQ.gamePage_small__BNm4V .gamePage_input__3LjI6 {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE {
    width: 200px;
    border-color: transparent; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE .gamePage_input__3LjI6 {
      font-size: 15px; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE .gamePage_icon__3U7hj {
      font-size: 30px;
      margin-right: 10px; }
    .gamePage_inputContainer__3c_qQ.gamePage_smallest__3JpvE.gamePage_focus__6fIwx {
      border-color: transparent; }
  .gamePage_inputContainer__3c_qQ .gamePage_input__3LjI6 {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .gamePage_inputContainer__3c_qQ .gamePage_icon__3U7hj {
    color: #BDD9BF;
    margin-right: 20px; }
  .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx {
    border: 1px solid #FFC857; }
    .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx .gamePage_icon__3U7hj {
      color: #FFC857; }
    .gamePage_inputContainer__3c_qQ.gamePage_focus__6fIwx .gamePage_input__3LjI6 {
      color: #FFC857; }

.gamePage_inputWithSuggestionsContainer__2J0JO {
  display: flex;
  flex-direction: column; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_alternativeSearch__1T8c_ {
    color: #BDD9BF;
    font-size: 12px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_alternativeSearch__1T8c_ span {
      margin-left: 5px; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV {
    width: 300px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_loadingContainer__2EPLT {
      margin-top: 20px; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5 {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5.gamePage_active__2Tk4X {
        color: #FFC857; }
      .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_suggestionItem__1Q-c5:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_suggestionsContainer__PcnEV .gamePage_noMatch__2V_cm {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .gamePage_inputWithSuggestionsContainer__2J0JO .gamePage_inputContainer__3c_qQ {
    margin: 0;
    padding: 0; }

.gamePage_hidden__14SeG {
  display: none !important; }

.gamePage_btn__2F4BO {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .gamePage_btn__2F4BO:hover, .gamePage_btn__2F4BO:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .gamePage_btn__2F4BO:focus {
    outline: none !important; }
  .gamePage_btn__2F4BO::-moz-focus-inner {
    border: 0 !important; }
  .gamePage_btn__2F4BO.gamePage_small__BNm4V {
    font-size: 12px;
    padding: 6px 6px; }
  .gamePage_btn__2F4BO.gamePage_disabled__1cpAX {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.gamePage_navMenuItem__3ATu3 {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.gamePage_navMenuItem__3ATu3::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X {
  color: #FFC857; }

.gamePage_navMenuItem__3ATu3:hover::after,
.gamePage_navMenuItem__3ATu3:focus::after,
.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X::after {
  width: 100%; }

.gamePage_navMenuItem__3ATu3.gamePage_active__2Tk4X::after {
  background: #FFC857;
  width: 100%; }

.gamePage_cardContainer__Oi8Qk {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .gamePage_cardContainer__Oi8Qk .gamePage_hoveredInfo__ZVLX0 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .gamePage_cardContainer__Oi8Qk .gamePage_hoveredInfo__ZVLX0 .gamePage_footer__nIbWC {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .gamePage_cardContainer__Oi8Qk .gamePage_backColor__3EAYZ {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .gamePage_cardContainer__Oi8Qk .gamePage_backImage__1JETx {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .gamePage_cardContainer__Oi8Qk .gamePage_title__hTyBr {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .gamePage_cardContainer__Oi8Qk .gamePage_secondaryInfoContainer__1bc1S {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- .gamePage_number__1Q7_J {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE .gamePage_badge__1E3n- .gamePage_emoji__T7Feh {
        margin-left: 3px; }
  .gamePage_cardContainer__Oi8Qk img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .gamePage_cardContainer__Oi8Qk:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_hoveredInfo__ZVLX0 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_noBlur__2Kg6J {
      -webkit-filter: none !important;
              filter: none !important; }
    .gamePage_cardContainer__Oi8Qk:hover .gamePage_backImage__1JETx {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.gamePage_slick-active__2ahBi div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .gamePage_inputContainer__3c_qQ .gamePage_input__3LjI6 {
    width: auto; }
  .gamePage_cardContainer__Oi8Qk .gamePage_mediasSummaryContainer__eYlyE {
    display: none; } }

.gamePage_noMedia__2s2y6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #FFC857;
  min-height: 300px; }
  .gamePage_noMedia__2s2y6 .gamePage_emoji__T7Feh {
    padding: 0 10px 8px; }

.gamePage_gamePageContainer__2_SrW {
  width: 100%; }
  .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD {
    background-color: #08090A;
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V {
      position: relative;
      z-index: 2;
      max-width: 1300px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_coverImg__2ijJh {
        width: 200px;
        margin-right: 50px;
        margin-left: 50px;
        box-shadow: 10px 10px 0 2px #FFC857; }
      .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_gameInfos__2BW1X {
        color: #FFC857;
        margin: 0 10px; }
        .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_gameInfos__2BW1X .gamePage_gameTitle__2H346 {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 36px;
          font-weight: normal;
          padding-bottom: 10px; }
        .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_gameInfos__2BW1X .gamePage_gameDevelopers__QKTZk {
          font-size: 20px;
          font-style: italic; }
    .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_backImage__1JETx {
      width: 100%;
      height: 100%;
      -webkit-filter: blur(2px);
              filter: blur(2px);
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.6;
      position: absolute;
      left: 0;
      top: 0; }

@media screen and (max-width: 700px) {
  .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD {
    height: 340px; }
    .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V {
      flex-direction: column; }
      .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_coverImg__2ijJh {
        margin-bottom: 20px;
        margin-right: 0;
        margin-left: 0;
        width: 150px;
        height: 200px; }
      .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_gameInfos__2BW1X {
        text-align: center; }
        .gamePage_gamePageContainer__2_SrW .gamePage_gameHeader__3veoD .gamePage_headerContent__3VF8V .gamePage_gameInfos__2BW1X .gamePage_gameTitle__2H346 {
          font-size: 24px; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.mediaPage_Form__3gvUL {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.mediaPage_Form_label__3SRzM {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.mediaPage_Form_labelContent__2GvRh {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.mediaPage_Form_error__BWvMi {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.mediaPage_Form_inputSubmit__2hUqu {
  width: auto !important; }

.mediaPage_InputText__29PqP {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .mediaPage_InputText__29PqP:focus {
    outline: none; }

.mediaPage_inputContainer__3zZmx {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .mediaPage_inputContainer__3zZmx.mediaPage_small__2_XZm {
    margin: 10px;
    padding: 0 15px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_small__2_XZm .mediaPage_input__5ulaA {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP {
    width: 200px;
    border-color: transparent; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP .mediaPage_input__5ulaA {
      font-size: 15px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP .mediaPage_icon__3wkjW {
      font-size: 30px;
      margin-right: 10px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP.mediaPage_focus__1IXxD {
      border-color: transparent; }
  .mediaPage_inputContainer__3zZmx .mediaPage_input__5ulaA {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .mediaPage_inputContainer__3zZmx .mediaPage_icon__3wkjW {
    color: #BDD9BF;
    margin-right: 20px; }
  .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD {
    border: 1px solid #FFC857; }
    .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD .mediaPage_icon__3wkjW {
      color: #FFC857; }
    .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD .mediaPage_input__5ulaA {
      color: #FFC857; }

.mediaPage_inputWithSuggestionsContainer__3XPWp {
  display: flex;
  flex-direction: column; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_alternativeSearch__2HUat {
    color: #BDD9BF;
    font-size: 12px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_alternativeSearch__2HUat span {
      margin-left: 5px; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE {
    width: 300px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_loadingContainer__3DQNs {
      margin-top: 20px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP.mediaPage_active__2JEr_ {
        color: #FFC857; }
      .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_noMatch__T70GY {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_inputContainer__3zZmx {
    margin: 0;
    padding: 0; }

.mediaPage_hidden__3HaPM {
  display: none !important; }

.mediaPage_btn__1H7c4 {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .mediaPage_btn__1H7c4:hover, .mediaPage_btn__1H7c4:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .mediaPage_btn__1H7c4:focus {
    outline: none !important; }
  .mediaPage_btn__1H7c4::-moz-focus-inner {
    border: 0 !important; }
  .mediaPage_btn__1H7c4.mediaPage_small__2_XZm {
    font-size: 12px;
    padding: 6px 6px; }
  .mediaPage_btn__1H7c4.mediaPage_disabled__2VlRL {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.mediaPage_navMenuItem__3WP8x {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.mediaPage_navMenuItem__3WP8x::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_ {
  color: #FFC857; }

.mediaPage_navMenuItem__3WP8x:hover::after,
.mediaPage_navMenuItem__3WP8x:focus::after,
.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_::after {
  width: 100%; }

.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_::after {
  background: #FFC857;
  width: 100%; }

.mediaPage_cardContainer__1BsCO {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .mediaPage_cardContainer__1BsCO .mediaPage_hoveredInfo__3Rfe7 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .mediaPage_cardContainer__1BsCO .mediaPage_hoveredInfo__3Rfe7 .mediaPage_footer__1zFwh {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .mediaPage_cardContainer__1BsCO .mediaPage_backColor__zA4dv {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .mediaPage_cardContainer__1BsCO .mediaPage_backImage__q3XrV {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .mediaPage_cardContainer__1BsCO .mediaPage_title__24EqB {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .mediaPage_cardContainer__1BsCO .mediaPage_secondaryInfoContainer__8uZQt {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y .mediaPage_number__27UTI {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y .mediaPage_emoji__QAGyg {
        margin-left: 3px; }
  .mediaPage_cardContainer__1BsCO img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .mediaPage_cardContainer__1BsCO:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_hoveredInfo__3Rfe7 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_noBlur__3G7xp {
      -webkit-filter: none !important;
              filter: none !important; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_backImage__q3XrV {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.mediaPage_slick-active__E1-cg div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .mediaPage_inputContainer__3zZmx .mediaPage_input__5ulaA {
    width: auto; }
  .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN {
    display: none; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.mediaPage_Form__3gvUL {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.mediaPage_Form_label__3SRzM {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.mediaPage_Form_labelContent__2GvRh {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.mediaPage_Form_error__BWvMi {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.mediaPage_Form_inputSubmit__2hUqu {
  width: auto !important; }

.mediaPage_InputText__29PqP {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .mediaPage_InputText__29PqP:focus {
    outline: none; }

.mediaPage_inputContainer__3zZmx {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .mediaPage_inputContainer__3zZmx.mediaPage_small__2_XZm {
    margin: 10px;
    padding: 0 15px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_small__2_XZm .mediaPage_input__5ulaA {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP {
    width: 200px;
    border-color: transparent; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP .mediaPage_input__5ulaA {
      font-size: 15px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP .mediaPage_icon__3wkjW {
      font-size: 30px;
      margin-right: 10px; }
    .mediaPage_inputContainer__3zZmx.mediaPage_smallest__3R6OP.mediaPage_focus__1IXxD {
      border-color: transparent; }
  .mediaPage_inputContainer__3zZmx .mediaPage_input__5ulaA {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .mediaPage_inputContainer__3zZmx .mediaPage_icon__3wkjW {
    color: #BDD9BF;
    margin-right: 20px; }
  .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD {
    border: 1px solid #FFC857; }
    .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD .mediaPage_icon__3wkjW {
      color: #FFC857; }
    .mediaPage_inputContainer__3zZmx.mediaPage_focus__1IXxD .mediaPage_input__5ulaA {
      color: #FFC857; }

.mediaPage_inputWithSuggestionsContainer__3XPWp {
  display: flex;
  flex-direction: column; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_alternativeSearch__2HUat {
    color: #BDD9BF;
    font-size: 12px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_alternativeSearch__2HUat span {
      margin-left: 5px; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE {
    width: 300px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_loadingContainer__3DQNs {
      margin-top: 20px; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP.mediaPage_active__2JEr_ {
        color: #FFC857; }
      .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_suggestionItem__1iZiP:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_suggestionsContainer__1nxpE .mediaPage_noMatch__T70GY {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .mediaPage_inputWithSuggestionsContainer__3XPWp .mediaPage_inputContainer__3zZmx {
    margin: 0;
    padding: 0; }

.mediaPage_hidden__3HaPM {
  display: none !important; }

.mediaPage_btn__1H7c4 {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .mediaPage_btn__1H7c4:hover, .mediaPage_btn__1H7c4:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .mediaPage_btn__1H7c4:focus {
    outline: none !important; }
  .mediaPage_btn__1H7c4::-moz-focus-inner {
    border: 0 !important; }
  .mediaPage_btn__1H7c4.mediaPage_small__2_XZm {
    font-size: 12px;
    padding: 6px 6px; }
  .mediaPage_btn__1H7c4.mediaPage_disabled__2VlRL {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.mediaPage_navMenuItem__3WP8x {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.mediaPage_navMenuItem__3WP8x::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_ {
  color: #FFC857; }

.mediaPage_navMenuItem__3WP8x:hover::after,
.mediaPage_navMenuItem__3WP8x:focus::after,
.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_::after {
  width: 100%; }

.mediaPage_navMenuItem__3WP8x.mediaPage_active__2JEr_::after {
  background: #FFC857;
  width: 100%; }

.mediaPage_cardContainer__1BsCO {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .mediaPage_cardContainer__1BsCO .mediaPage_hoveredInfo__3Rfe7 {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .mediaPage_cardContainer__1BsCO .mediaPage_hoveredInfo__3Rfe7 .mediaPage_footer__1zFwh {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .mediaPage_cardContainer__1BsCO .mediaPage_backColor__zA4dv {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .mediaPage_cardContainer__1BsCO .mediaPage_backImage__q3XrV {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .mediaPage_cardContainer__1BsCO .mediaPage_title__24EqB {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .mediaPage_cardContainer__1BsCO .mediaPage_secondaryInfoContainer__8uZQt {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y .mediaPage_number__27UTI {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN .mediaPage_badge__SBf2y .mediaPage_emoji__QAGyg {
        margin-left: 3px; }
  .mediaPage_cardContainer__1BsCO img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .mediaPage_cardContainer__1BsCO:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_hoveredInfo__3Rfe7 {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_noBlur__3G7xp {
      -webkit-filter: none !important;
              filter: none !important; }
    .mediaPage_cardContainer__1BsCO:hover .mediaPage_backImage__q3XrV {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.mediaPage_slick-active__E1-cg div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .mediaPage_inputContainer__3zZmx .mediaPage_input__5ulaA {
    width: auto; }
  .mediaPage_cardContainer__1BsCO .mediaPage_mediasSummaryContainer__KpaHN {
    display: none; } }

.mediaPage_titleContainer__16qxC {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Karla', sans-serif;
  height: 100%;
  color: #FFC857;
  font-size: 35px;
  margin-bottom: 20px; }
  .mediaPage_titleContainer__16qxC .mediaPage_logo__azeAv {
    height: 100%;
    padding-bottom: 10px; }

.mediaPage_subtitle__1lweU {
  color: #BDD9BF;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center; }
  .mediaPage_subtitle__1lweU strong {
    color: #FFC857; }

@media screen and (max-width: 700px) {
  .mediaPage_headerContainer__148wX .mediaPage_titleContainer__16qxC {
    font-size: 30px; } }

.mediaPage_mediaPageContainer__1UF4j, .mediaPage_mediaListPageContainer__2tiav {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  z-index: 2; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFC857;
    margin-top: 20px; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaLogo__3bahX, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaLogo__3bahX {
      height: 200px;
      width: 200px;
      background-color: #2E4052;
      box-shadow: 10px 10px 0 2px #FFC857;
      margin-bottom: 30px; }
      .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaLogo__3bahX img, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaLogo__3bahX img {
        height: 100%;
        width: 100%; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaName__2KBOa, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaName__2KBOa {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 36px;
      font-weight: normal;
      margin-bottom: 5px;
      text-align: center; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_epNumber__1Af8q, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud .mediaPage_epNumber__1Af8q {
      font-size: 18px; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaDescription__2G9mp, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaDescription__2G9mp {
      margin: 30px 10px;
      color: #BDD9BF;
      font-style: italic;
      max-width: 650px;
      white-space: pre-wrap; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_subscribeBtn__2iLma, .mediaPage_mediaListPageContainer__2tiav .mediaPage_subscribeBtn__2iLma {
    display: flex;
    margin: 15px; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_subscribeBtn__2iLma .mediaPage_mobile__326sF, .mediaPage_mediaListPageContainer__2tiav .mediaPage_subscribeBtn__2iLma .mediaPage_mobile__326sF {
      display: none; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaRowContainer__39tt8, .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediaRowContainer__39tt8 {
    display: flex;
    flex-direction: column; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_gamesContainer__3A30B, .mediaPage_mediaListPageContainer__2tiav .mediaPage_gamesContainer__3A30B {
    display: flex;
    flex-direction: column; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_gamesContainer__3A30B .mediaPage_gamesGridTitleContainer__1cyCk, .mediaPage_mediaListPageContainer__2tiav .mediaPage_gamesContainer__3A30B .mediaPage_gamesGridTitleContainer__1cyCk {
      display: flex;
      flex-direction: row;
      align-items: center;
      letter-spacing: 1px;
      color: #BDD9BF;
      margin: 0 10px 5px; }
      .mediaPage_mediaPageContainer__1UF4j .mediaPage_gamesContainer__3A30B .mediaPage_gamesGridTitleContainer__1cyCk .mediaPage_gamesGridTitleImg__35L4G, .mediaPage_mediaListPageContainer__2tiav .mediaPage_gamesContainer__3A30B .mediaPage_gamesGridTitleContainer__1cyCk .mediaPage_gamesGridTitleImg__35L4G {
        margin-right: 10px;
        border-radius: 50%;
        background-color: #08090A;
        color: #FFC857;
        font-size: 20px;
        padding: 5px; }
    .mediaPage_mediaPageContainer__1UF4j .mediaPage_gamesContainer__3A30B .mediaPage_gamesGrid__3uA_R, .mediaPage_mediaListPageContainer__2tiav .mediaPage_gamesContainer__3A30B .mediaPage_gamesGrid__3uA_R {
      align-self: center; }

.mediaPage_mediaListPageContainer__2tiav .mediaPage_pageTitle__3N5FA {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 10px;
  color: #FFC857;
  margin-bottom: 20px;
  text-align: center; }

.mediaPage_mediaListPageContainer__2tiav .mediaPage_info__2ripP {
  color: #BDD9BF;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .mediaPage_mediaListPageContainer__2tiav .mediaPage_info__2ripP .mediaPage_text__3_COJ {
    margin-right: 5px; }
  .mediaPage_mediaListPageContainer__2tiav .mediaPage_info__2ripP a {
    margin: 0 5px; }

.mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme {
  color: #BDD9BF;
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex: 1 1; }
  .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px auto;
    max-width: 800px;
    width: 100%; }
    .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockLeft__1atUa {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 150px;
      color: #FFC857; }
      .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockLeft__1atUa .mediaPage_mediaLogo__3bahX {
        transition: box-shadow 0.1s, -webkit-transform 0.1s;
        transition: box-shadow 0.1s, transform 0.1s;
        transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s;
        width: 150px;
        height: 150px;
        margin-bottom: 20px; }
    .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockRight__TpB67 {
      padding: 5px 30px;
      max-width: 650px;
      flex: 1 1; }
      .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockRight__TpB67 .mediaPage_mediaTitle__2m1-L {
        color: #FFC857;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column; }
        .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockRight__TpB67 .mediaPage_mediaTitle__2m1-L .mediaPage_mediaName__2KBOa {
          text-transform: uppercase;
          font-weight: normal;
          margin-right: 5px;
          font-size: 18px; }
        .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockRight__TpB67 .mediaPage_mediaTitle__2m1-L .mediaPage_mediaType__t20HV {
          font-size: 15px; }
      .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockRight__TpB67 .mediaPage_mediaDescription__2G9mp {
        color: #BDD9BF;
        font-style: italic;
        font-size: 15px; }
    .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6:hover .mediaPage_mediaLogo__3bahX {
      box-shadow: 10px 10px 0 2px #FFC857;
      -webkit-transform: translateX(-10px) translateY(-10px);
              transform: translateX(-10px) translateY(-10px);
      transition: box-shadow 0.2s, -webkit-transform 0.2s;
      transition: box-shadow 0.2s, transform 0.2s;
      transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }

@media screen and (max-width: 700px) {
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_mediaTitleContainer__1eGud .mediaPage_mediaName__2KBOa {
    font-size: 24px; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_subscribeBtn__2iLma .mediaPage_mobile__326sF {
    display: unset; }
  .mediaPage_mediaPageContainer__1UF4j .mediaPage_subscribeBtn__2iLma .mediaPage_desktop__1ynqX {
    display: none; }
  .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 {
    margin: 20px 5px; }
    .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockLeft__1atUa {
      width: 120px !important; }
      .mediaPage_mediaListPageContainer__2tiav .mediaPage_mediasList__gHDme .mediaPage_mediaItem__2-ar6 .mediaPage_mediaBlockLeft__1atUa .mediaPage_mediaLogo__3bahX {
        width: 120px;
        height: 120px; } }

/**
  GLOBAL VARIABLES
 */
body {
  margin: 0; }

html {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #9999ff; }

strong {
  font-weight: normal; }

h2 {
  color: #FFC857;
  font-weight: 300;
  font-size: 18px; }

.pageNotFound_Form__2m6G7 {
  display: flex;
  flex-direction: column;
  border: 1px solid #FFC857;
  padding: 30px 80px; }

.pageNotFound_Form_label__UxCed {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }

.pageNotFound_Form_labelContent__tANIc {
  color: #BDD9BF;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 1px; }

.pageNotFound_Form_error__33gPZ {
  font-size: 15px;
  margin: 10px 0 0 15px;
  height: 20px;
  color: #FFC857;
  width: 300px; }

.pageNotFound_Form_inputSubmit__1-3BD {
  width: auto !important; }

.pageNotFound_InputText__2N745 {
  background: none;
  box-shadow: none;
  padding: 10px 20px;
  border: 1px solid #BDD9BF;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  color: #BDD9BF;
  font-weight: 300;
  font-family: 'Roboto', sans-serif; }
  .pageNotFound_InputText__2N745:focus {
    outline: none; }

.pageNotFound_inputContainer__3PVpx {
  z-index: 1;
  padding: 0 20px;
  border: 1px solid #BDD9BF;
  margin: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .pageNotFound_inputContainer__3PVpx.pageNotFound_small__2OqjS {
    margin: 10px;
    padding: 0 15px; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_small__2OqjS .pageNotFound_input__3B6eH {
      font-size: 15px;
      width: 300px;
      padding: 8px 0; }
  .pageNotFound_inputContainer__3PVpx.pageNotFound_smallest__ps-to {
    width: 200px;
    border-color: transparent; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_smallest__ps-to .pageNotFound_input__3B6eH {
      font-size: 15px; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_smallest__ps-to .pageNotFound_icon__UFcJ4 {
      font-size: 30px;
      margin-right: 10px; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_smallest__ps-to.pageNotFound_focus__3xJBR {
      border-color: transparent; }
  .pageNotFound_inputContainer__3PVpx .pageNotFound_input__3B6eH {
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    width: 400px;
    border: none;
    color: #BDD9BF;
    font-size: 18px;
    font-weight: 300; }
  .pageNotFound_inputContainer__3PVpx .pageNotFound_icon__UFcJ4 {
    color: #BDD9BF;
    margin-right: 20px; }
  .pageNotFound_inputContainer__3PVpx.pageNotFound_focus__3xJBR {
    border: 1px solid #FFC857; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_focus__3xJBR .pageNotFound_icon__UFcJ4 {
      color: #FFC857; }
    .pageNotFound_inputContainer__3PVpx.pageNotFound_focus__3xJBR .pageNotFound_input__3B6eH {
      color: #FFC857; }

.pageNotFound_inputWithSuggestionsContainer__2Vq72 {
  display: flex;
  flex-direction: column; }
  .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_alternativeSearch__2NhpU {
    color: #BDD9BF;
    font-size: 12px; }
    .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_alternativeSearch__2NhpU span {
      margin-left: 5px; }
  .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K {
    width: 300px; }
    .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K .pageNotFound_loadingContainer__2nksf {
      margin-top: 20px; }
    .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K .pageNotFound_suggestionItem__2gEV_ {
      color: #BDD9BF;
      font-size: 15px;
      padding: 3px 25px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K .pageNotFound_suggestionItem__2gEV_.pageNotFound_active__35yJi {
        color: #FFC857; }
      .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K .pageNotFound_suggestionItem__2gEV_:hover {
        color: #FFC857;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
        transition: all 0.2s ease-in-out; }
    .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_suggestionsContainer__1E17K .pageNotFound_noMatch__22lr0 {
      margin-left: 25px;
      color: #FFC857;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal; }
  .pageNotFound_inputWithSuggestionsContainer__2Vq72 .pageNotFound_inputContainer__3PVpx {
    margin: 0;
    padding: 0; }

.pageNotFound_hidden__1tnOS {
  display: none !important; }

.pageNotFound_btn__15qtU {
  color: #BDD9BF;
  background-color: transparent;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BDD9BF;
  padding: 10px 10px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 0 #FFC857;
  transition: box-shadow 0.1s, -webkit-transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s;
  transition: box-shadow 0.1s, transform 0.1s, -webkit-transform 0.1s; }
  .pageNotFound_btn__15qtU:hover, .pageNotFound_btn__15qtU:active {
    border: 1px solid #FFC857;
    color: #FFC857;
    box-shadow: 3px 3px 0 0 #FFC857;
    -webkit-transform: translateX(-3px) translateY(-3px);
            transform: translateX(-3px) translateY(-3px);
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s;
    transition: box-shadow 0.2s, transform 0.2s, -webkit-transform 0.2s; }
  .pageNotFound_btn__15qtU:focus {
    outline: none !important; }
  .pageNotFound_btn__15qtU::-moz-focus-inner {
    border: 0 !important; }
  .pageNotFound_btn__15qtU.pageNotFound_small__2OqjS {
    font-size: 12px;
    padding: 6px 6px; }
  .pageNotFound_btn__15qtU.pageNotFound_disabled__1Qz-7 {
    opacity: 0.4; }

/* Effect 4: bottom border enlarge */
.pageNotFound_navMenuItem__2I0Hu {
  position: relative;
  color: #BDD9BF;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px; }

.pageNotFound_navMenuItem__2I0Hu::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #BDD9BF;
  content: '';
  text-align: center;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
  transition: width 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.pageNotFound_navMenuItem__2I0Hu.pageNotFound_active__35yJi {
  color: #FFC857; }

.pageNotFound_navMenuItem__2I0Hu:hover::after,
.pageNotFound_navMenuItem__2I0Hu:focus::after,
.pageNotFound_navMenuItem__2I0Hu.pageNotFound_active__35yJi::after {
  width: 100%; }

.pageNotFound_navMenuItem__2I0Hu.pageNotFound_active__35yJi::after {
  background: #FFC857;
  width: 100%; }

.pageNotFound_cardContainer__32Drw {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  opacity: 0.85;
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  overflow: hidden; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_hoveredInfo__30bnd {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    height: 100%;
    position: relative; }
    .pageNotFound_cardContainer__32Drw .pageNotFound_hoveredInfo__30bnd .pageNotFound_footer__EAuM- {
      height: 25px;
      position: absolute;
      bottom: 0;
      width: 100%; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_backColor__2pla4 {
    background-color: #08090A;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_backImage__tmENW {
    opacity: 1;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-color: #08090A; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_title__yvWWb {
    margin: 30px 5px 10px 0;
    color: #FFC857;
    opacity: 1;
    position: relative;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_secondaryInfoContainer__3nIav {
    font-style: italic;
    color: #FFC857;
    font-size: 15px;
    position: relative; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_mediasSummaryContainer__1CUcZ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px; }
    .pageNotFound_cardContainer__32Drw .pageNotFound_mediasSummaryContainer__1CUcZ .pageNotFound_badge__GUyzC {
      padding: 5px 10px;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #BDD9BF;
      background-color: #2E4052;
      font-weight: normal;
      font-size: 15px;
      margin-top: 7px; }
      .pageNotFound_cardContainer__32Drw .pageNotFound_mediasSummaryContainer__1CUcZ .pageNotFound_badge__GUyzC .pageNotFound_number__3hbX6 {
        font-size: 18px;
        margin-right: 5px;
        color: #FFC857; }
      .pageNotFound_cardContainer__32Drw .pageNotFound_mediasSummaryContainer__1CUcZ .pageNotFound_badge__GUyzC .pageNotFound_emoji__8c6zR {
        margin-left: 3px; }
  .pageNotFound_cardContainer__32Drw img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .pageNotFound_cardContainer__32Drw:hover {
    transition: all 0.2s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
    .pageNotFound_cardContainer__32Drw:hover .pageNotFound_hoveredInfo__30bnd {
      opacity: 1;
      transition: all 0.2s ease-in-out; }
    .pageNotFound_cardContainer__32Drw:hover .pageNotFound_noBlur__sddiG {
      -webkit-filter: none !important;
              filter: none !important; }
    .pageNotFound_cardContainer__32Drw:hover .pageNotFound_backImage__tmENW {
      -webkit-filter: blur(2px);
              filter: blur(2px); }

ul li.pageNotFound_slick-active__PAliL div {
  opacity: 1; }

@media screen and (max-width: 700px) {
  .pageNotFound_inputContainer__3PVpx .pageNotFound_input__3B6eH {
    width: auto; }
  .pageNotFound_cardContainer__32Drw .pageNotFound_mediasSummaryContainer__1CUcZ {
    display: none; } }

.pageNotFound_notFoundContainer__3Z-2z {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1; }
  .pageNotFound_notFoundContainer__3Z-2z .pageNotFound_title__yvWWb {
    font-size: 70px;
    color: #FFC857;
    margin-bottom: 50px;
    font-weight: normal; }
  .pageNotFound_notFoundContainer__3Z-2z .pageNotFound_subtitle__wo_o_ {
    font-size: 20px;
    color: #FFC857;
    font-weight: 300; }
  .pageNotFound_notFoundContainer__3Z-2z .pageNotFound_btn__15qtU {
    margin-top: 40px; }

