@import "src/index";

.activeMediaBoxContainer {
  width: 100%;
  @include flex-column;
  margin: 0 20px 30px;
  position: relative;

  .warning {
    color: $color-bright-2;
    font-weight: $weight-regular;
    font-size: $size-small;
  }

  &.video {
    width: 100%;
    margin: 0;
    padding: 0 20px 30px 20px;
    box-sizing: border-box;

    .titleContainer {
      margin-bottom: 0;
    }

    .bodyContainer {
      flex-direction: column-reverse;

      .rightRow {
        margin-left: 0;

        .rightRowContainer {
          min-height: 0;
          width: 100%;
        }

        .noGame {
          display: none;
        }

      }

      .leftRow {
        margin-top: 20px;
      }
    }
  }

  .closeBtn {
    color: $color-bright-1;
    font-size: 25px;
    position: absolute;
    padding: 10px;
    right: 0;
    top: 0;
    cursor: pointer;

    &:hover {
      color: $color-bright-2;
    }
  }

  .titleContainer {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    max-width: 1000px;
    width: calc(100%);

    .title {
      font-size: $size-bigger;
      letter-spacing: 2px;
      color: $color-bright-2;
      margin-bottom: 5px;
      font-weight: $weight-regular;
      width: calc(100% - 30px);
    }

    .authorAndDateContainer {
      color: $color-bright-2;
      margin: 10px 0;
      @include flex-row;
      align-items: center;

      .authorAndDateContent {
        @include flex-row;
        align-items: center;
        transition: all 0.1s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }

      .author {
        font-weight: normal;
        font-size: $size-small;
      }

      .date {
        font-weight: $weight-light;
        font-size: $size-smaller;
      }

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
        box-shadow: 2px 2px 0 0 $color-bright-2;
      }
    }

    .subscribeBtn {
      display: none;
    }
  }

  .bodyContainer {
    @include flex-row;

    .leftRow, .rightRow {
      @include flex-column;
      align-items: center;
    }

    .leftRow {
      padding-right: 30px;
      flex: 0.5;
      max-width: 500px;
      position: relative;

      .description {
        color: $color-bright-1;
        font-weight: $weight-regular;
        font-size: $size-small;

        .descriptionContent {
          white-space: pre-wrap;
        }

        .link {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .readMoreBtn {
        color: $color-bright-2;
        font-weight: $weight-bold;
        font-size: $size-smaller;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0 0 10px 0;
        letter-spacing: 1px;
        outline: none;
        background: none;
        border: none;
        margin: 0 5px;

        &:after {

        }
      }
    }

    .rightRow {
      flex: 0.5;
      align-items: flex-start;
      margin-left: 30px;

      .rightRowContainer {
        @include flex-column;
        margin-top: 10px;
      }

      .saveContainer, .verifyContainer {
        @include flex-row;
        justify-content: flex-end;
        align-self: flex-end;
        color: $color-bright-1;

        div {
          cursor: pointer;
        }

        .icon {
          font-size: 18px;
          margin-right: 5px;
          margin-top: -2px;
        }

        &:hover {
          color: $color-bright-2;
        }
      }

      .gamesContainer {
        min-height: 150px;
        margin-bottom: 10px;
        @include flex-row;
        flex-wrap: wrap;
        align-self: flex-start;

        .cardContainer {
          width: 112.5px;
          height: 150px;
          opacity: 1;
        }

        .emptyCard {
          background-color: $color-dark-1;

          .backImage {
            background-color: $color-dark-1;
          }
        }

        .hoveredInfo {
          justify-content: center;

          .title {
            font-size: $size-smaller;
            margin: 0;
          }
        }
      }

      .inputWithSuggestionsContainer {
        align-self: flex-start;
      }
    }
  }

}

@media #{$media-tablet} {
  .activeMediaBoxContainer {
    margin: 0;
    padding: 0 20px 30px 20px;
    box-sizing: border-box;

    .bodyContainer {
      flex-direction: column-reverse;

      .rightRow {
        margin-left: 0;

        .rightRowContainer {
          min-height: 0;
        }

        .noGame {
          display: none;
        }

      }

      .leftRow {
        margin-top: 20px;
      }
    }
  }
}

@media #{$media-phone} {
  .activeMediaBoxContainer {

    .titleContainer {

      .subscribeBtn {
        margin-top: 15px;
        display: flex;
      }
    }
  }
}