@import "../../index";

.sectionGridContainer {
  width: 100%;
  box-sizing: border-box;
  max-width: 1500px;

  .sectionTitle {
    color: $color-bright-2;
    font-size: $size-bigger;
    font-weight: $weight-regular;
    margin: 10px;
  }

  .sectionGrid {
    @include flex-row;
    flex: 1;

    .cardContainer {
      width: 150px;
      height: 150px;

      .hoveredInfo {
        justify-content: space-evenly;

        .title {
          margin: 0;
        }
      }
    }

  }

}

.adminPageContainer {
  width: 100%;
}

@media #{$media-phone} {
  .Form {
    padding: 0;
    border: none;

    .btn {
      width: 200px;
    }

    label {
      width: 200px;

      input {
        width: 200px;
      }
    }
  }
}