@import "src/index";

.errorContainer {
  @include flex-column-full-center;
  color: #ff9933;
  font-size: $size-big;
  min-height: 300px;
  font-weight: normal;

  .icon {
    font-size: 50px;
    padding: 20px;
  }
}