@import "../../index";

.carouselContainer {
  width: calc(100% - 76px);
  max-width: 1600px;
  align-self: center;

  .slideContainer {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &:focus {
      outline: none !important;
    }
  }


  .cardContainer {
    width: 200px;
    height: 260px;
    opacity: 1;

    &.active {
      transform: scale(1);

      .backImage {
        opacity: 1;
        box-shadow: inset 0 0 0 2px $color-bright-2;
        transition: opacity 0.2s ease-in-out;
      }

      .title {
        color: $color-bright-2;
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }

      .gradient {
        background: linear-gradient(rgba($color-dark-1, 0) 0%, rgba($color-dark-1, 0) 2px, $color-dark-2 2px, rgba($color-dark-1, 0) 35%);
        width: calc(100% - 4px);
        left: 2px;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;

          border-top: 10px solid $color-bright-2;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .gradient {
      height: 200px;
      width: 100%;
      background: linear-gradient($color-dark-2 0%, $color-dark-2 5%, rgba($color-dark-1, 0) 45%);
      position: absolute;
      left: 0;
      top: 0;
    }

    .cardHeader {
      @include flex-row;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 1;
      padding: 5px;
      cursor: pointer;
    }

    .mediaName {
      color: $color-bright-1;
      margin-left: 8px;
      font-size: $size-small;
    }

    .badge {
      height: 35px;
      width: 35px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }


      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        box-shadow: 2px 2px 0 0 $color-bright-2;
      }
    }

    .ribbon {
      content: "";
      border-top: 40px solid $color-bright-2;
      border-right: 40px solid transparent;
      position: absolute;
      opacity: 1;
      z-index: 2;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
    }

    .backImage {
      opacity: 0.6;
      height: 200px;
      transition: opacity 0.1s ease-in-out;
    }

    .hoveredInfo {
      opacity: 1;
    }

    .notVerifiedIcon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .title {
      position: absolute;
      bottom: 0;
      font-weight: $weight-regular;
      font-size: $size-small;
      color: $color-bright-1;
      margin: 0;
      height: 50px;
      width: 100%;
      text-align: center;
      transition: all 0.1s ease-in-out;

    }

    &.active {
      transform: scale(1);
    }

    &:hover {
      .title {
        color: $color-bright-2;
      }

      .backImage {
        opacity: 0.9;
        transition: opacty 0.2s ease-in-out;
      }
    }
  }

}

.arrowContainer {
  height: 100% !important;
  cursor: pointer;
  display: flex !important;
  @include flex-row;
  align-items: center;
  transform: translate(0, -60%);
  color: $color-bright-1 !important;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: $color-bright-2 !important;
    transition: all 0.2s ease-in-out;

  }

  &:before {
    content: "" !important;
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-bottom: 40px;
  }

  &.slickDisabled {
    display: none;
  }
}

.dotsContainer {
  @include flex-row;
  position: absolute;
  top: -20px;
  right: 8px;
  list-style-type: none;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &.hidden {
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }
}

.customPagingContainer {
  opacity: 0.4;
  height: 8px;
  width: 5px;
  margin: 1px;
  background-color: $color-bright-1;
}