@import "../../index";

.cookieConsentContainer {
  position: fixed;
  margin: 5% auto;
  padding: 30px 50px;
  width: 800px;
  left: 0;
  right: 0;
  bottom: -50px;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 $color-bright-2;
  background-color: $color-dark-2;
  color: $color-bright-1;
  z-index: 5;

  strong {
    color: $color-bright-2;
  }

  .cookieConsentFooter {
    margin-top: 30px;
    @include flex-row;
    justify-content: flex-end;

    .btn {
      margin: 0 5px;
    }
  }
}

@media #{$media-phone} {
  .cookieConsentContainer {
    bottom: unset;
    left: unset;
    right: 0;
    width: unset;
    top: 50%;
    margin: 0 10px;
    padding: 25px;
    transform: translateY(-50%);
  }
}