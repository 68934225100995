@import "../../index";

.sidebarContainer {
  display: none;

  .sidebarButton {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    z-index: 7;
    color: $color-bright-1;
  }

  .menuNavMobile {
    @include flex-column;
    align-items: center;
    height: 100%;
    flex: 1;

    .menuHeader {
      margin: 15px 0;
      position: relative;
      @include flex-row-full-center;
      color: $color-bright-1;
      width: 100%;
      height: 40px;

      .backButton {
        align-self: flex-start;

        svg {
          position: absolute;
          left: 15px;
        }
      }

      .logoImageContainer {
        transform: scale(0.4);
      }
    }

    .navMenuMobileContainer {
      @include flex-column-full-center;
      margin-bottom: 70px;

     flex: 1;
    }

    .menuMobileLink {
      @include flex-row;
      text-transform: uppercase;
      padding: 15px 40px;
      width: 50px;
      height: auto;
      font-size: $size-normal;
      margin: 5px 0;


      &:hover {
        color: $color-bright-2;
      }
    }

    .menuMobileLinkActive {
      color: white;
      background-color: $color-dark-2;
      font-weight: $weight-regular;
    }
  }
}


@media #{$media-phone} {
  .sidebarContainer {
    display: flex;
  }
}