@import "../../index";

.headerContainer {
  width: 100%;
  background-color: $color-dark-1;
  color: white;
  box-sizing: border-box;
  @include flex-row;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  z-index: 6;
  height: 56px;

  .headerWrapper {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;
    width: 100%;
  }


  .titleContainer {
    transform: scale(0.40);
  }

  .navMenuContainer {
    @include flex-row;
    height: 100%;
    align-items: center;
    width: 390px;
  }
}

.mobileSearch {
  display: none;
  color: $color-bright-1;

  .backButton {
    padding: 15px 0;
    position: relative;
    @include flex-row-full-center;

    svg {
      position: absolute;
      left: 15px;
    }

    span {
      text-transform: uppercase;
      font-weight: $weight-regular;
    }
  }

  .searchWithPreviewContainer {
    position: relative !important;
  }

  .sidebarButton {
    @include flex-column-full-center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    height: 50px;
    padding: 0 15px;
  }

  .searchResultsContainer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    right: 0;
    border-bottom: none;
    box-shadow: none;

    .allResults {
      margin: 10px;

      .itemContainer {
        padding: 10px 10px;
        justify-content: center;
      }

      .gameTitle {
        font-size: $size-small;
      }
    }
  }
}


.searchResultsContainer {
  position: absolute;
  padding: 0 30px;
  z-index: 22;
  justify-content: center;
  background-color: $color-dark-2;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 $color-bright-2;
  width: 600px;
  right: 20px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;

  // Workaround z-index being ignored when transformed is used in the logo SVG, causing the logo to be above the container
  transform: rotateZ(0) rotateY(0);

  ul {
    list-style-type: none;
    padding: 0;

    li {
      .itemContainer {
        padding: 10px 20px;
        @include flex-row;
      }

      &.allResults {
        color: $color-bright-1;
        @include flex-column;
        justify-content: center;
        font-size: $size-small;
        cursor: pointer;

        .itemContainer {
          padding: 20px 20px;
        }

        .gameTitle {
          text-transform: none;
        }
      }

      .imageContainer {
        height: 60px;
        width: 45px;
        margin-right: 20px;
        color: $color-dark-1;
        transition: all 0.1s ease-in-out;

        img {
          height: 100%;
          width: 100%;
          opacity: 0.9;
          object-fit: cover;
        }
      }


      .gameInfos {
        @include flex-column;
        justify-content: center;
      }

      &.active {
        color: $color-bright-2;

        .imageContainer {
          box-shadow: 5px 5px 0 0 $color-bright-2;
          transform: translateX(-5px) translateY(-5px);
          transition: box-shadow 0.2s, transform 0.2s;

          img {
            opacity: 1;
          }
        }

        .gameTitle {
          color: $color-bright-2;
        }

        .gameDate {
          color: rgba($color-bright-2, 0.8);
        }
      }

      .gameTitle {
        text-transform: uppercase;
        font-size: $size-normal;
        font-weight: $weight-regular;
        color: $color-bright-1;
      }

      .gameDate {
        color: rgba($color-bright-1, 0.8);
        font-size: $size-small;
      }
    }
  }
}

.searchWithPreviewContainer {

  &.mobile {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: $color-dark-2;

    .inputContainer {
      .input {
        width: 100%;
      }
    }
  }
}

@media #{$media-phone} {
  .headerContainer {
    .navMenuContainer {
      display: none;
    }
  }
  &.notHomeHeader {
    height: 50px;

    .titleContainer {
      width: 100px;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      top: auto;
    }

    .desktopSearch {
      display: none;
    }
  }


  .mobileSearch {
    display: block;
  }

}