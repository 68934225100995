@import "../../index";


.mediaRowContainer {
  width: 100%;

  .mediaRowContainer {
    padding-top: 20px;
    width: 100%;
    padding-bottom: 20px;
    @include flex-column;
    align-items: center;
    transition: background-color 0.3s ease-in-out, max-height 0.3s ease-in-out;
    overflow: hidden;

    .activeEpisodeContainer {
      opacity: 0;
      transition: all 0.2s ease-in-out 0.2s;
    }

    &.mediaRowContainerActive {
      background-color: $color-dark-2;

      .activeEpisodeContainer {
        opacity: 1;
      }
    }
  }

  .mediaRowWrapper {
    @include flex-column;
    max-width: 1300px;
    width: 100%;
  }

  .title {
    @include flex-row;
    align-items: center;
    letter-spacing: 1px;
    color: $color-bright-1;
    margin: 0 10px 5px;

    .emoji {
      height: 30px;
      width: 30px;
      padding: 2px;
      font-size: $size-normal;
      margin-right: 10px;
      border-radius: 50%;
      @include flex-column-full-center;
      background-color: $color-bright-2;
    }
  }
}

.gameContentWrapper {
  position: relative;
  z-index: 2;
  background-color: $color-dark-1;
}