@import "../../index";
@import "../homepage/homepage.module";

.mediaPageContainer, .mediaListPageContainer {
  margin: 30px 0;
  width: 100%;
  @include flex-column;
  align-items: center;
  flex: 1;
  z-index: 2;

  .mediaTitleContainer {
    @include flex-column;
    align-items: center;
    color: $color-bright-2;
    margin-top: 20px;

    .mediaLogo {
      height: 200px;
      width: 200px;
      background-color: $color-dark-1;
      box-shadow: 10px 10px 0 2px $color-bright-2;

      margin-bottom: 30px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .mediaName {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: $size-biggest;
      font-weight: normal;
      margin-bottom: 5px;
      text-align: center;
    }

    .epNumber {
      font-size: $size-normal;
    }

    .mediaDescription {
      margin: 30px 10px;
      color: $color-bright-1;
      font-style: italic;
      max-width: 650px;
      white-space: pre-wrap;
    }
  }

  .subscribeBtn {
    display: flex;
    margin: 15px;

    .mobile {
      display: none;
    }
  }

  .mediaRowContainer {
    @include flex-column;
  }

  .gamesContainer {
    @include flex-column;

    .gamesGridTitleContainer {
      @include flex-row;
      align-items: center;
      letter-spacing: 1px;
      color: $color-bright-1;
      margin: 0 10px 5px;

      .gamesGridTitleImg {
        margin-right: 10px;
        border-radius: 50%;
        background-color: $color-dark-2;
        color: $color-bright-2;
        font-size: 20px;
        padding: 5px;
      }

      .gameGridTitleText {
      }
    }

    .gamesGrid {
      align-self: center;
    }
  }

}

.mediaListPageContainer {
  .pageTitle {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $size-big;
    font-weight: normal;
    padding-bottom: 10px;
    color: $color-bright-2;
    margin-bottom: 20px;
    text-align: center;
  }

  .info {
    color: $color-bright-1;
    font-size: $size-small;
    @include flex-row;
    align-items: center;

    .text {
      margin-right: 5px;
    }

    a {
      margin: 0 5px;
    }
  }

  .mediasList {
    color: $color-bright-1;
    width: 100%;
    max-width: 1250px;
    @include flex-column;
    margin-top: 30px;
    flex: 1;

    .mediaItem {
      @include flex-row;
      justify-content: center;
      margin: 20px auto;
      max-width: 800px;
      width: 100%;

      .mediaBlockLeft {
        @include flex-column;
        align-items: center;
        width: 150px;
        color: $color-bright-2;

        .mediaLogo {
          transition: box-shadow 0.1s, transform 0.1s;
          width: 150px;
          height: 150px;
          margin-bottom: 20px;
        }
      }

      .mediaBlockRight {
        padding: 5px 30px;
        max-width: 650px;
        flex: 1;

        .mediaTitle {
          color: $color-bright-2;
          margin-bottom: 5px;
          @include flex-column;

          .mediaName {
            text-transform: uppercase;
            font-weight: $weight-regular;
            margin-right: 5px;
            font-size: $size-normal;
          }

          .mediaType {
            font-size: $size-small;
          }
        }

        .mediaDescription {
          color: $color-bright-1;
          font-style: italic;
          font-size: $size-small;
        }
      }

      &:hover {
        .mediaLogo {
          box-shadow: 10px 10px 0 2px $color-bright-2;
          transform: translateX(-10px) translateY(-10px);
          transition: box-shadow 0.2s, transform 0.2s;
        }
      }
    }
  }
}

@media #{$media-phone} {
  .mediaPageContainer {
    .mediaTitleContainer {
      .mediaName {
        font-size: $size-bigger;
      }
    }

    .subscribeBtn {
      .mobile {
        display: unset;
      }

      .desktop {
        display: none;
      }
    }
  }

  .mediaListPageContainer {
    .mediasList {
      .mediaItem {
        margin: 20px 5px;

        .mediaBlockLeft {
          width: 120px !important;

          .mediaLogo {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }
}