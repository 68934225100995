@import "../../index";

.settingsContainer {
  .adminButton {
    @include flex-row-full-center;

    z-index: 7;
    padding: 10px;
    background-color: $color-dark-1;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    right: 30px;
    top: 30px;
    height: 20px;
    width: 20px;
    color: $color-bright-1;
    transition: all 0.2s ease-in-out;

    &:hover, &.active {
      color: $color-bright-2;
      transition: all 0.3s ease-in-out;
      transform: rotate(-180deg);
    }
  }
}

.settingsPopoverContainer {
  padding: 30px 40px;
  @include flex-column;
  background-color: $color-dark-2;
  border-radius: 10px;
  box-shadow: 3px 3px 0 0 $color-bright-2;
  margin-right: 20px;

  .blockTitle {
    color: $color-bright-2;
    font-weight: $weight-regular;
    margin-bottom: 15px;
    font-size: $size-big;

    .icon {
      margin-right: 10px;
      font-size: $size-small;
    }
  }

  .settingsBlock {
    @include flex-column;

    .subBlock {
      @include flex-column;
      margin-bottom: 5px;

      &:last-child {
        margin-right: 0 !important;
      }

      .subBlockTitle {
        color: $color-bright-2;
        font-size: $size-small;
        font-weight: $weight-regular;
        margin-bottom: 10px;
      }
    }

    .filterRow {
      font-size: $size-small;
      margin: 5px 0;

      .filterName {
        color: $color-bright-1;
        padding-left: 5px;
      }
    }
  }

  .settingsFooter {
    color: $color-bright-1;
    font-size: $size-small;
    @include flex-column;

    .btn {
      margin-top: 20px;
    }
  }

  .longLabel {

  }

}

@media #{$media-phone} {
  .settingsContainer {
    display: none;
  }
}