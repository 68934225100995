@import "../../index";

.titleContainer {
  @include flex-column;
  align-items: center;
  font-family: 'Karla', sans-serif;
  height: 100%;
  color: $color-bright-2;
  font-size: 35px;
  margin-bottom: 20px;

  .logo {
    height: 100%;
    padding-bottom: 10px;
  }
}

.subtitle {
  color: $color-bright-1;
  font-size: $size-small;
  letter-spacing: 1px;
  text-align: center;

  strong {
    color: $color-bright-2;
  }
}

@media #{$media-phone} {
  .headerContainer {
    .titleContainer {
      font-size: 30px;
    }
  }
}