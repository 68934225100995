@import "../../index";
@import "src/components/adminMedia/adminMediaSection.module";

.gamePageContainer {
  width: 100%;

  .gameHeader {
    background-color: $color-dark-2;
    width: 100%;
    height: 400px;
    position: relative;
    @include flex-column-full-center;

    .headerContent {
      position: relative;
      z-index: 2;
      max-width: 1300px;
      width: 100%;
      @include flex-row;
      align-items: center;

      .coverImg {
        width: 200px;
        margin-right: 50px;
        margin-left: 50px;
        box-shadow: 10px 10px 0 2px $color-bright-2;
      }

      .gameInfos {
        color: $color-bright-2;
        margin: 0 10px;

        .gameTitle {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: $size-biggest;
          font-weight: $weight-regular;
          padding-bottom: 10px;
        }

        .gameDevelopers {
          font-size: $size-big;
          font-style: italic;
        }

        .gameDate {
        }
      }
    }

    .backImage {
      width: 100%;
      height: 100%;
      filter: blur(2px);
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.6;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

@media #{$media-phone} {
  .gamePageContainer {
    .gameHeader {
      height: 340px;

      .headerContent {
        flex-direction: column;

        .coverImg {
          margin-bottom: 20px;
          margin-right: 0;
          margin-left: 0;
          width: 150px;
          height: 200px;
        }

        .gameInfos {
          text-align: center;

          .gameTitle {
            font-size: $size-bigger;
          }
        }
      }
    }
  }
}